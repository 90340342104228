.overalls {
    overflow: hidden;
    user-select: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.background-sales-page {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.first-gradient {
    width: 100%;
    overflow: hidden;
    background: linear-gradient(180deg, #0F0F16 0%, rgba(46, 47, 72, 0) 100%),
    linear-gradient(275.36deg, rgba(142, 24, 235, 0.106) -5%, rgba(0, 0, 0, 0) 59.08%),
    linear-gradient(55.75deg, rgba(142, 24, 235, 0.106) 10.75%, rgba(0, 0, 0, 0) 60.65%);
}

.features-gradient {
    background-image: url('../icons/imagegirlside.png');
    background-size: 30% auto;
    background-position: left bottom;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    max-height: 100%;
    overflow: hidden;
}

.features-gradient::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5); /* White background with 50% opacity */
    z-index: -1;
}

.frequent-questions-bg {
    position: relative;
    background-color: #131313;
}

.frequent-questions-bg::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../icons/image-anime-girlie-right.png');
    background-size: 30% auto;
    background-position: right bottom;
    background-repeat: no-repeat;
    opacity: 0.35;
    z-index: 1;
}

@media (max-width: 680px) {
    .features-gradient {
        background-size: 90% auto;
    }

    .frequent-questions-bg {
        background-size: 60% auto;
    }

    .frequent-questions-bg::before {

        background-size: 65% auto;

    }
}