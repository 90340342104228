.new-pricing-card {
    display: flex;
    justify-content: space-around;
    height: fit-content;
    flex-direction: column;
    border: 1px solid #393939;
    padding: 15px;
    color: #8A8A8A;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    position: relative;
    background-color: #151515;
    transition: transform 0.3s;
    min-width: 300px;
    max-width: 300px;
    min-height: 290px;
}


.feature-card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: auto;
    min-width: 280px;
}


.selected-plan {
    border: 1px solid white;
    cursor: none;
}

.new-basic-container {
    background-color: #151515;
    border: 1px solid #393939;
    color: white;
    stroke: white;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s, transform 0.3s;
}

.new-basic-container:hover {
    background-color: #2a2a2a;
    border-color: #505050;
    color: #ffffff;
    transform: scale(1.05);
}

.new-free-container {
    stroke: #8A8A8A;

}

.new-premium-container {
    background: linear-gradient(159.17deg, rgba(91, 69, 141, 0.8) 0.79%, rgba(70, 57, 102, 0.2) 96.7%), #131313;
    color: white;
    stroke: white;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s, transform 0.3s;

}

.new-ultimatePlan {
    background: #976DFD linear-gradient(159.17deg, rgba(91, 69, 141, 0.8) 0.79%, rgba(70, 57, 102, 0.2) 96.7%);
    color: white;
    border-radius: 24px;
    position: absolute;
    top: 0;
    left: 30%;
    transform: translateX(-50%) translateY(-50%);
    padding: 5px 15px;
    font-weight: bold;
    font-size: 0.75em;

}

.new-unlimitedMessages {
    color: white;
    border-radius: 24px;
    position: absolute;
    top: 0;
    left: 30%;
    transform: translateX(-50%) translateY(-50%);
    background: #976DFD linear-gradient(159.17deg, rgba(91, 69, 141, 0.8) 0.79%, rgba(70, 57, 102, 0.2) 96.7%);
    padding: 5px 15px;
    font-weight: bold;
    font-size: 0.75em;


}

.new-ultimate-container:hover,
.new-unlimited-container:hover,
.new-premium-container:hover {
    cursor: pointer;
}

.new-ultimate-container:hover,
.new-premium-container:hover {
    background: linear-gradient(159.17deg, rgba(100, 69, 141, 0.8) 0.79%, rgba(70, 57, 102, 0.2) 96.7%), #131313;
    transform: scale(1.05);
}


.check-icon-pricing {
    height: 14px;

}

.new-textnamesub {
    font-size: 1.5em;
    font-weight: bold;
    margin: 4px 0 10px 6px;
    color: white;
}


.new-mostPopular {
    position: absolute;
    top: 0;
    left: 30%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #976DFD;
    color: white;
    padding: 5px 15px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 0.75em;
}

.new-sidebarMenu {
    margin-top: 10px;
    display: flex;
    font-size: 0.9em;
    font-weight: 600;
    flex-direction: column;
    align-items: flex-start;
}

.new-menuText {
    white-space: break-spaces;
    flex: 1 1;
    font-weight: 600;
}

.new-menuOptionContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}


.new-buttonsubscribe {
    background-color: #BBA9E1;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
    transition: background-color 0.3s;
}

.new-buttonsubscribe:hover {
    background-color: rgba(187, 169, 225, 0.8);
}


.new-ultimate-container {
    background: linear-gradient(159.17deg, rgba(91, 69, 141, 0.8) 0.79%, rgba(70, 57, 102, 0.2) 96.7%), #131313;
    color: white;
    border: 1px solid #393939;
    stroke: white;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s, transform 0.3s;

}

.new-unlimited-container {
    color: #8A8A8A;
    stroke: white;
}


@media screen and (min-width: 1480px) {
    .new-pricing-card {
        min-width: 350px;
        max-width: 350px;
    }
}

@media screen and (min-width: 980px) and (max-width: 1480px) {
    .new-pricing-card {
        min-width: 340px;
        max-width: 340px;
    }
}

@media screen and (min-width: 680px) and (max-width: 980px) {
    .new-pricing-card {
        min-width: 320px;
        max-width: 320px;
    }
}

@media screen and (min-width: 0px) and (max-width: 680px) {
    .new-pricing-card {
        min-width: 300px;
        max-width: 300px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 1.35em;
    }

    .feature-card-container {
        min-width: 180px;
    }

    .new-menuText {
        font-size: 0.80em;
        font-weight: 700;
    }

    .new-ultimatePlan,
    .new-unlimitedMessages {
        font-size: 0.6em;
    }

    .check-icon-pricing {
        height: 10px;

    }

    .new-textnamesub {
        font-size: 1.3em;
        margin: 0 0 5px 5px;
    }

    .new-premium-container {
        /*margin-top: 25px;*/
    }
}

@media screen and (min-width: 680px) and (max-width: 1280px) {
    .new-sidebarMenu {
        font-size: 1.05em;
    }

}

@media screen and (min-width: 1280px) and (max-width: 1480px) {
    .new-sidebarMenu {
        font-size: 1.1em;
    }
}

@media screen and (min-width: 1480px) {
    .new-sidebarMenu {
        font-size: 1.15em;
    }
}