.model-dropdown {
    width: 215px;
    height: 45px;
}

.model-dropdown-control {
    display: flex;
    background-color: #9072D9;
    border-radius: 12px;
    color: #ffffff;
    font-weight: 600;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    width: 215px;
    height: 45px;
    align-items: center;
    transition: all 0.3s ease;
    font-size: 1.05em;
}

.model-dropdown.is-open .model-dropdown-control {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #C4C4C4;
}

.model-dropdown-control:hover {
    background-color: #845ec2;
}

.model-dropdown-menu {
    background-color: #9072D9;
    border-radius: 0 0 8px 8px;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.model-dropdown.is-open .model-dropdown-menu {
    border: 1px solid #C4C4C4;
}

.model-dropdown-menu .Dropdown-option {
    color: #ffffff;
    padding: 10px 15px;
}

.model-dropdown-menu .Dropdown-option:hover {
    background-color: #6a53a1;
}

.model-dropdown-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    border-color: #ffffff transparent transparent;
}

.language-dropdown {
    width: 145px;
    height: 45px;
}

.language-dropdown-control {
    background-color: transparent;
    border-radius: 10px;
    color: #ffffff;
    cursor: pointer;
    padding: 10px 15px;
    transition: all 0.3s ease;
    height: 45px;
    width: 145px;
    display: flex;
    font-weight: 700;
    border: 1px solid #C4C4C4;
    align-items: center;
}

.language-dropdown.is-open .language-dropdown-control {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #C4C4C4;
}

.language-dropdown-control:hover {
    background-color: #0d0d0d;
}

.language-dropdown-menu {
    background-color: #131313;
    border: 1px solid #C4C4C4;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.language-dropdown-menu .Dropdown-option {
    color: #c4c4c4;
    padding: 10px 15px;
}

.language-dropdown-menu .Dropdown-option:hover {
    background-color: #3a3a3a;
}

.language-dropdown-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    border-color: #ffffff transparent transparent;
}

.Dropdown-option.is-selected {
    background-color: #6335D2;
    color: #ffffff;
}

.Dropdown-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.bonus-messages-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    user-select: none;
    height: 45px;
    border: 1px solid #C4C4C4;
    border-radius: 12px;
    font-size: 1em;
    font-weight: 700;
    gap: 5px;
}

.bonus-messages-counter svg {
    min-width: 18px;
    min-height: 18px;
    max-height: 18px;
    max-width: 18px;
}

@media screen and (max-width: 680px) {
    .model-dropdown-control {

        padding: 10px 15px;
        width: 100%;
        height: 45px;

        font-size: 0.9em;
    }
}