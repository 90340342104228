.character-card-page {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    color: white;
    overflow-y: scroll;
    overflow-x: hidden;
}

.character-page-public-chats-container {
    width: 100%;
}

.public-chats-grid {
    margin-top: 20px;
    max-height: 595px;
    overflow-y: scroll;
    grid-template-columns: 1fr 1fr;
}

.public-chats-grid {
    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

.public-chats-grid::-webkit-scrollbar {
    width: 8px;
}

.public-chats-grid::-webkit-scrollbar-track {
    background: transparent;
}

.public-chats-grid::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 4px;
}

.three-dots-container {
    position: relative;
    display: inline-block;
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
}

.similar-characters-main {
    display: block;
    width: 300px;
    position: relative; /* Ensures shadow stays within bounds */
}

.similar-characters-main::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 32px;
    background: linear-gradient(to top, rgba(13, 13, 13, 0.35), transparent);
    pointer-events: none;
}

.username-popup {
    position: absolute;
    background-color: #161616;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    z-index: 1000;
    top: 100%;
    right: 0;
    border: 1px solid #4C4C4C;
}

.report-button-insider {
    display: flex;
    gap: 5px;
    align-items: center;
    width: 100%;
    padding: 10px;
    color: #C4C4C4;
    font-weight: 700;
    text-align: left;
    cursor: pointer;
}

.nothing-found {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.nothing-found-message {
    font-size: 1em;
    color: #c4c4c4;
    text-align: center;
}

.created-by-button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    color: #C4C4C4;
    font-weight: 700;
    text-align: left;
    cursor: pointer;
}

.created-by-button:hover {
    background-color: #1f1f1f;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}


.report-button-insider:hover {
    background-color: #1f1f1f;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.report-button-insider svg {
    min-width: 16px;
    min-height: 16px;
    max-height: 16px;
    max-width: 16px;
}

.no-public-characters {
    color: #c4c4c4;
    font-size: 1em;
    font-weight: 600;

}

.share-button-container {
    position: relative;
}

.similar-char-general-loader {
    gap: 15px;
}

.similar-char-loader {
    width: 300px;
    height: 85px;
    background-color: #1e1e1e;
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    animation: glistening 1.5s infinite;
    border-radius: 14px;
    margin-bottom: 10px;
}

.share-popup {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #242424;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
}

.share-popup.show {
    opacity: 1;
}

.character-card-background {
    height: 100%;
    max-height: 285px;
    width: 100%;
    background: url('IconsSub/backgroundShapes.svg'),
    linear-gradient(97.28deg, rgba(120, 19, 250, 0.3) 33.62%, rgba(191, 63, 251, 0.3) 75.64%),
    rgb(19, 19, 19);
    background-size: cover;
    border-radius: 24px 24px 0 0;
    z-index: -1;
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
}

.character-card-column-unify {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    margin-top: 145px;
    gap: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 15px;
}

.audio-sample-character-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 260px;
    height: 95px;
    background-color: #6F46D1;
    border-radius: 0 12px 12px 12px;
}

.character-card-left-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    gap: 10px;
}

.character-page-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.character-page-main-image-tags {
    max-width: 300px;
    margin-top: 5px;
}

.character-page-image {
    width: 300px;
    height: 420px;
    border-radius: 12px;
    object-fit: cover;
    display: block;
    background-size: cover;
}

.character-card-right-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    gap: 10px;
}

.character-page-bio {
    max-width: 400px;
}

.object-centralizer-component {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.character-card-right-column-button-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.character-page-name {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.character-name-centralizer {
    display: flex;
    align-items: center;
}

.report-icon-central svg {
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
    cursor: pointer;
}

.character-card-button-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: white;
    margin-top: 65px;
    align-items: center;
}

.ai-girlfriend-button svg, .share-favorite-button svg {
    width: 20px;
    height: 20px;
}

.ai-girlfriend-button {
    display: flex;
    width: 100%;
    min-width: 200px;
    max-width: 300px;
    height: 50px;
    background-color: #E47CCD;
    border-radius: 12px;
    color: white;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    gap: 10px;
    font-size: 1.25em;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    cursor: pointer;
}

.ai-girlfriend-button:hover {
    background-color: #E47CCD;
    opacity: 0.90;
}

.share-favorite-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 10px;
}

.share-favorite-button {
    width: 60px;
    height: 40px;
    background-color: #1B1B1B;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.3s ease;
}

.share-favorite-button:hover {
    background-color: #1f1f1f;
    opacity: 0.90;
}

.similar-characters-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    align-self: center;
}

.similar-characters-avatar-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}

.similar-chars-placeholder {
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
    border-radius: 50%;
    background: url("../CharacterCard/IconsSub/altcard.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.similar-chars-placeholder svg {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.similar-characters-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    height: 100%;
    min-width: 300px;
    margin-top: 20px;
    overflow-y: scroll;
    scrollbar-width: none;
    max-height: 595px;
}

.similar-characters-unify {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 10px;
    border-radius: 14px;
    width: 300px;
    background: rgba(91, 91, 91, 0.2);
    transition: background-color 0.3s;
    min-height: 87px;
    max-height: 87px;
}

.similar-characters-unify:hover {
    background-color: rgba(68, 68, 68, 0.2);
}

.similar-characters-name {
    font-size: 1em;
    font-weight: 700;
}

.similar-characters-description {
    font-size: 0.85em;
    color: #AEAEAE;
    font-weight: 500;
}

.similar-characters-text-container {
    width: fit-content;
    margin-right: auto;
}

.general-public-chat-container {
    width: 100%;
    background-color: #1F1F1F;
    padding: 20px;
    border-radius: 14px;
    height: 290px
}

.public-chat-upper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    width: fit-content;
}

.user-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #ddd;
    margin-right: 10px;
}

.public-chat-messages {
    display: flex;
    flex-direction: column;
    background-color: #18181A;
    padding: 30px 20px;
    border-radius: 14px;
    gap: 15px;
    user-select: none;
    cursor: pointer;
}

.public-chat-user, .public-chat-bot {
    padding: 10px;
    font-size: 0.75em;
    width: 90%;
}

.public-chat-user {
    border-radius: 12px 12px 0 12px;
    background-color: #131313;
    align-self: flex-end;
    border: 2px solid #242424;
}

.public-chat-bot {
    border-radius: 0 12px 12px 12px;
    background-color: #6F46D1;
    align-self: flex-start;
}

.public-chat-author {
    font-size: 1em;
    color: #AEAEAE;
    font-weight: 500;
}

.public-chat-by-author {
    font-size: 0.8em;
    color: #AEAEAE;
    font-weight: 500;
}

.public-chat-title {
    font-size: 1em;
    white-space: normal;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;
}

.lower-half-character-page {
    display: flex;
    flex-direction: row;
    gap: 40px;
    padding-left: 40px;
    padding-right: 40px;
    align-items: flex-start;
    margin-bottom: 15px;
}

@media screen and (min-width: 1920px) {
    .public-chats-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .similar-characters-list,
    .public-chats-grid {
       max-height: 905px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1920px) {
    .public-chats-grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 720px) and (max-width: 980px) {
    .public-chats-grid {
        grid-template-columns: 1fr;
    }

    .similar-characters-list {

    }

    .expandable-description {
        font-size: 0.90em;
    }
}

@media screen and (max-width: 720px) {
    .similar-char-loader {
        width: 100%;
    }
    
    .public-chat-messages {
        padding: 25px 10px;
    }

    .lower-half-character-page {
        display: flex;
        flex-direction: unset;
        justify-content: unset;
        align-items: unset;
        gap: 40px;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
        margin-top: 0;
    }

    .similar-characters-description,
    .public-chat-author {
        text-align: start;
    }

    .character-page-public-chats-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 100%;
        /*margin-top: 65px;*/
    }

    .character-page-image {
        width: 300px;
        height: 420px;
        aspect-ratio: 3/4;
        max-width: 340px;

    }

    .character-card-left-column {
        justify-content: center;
        align-items: center;
    }

    .character-card-column-unify {
        flex-direction: column;
        display: flex;
        position: relative;
        width: 100%;
        margin-top: 145px;
        gap: 15px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 5px;
    }

    .object-centralizer-component {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .audio-sample-character-card {
        display: none;
    }

    .similar-characters-name{
        text-align: start;
    }

    .similar-characters-main {
        display: block;
        width: 100%;
        /*margin-top: 20px;*/
        padding-left: 40px;
        padding-right: 40px;
        text-align: center;
        padding-bottom: 85px;
    }

    .similar-characters-list {
        width: 100%;
        align-items: center;
    }

    .similar-characters-unify {
        width: 100%;
        max-width: 400px;
    }

    .public-chats-grid {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr;
        place-items: center;
    }

    .character-card-right-column {
        align-items: center;
        text-align: center;
    }

    .character-card-button-container {
        flex-direction: row;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .share-favorite-button {
        background: #1B1B1B;
        height: 45px;
        min-width: 70px;
        width: 100%;
        max-width: 70px;
    }

    .ai-girlfriend-button {
        height: 45px;
    }

    .character-page-main-image-tags {
        min-width: 100%;
        max-width: 100%;
    }

    .general-public-chat-container {
        width: 90%;
    }

    .character-page-name {
        display: flex;
        flex-direction: row;
        gap: 5px;
        justify-content: center;
    }
    .character-card-right-column-button-header {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (min-width: 0px) and (max-width: 420px) {
    .character-page-image {
        width: 100%;
        height: unset;
        aspect-ratio: 3/4;
        max-width: 300px;
        object-position: top;
    }
}

