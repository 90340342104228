/* Image Carousel Container */
.carousel-background-container {
    align-items: center;
    z-index: 9;
    height: auto;
    background: #131313;
}

.carousel-container {

    text-align: center;
    position: relative;
    z-index: 9;
    padding: 40px 60px 40px;
}

/* Carousel Title */
.carousel-title {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.upper-text {
    font-size: 2.625em;
color: #E7E7E7;
    font-weight: 800;
    letter-spacing: -0.01em;
    text-align: left;
}

.lower-text {
    font-style: italic;
    font-size: 2.625em;
    font-weight: 600; /* This will override the 800 weight */
    letter-spacing: -0.01em;
    text-align: left;
    background: linear-gradient(96.61deg, #D9D9D9 0%, #FC8DFE 117.73%),
    linear-gradient(0deg, #E7E7E7, #E7E7E7),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.carousel-subtitle-2{
    /*margin-top: 10px;*/
    color: #BDBDBD;
    font-size: 1.375em;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.01em;
    text-align: left;
}


/* Carousel Images */
.carousel-images {
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-image {
    padding: 30px;
    height: auto;
    opacity: 0.5;
    transition: opacity 0.2s ease;
        border-radius: 40px;
        max-width: 95%; /* Control the width of images */
        min-height: 100%;
        max-height: 100%; /* Control the height of images */
        margin-bottom: 35px;
}

/* Increase opacity for active image */
.active-image {
    transition: transform 0.2s ease, opacity 0.2s ease, height 0.2s ease; /* Add transition for smooth size change and opacity */
    transform: scale(1.2); /* Scale up the selected image by 10% (adjust as needed) */
    opacity: 1;


}

/* Scroll Buttons */
.scroll-button {
    background-color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    font-size: 1.25em;
    color: #333;
    transition: background-color 0.1s ease;
}

.scroll-button:hover {
    background-color: #ddd;
}

.scroll-left-button {
    left: 10px;
}

.scroll-right-button {
    right: 10px;
}

.carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: center;
}




@media screen and (max-width:680px) {
    .carousel-container {
        padding: 40px 25px 40px;
    }

    .carousel-image {
        padding: 35px;

    }
    .carousel-title {
       padding-bottom: 20px;
    }
}

@media screen and (min-width: 680px) and (max-width: 1280px) {
    .carousel-image {
        padding: 15px;

    }


    .carousel-subtitle-2{
        text-align: center;
        font-size: 1em;
    }



}



@media screen and (min-width: 1920px) {



    .upper-text {
        font-size: 4.5em;
        color: #E7E7E7;
        font-weight: 800;
    }

    .lower-text {
        font-size: 4.5em;
        margin-top: 15px;
        padding-top: 10px;
        padding-bottom: 20px;
        font-weight: 600; /* This will override the 800 weight */
    }

    .carousel-subtitle-2{
        margin-top: 10px;
        color: #BDBDBD;
        font-size: 1.625em;
        font-weight: 500;
        line-height: 27px;
    }
}
