.modal-backdrop-chat-control {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 1050;
    margin-top: 155px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.modal-backdrop-chat-control.fade-in {
    opacity: 1;
}

.modal-backdrop-chat-control.fade-out {
    opacity: 0;
}

@keyframes strokeGlow {
    0% {
        filter: brightness(1);
        opacity: 0.8;
    }
    50% {
        filter: brightness(1.5);
        opacity: 1;
    }
    100% {
        filter: brightness(1);
        opacity: 0.8;
    }
}

.menu-icon-desktop svg {
    min-width: 22px;
    max-width: 22px;
    min-height: 22px;
    max-height: 22px;
    stroke: #BEBEBE;
}

.menu-icon-desktop-premium svg{
    min-width: 22px;
    max-width: 22px;
    min-height: 22px;
    max-height: 22px;
    stroke: #9072D9;
    animation: strokeGlow 5s ease-in-out infinite;
}

.menu-label-desktop{
    color: #c4c4c4;
    font-size: 0.95em;
    font-weight: 600;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.menu-label-desktop-premium{
    color: #9072D9;
    font-size: 0.95em;
    font-weight: 600;
    width: 100%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    animation: strokeGlow 5s ease-in-out infinite;
}

.modal-content-profile-control-menu {
    background-color: #131313;
    padding: 15px;
    user-select: none;
    color: white;
    border-radius: 16px;
    border: 1px solid #2a2a2a;
    min-width: 260px;
    max-width: 260px;
    max-height: 80%;
    overflow: hidden;
    margin-right: 15px;
    opacity: 0;
    transform: scale(0.95);
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
}

.modal-content-profile-control-menu.pop-in {
    opacity: 1;
    transform: scale(1);
}


.modal-content-profile-control-menu.pop-out {
    opacity: 0;
    transform: scale(0.95);
}

/* Rest of your CSS remains the same */

.control-menu-header {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
}

.user-avatar-profile-control-menu {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #ddd;
    margin-right: 10px;
    object-fit: cover;
}

.profile-control-menu-username {
    font-size: 1em;
    color: white;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
}

.profile-count-counter svg,
.profile-control-menu-arrow svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
}

.profile-control-menu-arrow {
    width: 20px;
    height: 20px;
    margin-left: auto;
}

.balance-counter-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile-count-counter {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 650;
}

.get-more-tokens-sign {
    font-size: 0.9em;
    color: #C4C4C4;
    cursor: pointer;
    text-align: right;
    white-space: nowrap;
}

.profile-control-options {
    display: flex;
    flex-direction: column;
}

.profile-control-option {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: 15px;
    padding: 10px;
}

.profile-control-option:hover {
    background-color: #3a3a3a;
    border-radius: 8px;
    cursor: pointer;
}

@media (max-width: 680px) {
    .modal-backdrop-chat-control {
        margin-top: 80px;
    }

}