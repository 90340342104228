/* Base Timer Container */
.standard-timer-container,
.holiday-timer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 10px 20px;
    width: 100vw;
    border-radius: 0 0 16px 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: fixed;
}

/* Specific Container Styles */
.standard-timer-container {
    background-color: #6C3EA6;

}

.holiday-timer-container {
    background: linear-gradient(45deg, #00F5FF 0%, #8A2BE2 100%);
    overflow: hidden;
}

/* Timer Text Section */
.standard-timer-text,
.holiday-timer-text {
    display: flex;
    align-items: center;
    gap: 8px;

    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.standard-timer-text {
    color: white;
    font-size: 1.15em;
}

.holiday-timer-text {
    color: #000000;
    font-weight: 800;
    font-size: 1.35em;
}

/* Timer Padding Section */
.standard-timer-padding,
.holiday-timer-padding {
    font-size: 1.2em;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 16px;
}

.standard-timer-padding {
    color: white;
    background-color: #4a2a72;
}

.standard-timer-padding::before {
    content: '';
    font-size: 1em;
    font-weight: 700;
}

.holiday-timer-padding {
    color: #000000;
    background-color: rgba(255, 255, 255, 0.5);
}

.holiday-timer-padding::before {
    font-size: 1em;
    font-weight: 700;
}

/* Timer General Section */
.standard-timer-general,
.holiday-timer-general {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 24px;
}

.standard-timer-general {
    background-color: #4a2a72;
}

.holiday-timer-general {
    background-color: rgba(255, 255, 255, 0.5);
}

/* Timer Display */
.standard-timer,
.holiday-timer {
    display: flex;
    align-items: baseline;
    gap: 4px;
    font-weight: 700;
}

/* Timer Segments */
.standard-segment,
.holiday-segment {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Numbers */
.standard-number,
.holiday-number {
    font-family: 'Sans-Serif';
    font-weight: bold;
}

.standard-number {
    font-size: 1.0em;
    color: white;
    margin: 0;
}

.holiday-number {
    color: #000000;
    /*font-size: 1.15em;*/
}

/* Labels */
.standard-text,
.holiday-text {
    font-size: 0.7em;
}

.standard-text {
    color: white;
}

.holiday-text {
    color: #000000;
}

/* Separators */
.standard-segment-separator,
.holiday-segment-separator {
    font-weight: bold;
}

.standard-segment-separator {
    color: white;
}

.holiday-segment-separator {
    color: #000000;
    font-size: 1em;
}

/* Hover Effects */
.standard-timer-container:hover,
.holiday-timer-container:hover {
    /*transform: translateY(-2px);*/
}

.holiday-timer-container:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

/* Icon Styles */
.standard-timer-text svg,
.holiday-timer-text svg {
    width: 24px;
    height: 24px;
}


.holiday-timer-text svg:first-child {
    width: 32px;
    height: 32px;
}

/* Mobile Responsiveness */
@media (max-width: 680px) {
    .standard-timer-container,
    .holiday-timer-container {
        padding: 4px 8px;
    }
    .holiday-timer-text svg:first-child {
        width: 26px;
        height: 26px;
    }
    .standard-timer-text,
    .holiday-timer-text {
        white-space: normal;
        font-size: 0.75em;
    }

    .standard-timer-padding,
    .holiday-timer-padding {
        font-size: 0.75em;
        font-weight: bold;
        white-space: nowrap;
        padding: 6px 12px;
        border-radius: 16px;
    }

    .standard-timer,
    .holiday-timer {
        gap: 0;
    }

    .holiday-segment {
        padding: 1px 3px;
    }

    .standard-timer-general,
    .holiday-timer-general {
        display: flex;
        align-items: center;
        padding: 6px 12px;
        border-radius: 24px;
        font-size: 0.75em;
    }

}