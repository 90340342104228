.elyza-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1100;
    backdrop-filter: blur(4px);
}

.elyza-modal-pricing-wrapper {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1200px;
    border-radius: 12px;
    z-index: 1101;
    padding: 32px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
}

.elyza-modal-content {
    width: 100%;
    display: flex;
    gap: 24px;
    justify-content: center;
}

/* Slider specific styles */
.elyza-pricing-slider {
    width: 100%;
    position: relative;
    padding: 0 24px;
}

/* Dots container */
.elyza-pricing-slider .slick-dots {
    position: absolute;
    bottom: -30px;
    display: flex !important;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    gap: 8px;
}

/* Individual dot */
.elyza-pricing-slider .slick-dots li {
    margin: 0;
    padding: 0;
}

.elyza-pricing-slider .slick-dots li button {
    font-size: 0;
    width: 8px;
    height: 8px;
    padding: 0;
    border: none;
    border-radius: 50%;
    background: #333;
    cursor: pointer;
    transition: all 0.3s ease;
}

/* Active dot */
.elyza-pricing-slider .slick-dots li.slick-active button {
    background: #fff;
    transform: scale(1.2);
}

/* Slide item */
.elyza-pricing-slide-item {
    padding: 24px;
    outline: none;
}

/* Custom arrows if needed */
.elyza-pricing-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 2;
}

.elyza-pricing-arrow:hover {
    background: rgba(255, 255, 255, 0.2);
}

.elyza-pricing-arrow-prev {
    left: -20px;
}

.elyza-pricing-arrow-next {
    right: -20px;
}

/* Responsive adjustments */
@media (max-width: 680px) {
    .elyza-modal-pricing-wrapper {
            width: 100%;
        padding: 16px;
    }

    .elyza-pricing-slider {
        padding: 0;
    }

    .elyza-pricing-arrow {
        display: none;
    }
}