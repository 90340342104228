.character-style-carousel {
    position: relative;
    padding-top: 40px;
    padding-bottom: 20px;
}

.swiper-container {
    position: relative;
}

.character-style-carousel::before,
.character-style-carousel::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    z-index: 2;
    pointer-events: none;
}

.character-style-carousel::before {
    left: 0;
    background: linear-gradient(to right, #151515, rgba(19, 19, 19, 0));
}

.character-style-carousel::after {
    right: 0;
    background: linear-gradient(to left, #151515, rgba(19, 19, 19, 0));
}

.general-option-creator-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 14px;
    position: relative;
    font-size: 1.625em;
    z-index: 1;
    height: fit-content;
    border: 2px solid white;
    color: white;
    transition: all 0.3s ease-in-out;
}

.general-option-creator-carousel::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(178.83deg, #1C1C1C 1%, rgba(28, 28, 28, 0) 70.77%);
    right: 0;
    bottom: 0;
    z-index: 9;
    pointer-events: none;
    border-radius: 12px;
}

.general-option-creator-carousel.selected {
    color: #C0A7FF;
}

.general-image-creator-carousel {
    display: block;
    position: relative;
    object-fit: cover;
    border-radius: 12px;
    aspect-ratio: 1/1;
    object-position: top;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    width: 100%;
}

.general-option-creator-carousel.selected::before,
.general-option-creator-carousel.selected .general-image-creator-carousel {
    scale: 1.05;
    border: 2px solid #C0A7FF;
}

.other-styles-option {
    display: flex;
    justify-content: center;
}

.third-option-title {
    font-size: 1.0em;
    font-weight: 600;
    letter-spacing: -0.01em;
    color: #A7A7A7;
    cursor: pointer;
    z-index: 1001;
}

.general-image-creator-carousel-selected, .non-binary-option-selected, .general-image-creator-carousel-selected {
    border-color: #D1BFFF;
    color: #D1BFFF;
}

.non-binary-option {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease;
}

.character-style-carousel .slick-slide {
    padding: 10px;
}

.character-style-carousel .slide-item {
    transition: all 0.3s ease;
}

.character-style-carousel .slick-center .slide-item {
    transform: scale(1.1);
}

.character-style-carousel .slick-list {
    padding: 0 0 !important;
}

@media (max-width: 680px) {
    .character-style-carousel {
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .character-style-carousel .slick-slide {
        padding: 5px;
    }

    .general-image-creator-carousel {
        max-height: 250px;
    }

    .character-style-carousel::before,
    .character-style-carousel::after {
        width: 30px;
    }

    .general-option-creator-carousel {
        font-size: 1.25em;
    }

    .general-option-creator-carousel.selected::before,
    .general-option-creator-carousel.selected .general-image-creator-carousel {
        scale: 1.10;
    }
}