.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-content {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 70%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: auto; /* Center the div */
}

.popup-button.apply-button{
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.popup-button.delete-button{
    border-radius: 0 0 12px 12px;
}

.popup-content h3 {
    margin-bottom: 16px;
    text-align: center;
    color: white;
    font-size: 18px;
    font-weight: bold;
}

.popup-button {
    width: 100%;
    height: 44px;
    padding: 0 10px;
    border: none;
    background-color: #131313;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 1em;
    font-weight: 700;
}

.popup-button:not(:last-child) {
    border-bottom: 1px solid #2D2D2D;
}

.popup-button svg {
    margin-right: 12px;
    width: 20px;
    height: 20px;
}

.apply-button,
.edit-button,
.delete-button {
    color: white;
}

.popup-button:hover {
    background-color: #2D2D2D;
}

.profile-box-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
}