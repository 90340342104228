.container-char-creator {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    user-select: none;
}

.steps-container {
    overflow: hidden;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
}

.step-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.step-content {
    min-width: 900px;
    max-width: 900px;
    padding: 30px 60px;
    background-color: #151515;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border: 1px solid #393939;
    border-radius: 16px;
}

.progress-bar-container-char {
    position: relative;
    width: 100%;
    max-width: 900px;
    min-width: 900px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.step-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 10px;
}

.step-subtitle{
    font-size: 1.05rem;
    font-weight: 500;
    letter-spacing: -0.01em;
    color: #C4C4C4;
}

.navigation-buttons {
    margin-top: 45px;
    position: relative;
    bottom: 1rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.nav-button {
    display: flex;
    padding: 12px 24px;
    font-size: 1rem;
    border-radius: 14px;
    font-weight: 600;
    color: white;
    border: none;
    cursor: pointer;
    transition: opacity 0.3s ease;
    align-items: center;
    justify-content: center;
    z-index: 1002;
}

.nav-button svg {
    max-width: 28px;
    max-height: 28px;
}

.nav-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.nav-button:disabled:hover {
    opacity: 0.5;
    cursor: not-allowed;
}

.prev-button {
    background: linear-gradient(102.84deg, #57555B 41.88%, #3F3C45 66.26%);
}

.prev-button:hover {
    opacity: 0.9;
}

.next-button {
    background: linear-gradient(102.84deg, #9072D9 41.88%, #8573B0 66.26%);
    width: 100%;
}

.nav-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.next-button span {
    flex-grow: 1;
    text-align: center;
}

.next-button svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.next-button:hover {
    opacity: 0.9;
}

.character-creation-header{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    color: white;
    margin-bottom: 15px;
}

.main-title {
    margin-bottom: 0;
    font-size: 1.75em;
    font-weight: 700;
    margin-top: 0;
}

.main-description {
    font-weight: 500;
    font-size: 1.15em;
    text-align: center;
    letter-spacing: -0.01em;
    color: #C4C4C4;
    margin-bottom: 0;
}

.mobile-advanced-char{
   display: none;
}

.header-char-creator-switch-mobile{
    display: none;
}

@media screen and (min-width: 1980px) {
    .step-content{
        min-width: 920px;
        max-width: 920px;
    }
    .progress-bar-container-char{
        min-width: 920px;
        max-width: 920px;
    }
}

@media screen and (min-width: 1680px) and (max-width: 1980px) {
    .step-content{
        min-width: 840px;
        max-width: 840px;

    }
    .progress-bar-container-char{
        min-width: 840px;
        max-width: 840px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1680px) {
    .step-content{
        min-width: 760px;
        max-width: 760px;
    }
    .progress-bar-container-char{
        min-width: 760px;
        max-width: 760px;
    }
}

@media screen and (min-width: 680px) and (max-width: 1280px) {
    .step-content{
        min-width: 700px;
        max-width: 700px;
    }
    .progress-bar-container-char{
        min-width: 700px;
        max-width: 700px;
    }
}

/*//TODO: blur*/

@media screen and (min-width: 0px) and (max-width: 680px) {

    .steps-container {
        padding-bottom: 80px;
    }

    .character-creation-header{
        z-index: 500;
    }

    .main-title {
        font-size: 1.35em;
    }

    .main-description {
        font-size: 0.95em;
    }

    .progress-bar-container-char {
        max-width: 100%;
        min-width: 100%;
    }
    .steps-container{
        max-width: 100%;
        min-width: 100%;
        padding: 5px;
    }
    .step-content {
        max-width: 100%;
        min-width: 100%;
        padding: 15px;
        margin-bottom: 30vh;
    }
    .step-title{
        font-size: 1.25em;
    }
    .step-subtitle{
        font-size: 0.85em;
    }
    .nav-button{
        padding: 10px 18px;
    }
    .header-char-creator-switch-mobile{
        display: flex;
        height: 50px;
    }
    .header-switch-option{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .mobile-advanced-char{
        display: block;
        z-index: 9999;
        width: 100%;
        position: fixed;
        bottom: 0;
        border-top: 1px solid #242424;
        background-color: #131313;
    }
}