
.voice-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 400px;
    overflow-y: auto;
    padding: 10px 0;
}

.button-container-cloning {
    display: flex;
    align-items: center;
    gap: 1px;
    border-radius: 8px;
    overflow: hidden;
}

.play-button {
    background: #151515;
    color: #c4c4c4;
    font-weight: 500;
    font-size: 1em;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 65px;
    transition: background 0.2s;
}

.button-divider {
    width: 1px;
    align-self: stretch; /* stretches to match parent height */
    background-color: #c4c4c4;
}

.play-button.left{
    border-radius: 8px 0 0 8px;
}

.play-button.left:hover {
    background: #3a3a3a;
    border-radius: 8px 0 0 8px;
}

.play-button.right{
    border-radius: 0 8px 8px 0;
}

.play-button.right:hover {
    background: #3a3a3a;
    border-radius: 0 8px 8px 0;
}

.voice-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    background: #151515;
    border: 1px #c4c4c4 solid;
    color: #fff;
    border-radius: 8px;
}

.voice-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}



@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
