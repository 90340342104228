.chat-message-bot-h {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    transition: transform 0.5s ease; /* Add this line */
}


.universal-help-popup{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
}

.surfer-h-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.central-surfer-component {
    display: inline-block;
    position: relative;
    width: 100%;
    cursor: pointer;
    color: #000;
    text-align: center;
    vertical-align: middle;
    z-index: 0;
}


.main-surfer-line {
    display: inline-block;
    vertical-align: middle;
}

.surfer-control-timer {
    display: flex;
    min-width: 54px;
    min-height: 54px;
    margin: 7px 10px 7px 0;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #9A73F7;
    cursor: pointer;
}

.surfer-control-timer svg {
    min-width: 32px;
    min-height: 32px;
}

.bot-chat-container {
    display: flex;
    /*flex-direction: row;*/
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    min-height: 60px;
    min-width: 150px;
    gap: 10px;
}

.bot-text {
    position: relative;
    align-self: flex-start;
}

.loading-overlay {
    display: flex;

    flex-direction: column;
    text-align: center;
    position: relative;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cover-full {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
}

.ai-chat-message-main {
    align-items: center;
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #6f46d1;
    padding: 15px;
    border-radius: 0 16px 16px 16px;
    transition: transform 1s ease; /* Add this line */
}

.ai-chat-message-main-2 {
    align-items: center;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 15px;
    box-shadow: 0 2px 5px 0 #E8E8E840;
    border-radius: 0 16px 16px 16px;
    position: relative;
    align-self: flex-end;
    background-color: #9c4ac1;
    color: white;
}

.chat-image {
    position: relative;
    border-radius: 0 14px 14px 14px;
    max-width: 40vw;
    object-fit: cover;
    min-height: 420px;
    background-color: #131313;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px 0 #E8E8E840;
    /*border: 1px solid #C4C4C4;*/
}

.chat-image.hidden {
    display: none;
}


.chat-image.visible.blur {
    filter: blur(5px);
    /*border: 1px solid #C4C4C4;*/
}

.ai-chat-message-image:hover .chat-image.blur:not(.fullscreen) {
    filter: blur(5px);
}

.loading-text {
    font-weight: 600;
    font-size: 1.16em;
}

.loading-image-container {
    flex-direction: column;
    align-items: center;
    display: flex;
    position: relative;
    width: 30vw;
    max-width: 50vw;
    height: 50vh;
    max-height: 400px;
    margin-top: 40px;
    justify-content: center;
    background-color: #131313;
    border-radius: 0 14px 14px 14px;
    border: 2px solid #242424;
}

.loading-image-container-2 {
    flex-direction: column;
    align-items: center;
    display: flex;
    position: relative;
    width: 30vw;
    max-width: 50vw;
    height: 50vh;
    max-height: 400px;
    justify-content: center;
    background-color: #6f46d1;
    border-radius: 0 16px 16px 16px;

}

.loading-image-container-3 {
    flex-direction: column;
    align-items: center;
    display: flex;
    position: relative;
    width: 30vw;
    max-width: 50vw;
    height: 50vh;
    max-height: 400px;
    justify-content: center;
    background-color: #131313;
    border-radius: 0 16px 16px 16px;


}

.loading-image-text {
    font-size: 1.3em;
    font-weight: 700;
    flex-direction: row;
    display: flex;
    align-items: center;
    align-self: center;
    width: 100%;
    text-align: center;
    justify-content: center;
    padding-bottom: 5px;
    margin-top: 15px;
}

.loading-image-text-2 {
    font-size: 1.1em;
    font-weight: 700;
    flex-direction: row;
    display: flex;
    align-items: center;
    align-self: center;
    width: 100%;
    text-align: center;
    justify-content: center;
    padding-bottom: 5px;
}


.ai-chat-message-image {
    padding: 5px;
    margin-top: 40px;
    cursor: pointer;
    border-radius: 0 16px 16px 16px;
    transition: transform 0.1s ease-in-out; /* Add this line */
}


.ai-chat-message-image:hover .chat-image {
    filter: brightness(80%);

}

.ai-chat-message-image:hover .chat-image.fullscreen {
    filter: brightness(100%);
}

.fullscreen {
    width: 100vw;
    height: 100vh;
    object-fit: contain;
}

.ai-chat-message-image:fullscreen,
.ai-chat-message-image:-webkit-full-screen,
.ai-chat-message-image:-moz-full-screen {
    position: relative;
}

.ai-chat-message-image:fullscreen .like-dislike-buttons,
.ai-chat-message-image:-webkit-full-screen .like-dislike-buttons,
.ai-chat-message-image:-moz-full-screen .like-dislike-buttons {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 9;
}

.ai-chat-message-image:hover .chat-image:not(.fullscreen) {
    filter: brightness(80%);
}


.ai-chat-message-image:hover .like-dislike-buttons {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.like-dislike-buttons {
    display: none;
    position: absolute;

}

.like-icon, .dislike-icon {
    cursor: pointer;
    stroke: white;
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.5);
    fill: white;
    border-radius: 6px;
    margin-right: 8px;
}

.like-icon svg, .dislike-icon svg {
    width: 20px;
}


.like-icon:hover, .dislike-icon:hover {
    opacity: 0.65;
}


.icon-like-selected {

    cursor: pointer;
    stroke: white;
    padding: 4px 8px;
    background-color: rgba(255, 255, 255, 0.8);
    fill: #2f2f2f;
    border-radius: 6px;
    margin-right: 8px;

}

.like-icon, .dislike-icon {
    cursor: pointer;
}

.message-loading-spin {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
}

.bot-chat-container-information {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


/*.continue-text {*/
/*    font-size: 0.8750em;*/
/*}*/


.bot-chat-continue-button svg {
    fill: black;
    width: 24px;
    height: 24px;
}

.anime-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 12px;
    border-radius: 16px;
    aspect-ratio: 2/3;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none;
}

.view-image-premium-button {
    display: flex;
    flex-direction: row;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #7136FE;
    color: white;
    width: 70%;
    height: 55px;
    border-radius: 24px;
    padding: 12px;
    cursor: pointer;
    gap: 5px;
    font-weight: 700;
    font-size: 1.25em;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    white-space: nowrap;

    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.view-image-premium-button:hover {
    background-color: #5f2edc;
    opacity: 0.95;
}

.view-image-diamond-icon {
    height: 24px;
    width: 24px;
}

@media screen and  (max-width: 480px) {
    .loading-image-container-3,
    .loading-image-container-2 {
        width: 60vw;
        max-width: 60vw;
        max-height: 345px;
    }
}


@media screen and (max-width: 680px) {

    .bot-chat-continue-button {
        margin-top: 5px;
        margin-right: 50px;
        position: absolute;
        cursor: pointer;
    }

    .loading-image-container {
        width: 50vw;
        max-width: 50vw;
        max-height: 350px;
        border-radius: 0 14px 14px 14px;
        border: 2px solid #242424;
    }

    .view-image-premium-button {
        font-size: 0.95em;
        height: 45px;
    }

    .view-image-premium-button svg {
        height: 22px;
        width: 22px;
        min-height: 22px;
        min-width: 22px;
        max-width: 22px;
        max-height: 22px;
    }

    .like-dislike-buttons {
        display: flex;
        flex-direction: row;
        padding: 10px;
    }

    .like-icon, .dislike-icon {
        padding: 4px 8px;
    }

    .like-icon:hover, .dislike-icon:hover {
        opacity: .85;
    }

    .chat-image {
        min-height: 260px;
        min-width: 140px;

    }
}

@media screen and (min-width: 480px) and (max-width: 680px) {
    .chat-image {
        max-width: 45vw;
        min-height: 360px;
    }

    .loading-image-container-3,
    .loading-image-container-2 {
        width: 45vw;
        max-width: 50vw;
        max-height: 360px;
    }
}

@media screen and (min-width: 680px) and (max-width: 780px) {
    .loading-image-container-3,
    .loading-image-container-2 {
        width: 35vw;
        max-width: 45vw;
        /*max-height: 380px;*/
    }

    .chat-image {
        /*min-height: 380px;*/
    }
}


@media screen and (min-width: 780px) and (max-width: 980px) {
    .chat-image {
        max-width: 35vw;
        min-height: 400px;
    }

    .loading-image-container-3,
    .loading-image-container-2 {
        width: 30vw;
        max-width: 45vw;
        max-height: 400px;
    }

    .view-image-premium-button {

        width: 70%;
        height: 55px;
        font-size: 0.95em;

    }
}

@media screen and (min-width: 980px) and (max-width: 1280px) {
    .chat-image {
        max-width: 30vw;
        min-height: 380px;
    }

    .loading-image-container,
    .loading-image-container-3,
    .loading-image-container-2 {
        width: 25vw;
        max-width: 25vw;
        max-height: 380px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1480px) {


    .chat-image {
        max-width: 20vw;
        min-height: 400px;
    }

    .loading-image-container,
    .loading-image-container-3,
    .loading-image-container-2 {
        width: 20vw;
        max-width: 25vw;
        max-height: 400px;
    }
}

@media screen and (min-width: 1480px) and (max-width: 1920px) {


    .chat-image {
        max-width: 20vw;
        min-height: 440px;
    }

    .loading-image-container,
    .loading-image-container-3,
    .loading-image-container-2 {
        width: 20vw;
        max-width: 20vw;
        max-height: 440px;
    }
}

@media screen and (min-width: 1920px) {
    .loading-image-container,
    .loading-image-container-3,
    .loading-image-container-2 {
        max-width: 20vw;
        max-height: 560px;
    }

    .chat-image {
        max-width: 20vw;
        min-height: 560px;
    }
}
