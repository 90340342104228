
.features-container {
    display: flex;
    justify-content: center;
    gap: 10px;

}

.features-container.second-row {
    justify-content: space-around;
    padding: 30px;
}

.features-section{
    padding: 80px;
    background: linear-gradient(325.24deg, rgba(82, 0, 255, 0.23) -61.78%, rgba(19, 19, 19, 0.8) 79.82%),
    linear-gradient(149.02deg, rgba(82, 0, 255, 0.53) -50.53%, rgba(19, 19, 19, 0) 70.77%);
}
.feature-icon-text{
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 1.375em;
    gap: 15px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #FFFFFF;
}


.feature-title{
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Styling for individual feature items */
.feature-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    padding: 20px 5px;

}

/* Styling for feature icons */
.feature-icon {
    width: 44px; /* Adjust the icon size as needed */
    height: 44px;
    stroke: white;
}

.feature-description{
    color: #D6D6D6;
    font-size: 1em;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.01em;
    margin: auto;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;

}
@media screen and (max-width: 680px) {
    .features-section{
        padding: 40px 25px;

    }
    .features-container {

        align-items: flex-start;
        padding: 5px;

    }

    .features-container.second-row {
        justify-content: center;
    }

    .feature-item {

        width: 50%;
        padding: 0;

    }

    .feature-icon-text {
        flex-direction: column; /* Stack icon and text vertically */
        align-items: center; /* Center align */
        text-align: center; /* Center the text */
    }

    .feature-icon {
        margin-bottom: 10px; /* Space between icon and text */
    }

    .feature-description {
        font-size: 0.8750em; /* Adjust font size */
        text-align: center; /* Center the title text */
    }


    .feature-name {
        font-size: 1.125em; /* Adjust the feature name font size */
    }

    /* Additional adjustments for titles and subtitles */
    .upper-text, .lower-text{
        font-size: 1.625em; /* Reduce font size for smaller screens */
    }
    .carousel-subtitle-2{
        font-size: 1em;


    }


    .feature-name{
        font-size: 0.75em;
        white-space: normal;
    }
}





