.chat-page-loader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    gap: 40px;
    /*width: calc(100vw - 360px);*/
    background-color: #131313;
    align-items: center;
    overflow: hidden;
    z-index: 9999;
    border-top: 2px solid #242424;
    margin-top: 85px;
    height: calc(100vh - 85px);
}

.chat-loading-skeleton {
    z-index: 99;
    display: flex;
    width: auto;

    position: relative;
    height: 100%;
    background-color: #131313;
}


@keyframes glistening {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

.message {
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    max-width: 80%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.user-message, .bot-message {
    position: relative;
    padding: 16px;
    width: 70%;
    border-radius: 24px;
    overflow: hidden;
}

.user-message {
    padding: 16px;
    width: 70%;
    margin-left: 15px;
    background-color: #1e1e1e;
    animation: glistening 1.5s infinite;
    background-size: 200% 100%;
    border-radius: 0 24px 24px 24px;
    align-self: flex-start;
}

.bot-message {
    padding: 16px;
    margin-right: 15px;
    width: 40%;
    background-color: #1e1e1e;
    animation: glistening 1.5s infinite;
    background-size: 200% 100%;
    border-radius: 24px 24px 0 24px;
    align-self: flex-end;
}


.send-message-skeleton {
    width: 85%;
    margin: 40px auto 20px;
    padding: 8px;
    border-radius: 24px;

    background-color: #1e1e1e; /* Your desired solid background color */
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    animation: glistening 1.5s infinite;
    position: relative;
}

.gliding-rectangle {
    width: 100%;
    height: 130px;
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    position: relative;
    animation: glistening 1.5s infinite;
}


.user-message::after, .bot-message::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    animation: glistening 1.5s infinite;
    background-size: 200% 100%;
}

.user-message {
    border-radius: 0 24px 24px 24px;
}

.bot-message {
    border-radius: 24px 24px 0 24px;
}

@media (max-width: 680px) {
    .chat-page-loader {
        width: 100vw;
        gap: 10px;
        border-top: 0;
    }
    .chat-loading-skeleton {
        display: flex;
        width: auto;
        position: relative;
        height: 100%;
        z-index: 9999;
        margin-top: -165px;
    }
    .gliding-rectangle {

        height: 80px;

    }
    .send-message-skeleton {
        margin: 20px auto 80px;
    }

}
