@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(5px); }
    50% { transform: translateX(-5px); }
    75% { transform: translateX(5px); }
    100% { transform: translateX(0); }
}

.shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

.floating-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #151515;
    border: 1px solid #4C4C4C;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000;
}