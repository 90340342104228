.sales-hero-container {
    background: transparent;
    overflow: hidden;
    display: flex;
    padding: 100px 60px 0;
    flex-direction: row;
    /*justify-content: space-between;*/
    align-items: flex-start;
}

.sales-left-container {
    max-width: 50vh;
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;


}

.sales-title {
    font-size: 3.375em;
    font-weight: 600;
    line-height: 65px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #FFFFFFFF;
    white-space: nowrap;
}

.sales-title-2 {
    font-size: 3.375em;
    font-style: italic;
    font-weight: 800;
    letter-spacing: -0.01em;
    text-align: left;
    background: linear-gradient(94.33deg, #FF9FEA 30.43%, #FFFFFF 86.78%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    white-space: nowrap;
    display: inline-block;
}


.sales-subtitle {
    position: relative;
    font-size: 1.375em;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #DCDCDC;
}

.united-image-container-sales {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    margin-left: 10%;

}

.container-bottom-left,
.container-bottom-right {
    position: relative;
    width: auto;
    height: 420px;
    z-index: -1;
    display: flex;
    bottom:  0;
}

.container-bottom-left {
    /*width: 15%;*/
    right: 0;
}

.container-bottom-right {
    min-width: 350px;

}

.container-bottom-left img,
.container-bottom-right img {
    width: auto;
    height: auto;
    pointer-events: none;
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
    transform-origin: bottom;
    transform: scale(0.9);
    opacity: 0.5;

}


@media screen and (max-width: 680px) {
    .sales-hero-container {
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 40px 25px 0;
    }
    .sales-left-container {
        width: 100%;
        align-items: flex-start;
        justify-content: center;
        text-align: left;

    }
    .united-image-container-sales {
        margin-top: -290px;
        margin-left: 0;

    }

    .sales-title {
        font-size: 2.375em;

    }

    .sales-title-2 {
        font-size: 2.65em;
    }


}


@media screen and (min-width: 1280px) and (max-width: 1720px) {
    .container-bottom-left,
    .container-bottom-right {
        height: 520px;

    }
    .united-image-container-sales {
        margin-top: -100px;
        margin-left: 15%;

    }
}

@media screen and (min-width: 1720px) and (max-width: 1920px) {
    .container-bottom-left,
    .container-bottom-right {
        height: 540px;

    }
    .united-image-container-sales {
        margin-top: -120px;
        margin-left: 20%;

    }
}

@media screen and (min-width: 1920px) {
    .container-bottom-left,
    .container-bottom-right {
        height: 620px;

    }
    .united-image-container-sales {
        margin-top: -160px;
        margin-left: 25%;

    }


}