.image-count-container {
    display: flex;
    flex-direction: row;
}

.style-select{
    display: flex;
    flex-direction: row;
}

.model-select-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
}

.aspect-ratio-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.image-count-number {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px #4F4F4F solid;
    justify-content: center;
    border-radius: 6px 0 0 6px;
    width: 60px;
    gap: 3px;
    font-size: 1.15em;
}

.image-count-number svg{
    min-width: 22px;
    min-height: 22px;
    max-width: 22px;
    max-height: 22px;
}

.image-counter-change {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px #4F4F4F solid;
    border-left: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.arrow-control-counter svg {
    min-width: 20px;
    min-height: 20px;
    max-height: 20px;
    max-width: 20px;
}

.arrow-control-counter,
.arrow-general-change {
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow-control-counter{
    cursor: pointer;
}


.arrow-control-counter.top:hover{
    background-color: #242424;
    border-top-right-radius: 6px;
}

.arrow-control-counter.bottom:hover{
    background-color: #242424;
    border-bottom-right-radius: 6px;
}