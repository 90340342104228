.auth-modal {
    z-index: 999;
    overflow: hidden;
}


.signup-form {
    min-width: 400px;
    max-width: 450px;
    height: auto; /* Auto height for better content fit */
    margin: 20px auto; /* Consistent margin for top and bottom */
    padding: 30px;
    width: 40vw;
    overflow: hidden;
    border: 1px solid #2a2a2a; /* Added border for better visibility */
    border-radius: 16px; /* Increased radius for a modern look */
    color: white;
    background-color: #131313;
    text-align: left;
    position: relative; /* Changed to relative for better responsiveness */
    user-select: none;
}

.login-container {
    user-select: none;
    width: 35vw;
    min-width: 400px;
    max-width: 450px;
    border: 1px solid #2a2a2a;
    background-color: #131313;
    height: auto; /* Auto height for better content fit */
    margin: 20px auto; /* Consistent margin for top and bottom */
    padding: 30px;
    border-radius: 10px; /* Increased radius for a modern look */
    color: white;
    align-items: center;
    text-align: left;
    position: relative; /* Changed to relative for better responsiveness */
}


.text-field-header {
    color: #C4C4C4;
}

.label-sign-up-h {
    color: #C4C4C4;
    white-space: nowrap;
    width: fit-content;
}

.a-straight-line,
.a-straight-line-2,
.a-straight-line-3 {
    z-index: -1;
    width: 100%;
    height: 1px;
    flex: none;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #696969;
}

.a-straight-line-2 {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #696969;
}

.a-straight-line-3 {
    margin-top: 15px;
    margin-bottom: 15px;

}


.social-signup-container {
    flex-direction: column;
}

.double-social-signup-button{
    display: flex;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
}


.social-signup-button.discord {
    background: white;
    color: #ffffff;
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 600;
    font-size: 1.15em;
    gap: 10px;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.or-container {
    display: flex;
    align-items: center;
    padding: 5px;
}

.line {
    flex: 1; /* Allows lines to fill up the space */
    height: 1px; /* Line thickness */
    background-color: #696969; /* Primary color for line */
}

.or-label {
    padding: 0 20px;
    color: #C4C4C4;
    background-color: #131313;
    border-radius: 50px;
}


.signup-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    font-size: 2em;
    text-space: 2px;
    font-weight: bold;
}


.signup-button {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #916DE8;
    color: #fff;
    font-size: 1.25em;
    font-weight: 600;
    border: none;
    border-radius: 14px;
    padding: 12px;
    cursor: pointer;
    max-width: 400px;
    transition: background-color 0.3s ease-in-out;
}

.login-mobile-button {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

    color: #fff;
    font-size: 1.25em;
    font-weight: 600;
    border: none;
    border-radius: 14px;
    padding: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.signup-button:hover {
    background-color: rgba(145, 109, 232, 0.8);
}

.signup-button:disabled {
    background-color: #916DE8;
    opacity: 0.5;
    cursor: not-allowed;
}

.popup-link {
    color: #C4C4C4;
    align-self: flex-start;
    padding-left: 0;
}

.popup-link-bottom {
    margin-top: 10px;
    padding-left: 0;
}

.underline {
    position: relative; /* Allows absolute positioning of the pseudo-element relative to this element */
    font-weight: 700;
    margin-left: 5px;
    color: #AA87FF; /* Adjusted to the color code you provided */
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
    overflow: hidden; /* Ensures the pseudo-element does not extend outside */
}

.underline::after {
    content: '';
    position: absolute;
    bottom: -1px; /* Adjust based on desired underline position */
    left: 0;
    width: 0;
    height: 2px; /* Adjust thickness of the underline */
    background-color: #AA87FF; /* Underline color */
    transition: width 0.3s ease; /* Smooth transition for the width */
}

.underline:hover::after {
    width: 100%; /* Full width on hover */
}

.social-signup-divider {
    margin-top: 15px;
}

.tos {
    color: #C4C4C4;
    font-size: 0.875em;
}

.social-signup-container {
    margin-bottom: 8px;
    margin-top: 14px;
    display: flex;
    gap: 10px;
    left: 0;
    right: 0;
    position: relative;
    align-items: center;
    justify-items: center;
    align-content: center;
    text-align: center;
    align-self: center;
}

.social-signup-button {
    background-color: #ffffff;
    color: #333;
    display: inherit;
    align-items: center;
    width: 100%;
    font-weight: 600;
    gap: 6px;
    height: 45px;
    font-size: 1.15em;
    justify-content: center;
    position: relative;
    border-radius: 16px;
    padding: 6px 8px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.social-signup-button:hover {
    background-color: #e1e1e1;
}

.social-signup-button.discord {
    background: #7289DA;
    color: #ffffff;
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 600;
    font-size: 1.15em;
    gap: 10px;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.social-signup-button-icon-discord {
    width: 24px;
    height: 24px;
}

.social-signup-button-icon-google {
    width: 26px;
    height: 26px;
}

.social-signup-button.discord:hover {
    background: rgba(115, 137, 218, 0.8);
}

.error-message {
    color: #d32f2f;
    font-size: 0.9em;
    margin-top: 5px;

}

@media screen and (min-width: 1280px) {
    .label-sign-up-h {
        font-size: 1.1em;
    }

    .popup-link {
        font-size: 0.95em;
    }
}


@media screen and (min-width: 680px) and (max-width: 1280px) {
    .popup-link {
        font-size: 0.9em;
    }
}

@media screen and (min-width: 0px) and (max-width: 680px) {

    .label-sign-up-h {
        font-size: 0.9em;
    }

    .signup-form {
        width: 90vw;
        min-width: 300px;
        max-width: 400px;
    }

    .or-container {
        padding: 0;

    }

    .text-field-header {
        font-size: 0.85em;

    }

    .login-container {
        width: 90vw;
        min-width: 300px;
        max-width: 400px;
        max-height: 560px;
    }

    .signup-label {
        font-size: 1.5em;
    }

    .signup-button {
        font-size: 1em;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .social-signup-button {
        font-size: 1em;
        height: 40px;
    }

    .social-signup-button.discord {
        font-size: 1em;
        height: 40px;
    }

    .social-signup-button-icon-discord {
        width: 20px;
        height: 20px;
    }

    .social-signup-button-icon-google {
        width: 20px;
        height: 20px;
    }

    .tos {
        font-size: 0.75em;
    }

    .popup-link {
        font-size: 0.75em;
    }

    .popup-link-bottom {
        font-size: 0.75em;
    }

    .error-message {
        font-size: 0.75em;
    }

    .login-mobile-button {
        font-size: 1em;
    }
}











