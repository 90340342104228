.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1050;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-modal-content-delete-char {
    position: relative;
    color: white;
    background-color: #131313;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 380px;
}

.deletion-char-modal {
    text-align: left;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;

}

.close-icon {
    position: relative;
    cursor: pointer;
    align-self: flex-end;
}

.close-icon svg {
    height: 18px;
}

.deletion-text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 20px;
    white-space: nowrap;

}


