.selection-menu-general {
    flex-shrink: 0;
    display: flex;
    position: absolute;
    justify-content: flex-end;
    width: 100%;
    bottom: -25px;
    right: 0;
    transition: margin-top 0.5s ease;
}

.selection-menu-general.fadeIn {
    margin-top: 0;
}

.selection-menu-general:not(.fadeIn) {
    margin-top: -10px;
}

.fadeIn {
    animation: fadeInAnimation 0.5s ease forwards;
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.icons_functional {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    margin-left: -50px;
    pointer-events: auto;
}

.continue-button,
.image-gen-button,
.regenerate-button,
.voice-button,
.delete-message-button {
    position: relative;
    cursor: pointer;
    pointer-events: auto;
}

.continue-button-off,
.image-gen-button-off,
.regenerate-button-off,
.voice-button-off,
.delete-message-button-off {
    cursor: not-allowed;
    pointer-events: none;
}

.loading-button {
    position: relative;
    display: flex;
    align-items: center;
}


@media screen and (max-width: 680px) {
    .selection-menu-general {
        justify-content: flex-start;
        bottom: -30px;
    }
    .icons_functional{
        margin-left: 0;
    }

    .continue-button,
    .image-gen-button,
    .regenerate-button,
    .voice-button,
    .delete-message-button {
        display: flex;
        padding: 4px;
        position: relative;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        pointer-events: auto;
        background: #131313;
        border: 1px solid #242424;
        border-radius: 6px;
    }
}