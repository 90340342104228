
.view-memory-modal-main {
    background-color: #131313;
    border-radius: 8px;
    padding: 10px;
    width: 80%;
    max-width: 680px;
    min-height: 400px;
    max-height: 560px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.memory-content{
    display: flex;
    flex-direction: column;
}
.modal-header-memory {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-header-memory h2 {
    margin: 0;
    font-size: 1.2rem;
}

.modal-content-memory {
    flex-grow: 1;
    margin: 0 24px 24px;
    border-radius: 12px;
    overflow-y: auto;
    padding: 32px 24px 0;
    background-color: #131313;
}

.modal-content-memory {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: url('./backgroundShapes.svg'),
    linear-gradient(97.28deg, rgba(99, 87, 115, 0.3) 33.62%, rgba(57, 42, 64, 0.3) 75.64%), #131313;
    background-size: cover;
    border-radius: 24px;
}

.modal-content-memory::-webkit-scrollbar {
    display: none;
}

.memory-message {
    max-width: 70%;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 10px;
    word-wrap: break-word;
    color: white;
}

.memory-message-bot {
    display: flex;
    align-self: flex-start;
    position: relative;
    background-color: #6f46d1;
    max-width: 85%;
    min-height: 65px;
    min-width: 60px;
    border-radius: 0 16px 16px 16px;
    color: white;
}

.memory-message-user {
    display: flex;
    position: relative;
    justify-content: flex-end;
    max-width: 45%;
    margin-bottom: 30px;
    margin-top: 20px;
    min-width: 60px;
    padding-right: 20px;
    align-self: flex-end;
    border-radius: 16px 16px 0 16px;
    background-color: #9c4ac1;
    color: white;
}

.loading-message{
    display: flex;
    flex-direction: column;
    min-width: 100%;
}

.loading-message-bot{
    display: flex;
    align-self: flex-start;
    position: relative;
    background-color: #1e1e1e; /* Your desired solid background color */
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    animation: glistening 1.5s infinite;
    max-width: 85%;
    min-height: 120px;
    min-width: 85%;
    border-radius: 0 16px 16px 16px;
}

.loading-message-user{
    display: flex;
    position: relative;
    justify-content: flex-end;
    max-width: 45%;
    margin-bottom: 30px;
    min-height: 60px;
    margin-top: 20px;
    min-width: 45%;
    padding-right: 20px;
    align-self: flex-end;
    border-radius: 16px 16px 0 16px;
    background-color: #1e1e1e;
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    animation: glistening 1.5s infinite;
}

@media screen and (max-width: 680px) {
    .view-memory-modal-main {
        width: 100%;
        max-width: 600px;
        min-height: 450px;
        max-height: 550px;
    }
    .memory-message-user {
        max-width: 70%;
    }
    .memory-message-bot {
        max-width: 95%;
    }
}