.character-container-new {
    position: relative;
    cursor: pointer;
    user-select: none;
    height: 300px;
    aspect-ratio: 1 / 1.2;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 24px;
    overflow: hidden;
    max-width: 210px;
    border-width: 1px;
    border-color: #2f2f2f;
    border-style: solid;
}

.character-container-new:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    border-radius: 24px;
}

.character-image-overlay-container-new {
    height: 100%;
    display: flex;
    width: 100%;
    position: relative;
    /*flex-direction: column;*/
    border-radius: 24px;
    justify-content: flex-end;
}

.character-image-main-new {
    width: 200px;
    height: 300px;
    object-fit: cover;
    display: block;
    border-radius: 24px;
    background-size: cover;
}

.blur-unblur-general {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    align-items: center;
    gap: 5px;
    z-index: 999;
}

.nsfw-blur-text {
    width: 85%;
    align-items: center;
    text-align: center;
    color: #c4c4c4;
    font-weight: 550;
    background-color: rgba(35, 35, 35, 0.65);
    padding: 4px 6px;
    font-size: 0.95em;
    border-radius: 4px;
    border: 1px solid rgba(76, 76, 76, 0.7);
}

.unblur-button-character {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 12px;
    font-size: 0.85em;
    font-weight: 600;
    cursor: pointer;
    z-index: 1;
    transition: background-color 0.3s ease;
}

.unblur-button-character:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.image-container.blurred img {
    filter: blur(8px);
}

.character-gradient-overlay-new {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*border-radius: 24px;*/
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent); /* Gradient overlay */

}

.character-text-content-new {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 10px 10px;
    /*max-height: 75px;*/
    color: white;
}

.character-name-new {
    padding: 0 10px;
    color: #FFF;
    font-size: 1.25em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.46px;
    white-space: break-spaces;
}

.character-svg-altcard {
    height: 100%;
    width: 100%;
    align-self: center;
    background-image: url('./icons/altcard.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.character-description-new {
    padding: 0 10px 10px;
    color: #AEAEAE;
    font-size: 0.75em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.26px;
}

.custom-tooltip-card {
    z-index: 100;
}

@media (max-width: 680px) {
    .character-container-new {
        width: 190px; /* This is your width */
    }

    .character-image-main-new {
        width: 190px;
        height: 300px;
        object-fit: cover;
        display: block;
        background-size: cover;
    }

    .character-name-new {
        font-size: 0.85em;
        padding: 0 5px;
        color: #FFF;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.46px;


    }

    .character-description-new {
        font-size: 0.6em;
        padding: 5px 5px 10px;

    }

    .character-text-content-new {
        max-height: 65px;
    }

}

@media (min-width: 680px) and (max-width: 1280px) {
    .character-container-new {
        width: 380px;
        height: 340px;
    }

    .character-image-main-new {
        width: 220px;
        height: 340px;
        object-fit: cover;
        display: block;
        background-size: cover;
    }

    .character-name-new {
        font-size: 1em;
        padding: 0 5px;
        color: #FFF;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.46px;
    }

    .character-description-new {
        font-size: 0.7em;
        padding: 0 5px 0;
        min-height: 40px;
    }

    .character-text-content-new {
        max-height: 85px;
    }
}

@media (min-width: 1280px) {
    .character-container-new {
        width: 400px; /* This is your width */
        height: 380px;
    }

    .character-image-main-new {
        width: 240px;
        height: 380px;
        object-fit: cover;
        display: block;
        background-size: cover;
    }

    .character-name-new {
        font-size: 1.4em;
        padding: 0 5px;
        color: #FFF;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.46px;


    }

    .character-description-new {
        font-size: 0.8em;
        min-height: 40px;
        padding: 5px 5px 0;

    }

    .character-text-content-new {
        max-height: 105px;
    }
}