.character-card-loader-column {
    height: 100vh;
    width: 100%;
    margin-top: 85px;
    position: absolute;
    background-color: #131313;
    z-index: 9999;
}

.character-card-loader-main {
    display: flex;
    gap: 40px;
    height: fit-content;
    width: 100%;
    border-top: 1px solid #2D2D2D;
}

.character-loader-bottom-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    gap: 40px;
    margin-top: 25px;
}

.character-loader-top-right {
    display: flex;
    width: 100%;
    gap: 10px;
}

.character-loader-right-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 65px;
    padding-right: 40px;
    width: 100%;
    height: 100%;
}

.character-loader-left-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 65px;
    padding-left: 40px;
}

.character-loader-profile {
    max-width: 300px;
    min-width: 300px;
    min-height: 420px;
    max-height: 420px;
    border-radius: 12px;
    background: #e0e0e0;
    animation: shine 1.5s infinite ease-in-out;
}

.character-loader-tags {
    display: flex;
    flex-direction: row;
    gap: 5px;
    min-width: 300px;
}

.character-loader-tag {
    width: 80px;
    height: 35px;
    border-radius: 16px;
}

.character-loader-similar-chars {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 300px;
    height: fit-content;
}

.character-loader-similar-char-label {
    width: 100%;
    height: 20px;
    border-radius: 4px;
}

.character-loader-similar-char {
    width: 300px;
    height: 80px;
    border-radius: 14px;
}

.character-loader-text-profile {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.character-loader-name {
    width: 35%;
    height: 35px;
    border-radius: 4px;
}

.character-loader-label {
    width: 85%;
    height: 65px;
    border-radius: 4px;
}

.character-loader-gf-button-main {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.character-loader-gf-button {
    min-width: 200px;
    max-width: 300px;
    height: 50px;
    border-radius: 12px;
}

.character-loader-sub-button-norm {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.character-loader-sub-button {
    width: 60px;
    height: 40px;
    border-radius: 12px;
}

.character-loader-sound-playback {
    width: 40%;
    height: 95px;
    margin-top: 40px;
    border-radius: 0 12px 12px 12px;
}

.character-loader-about-me-general {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.character-loader-about-me-label {
    width: 20%;
    height: 20px;
    border-radius: 4px;
}

.character-loader-about-me, .character-loader-public-chats {
    width: 100%;
    height: 150px;
    border-radius: 4px;
}

.character-loader-public-chat-container {
    width: 50%;
    height: 300px;
    border-radius: 4px;

}

.character-loader-public-chats-general {
    width: 100%;
    height: fit-content;
}

@keyframes shine {
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: 200px 0;
    }
}

.character-loader-profile, .character-loader-tag, .character-loader-similar-char, .character-loader-name,
.character-loader-label, .character-loader-gf-button, .character-loader-sub-button,
.character-loader-sound-playback, .character-loader-about-me, .character-loader-public-chats, .character-loader-similar-char-label, .character-loader-about-me-label, .character-loader-public-chats-general, .character-loader-public-chat-container {
    background-color: #1e1e1e; /* Your desired solid background color */
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    animation: glistening 1.5s infinite;
}

.character-loader-tag, .character-loader-similar-char, .character-loader-name, .character-loader-label,
.character-loader-gf-button, .character-loader-sub-button, .character-loader-sound-playback,
.character-loader-about-me, .character-loader-public-chats, .character-loader-similar-char-label, .character-loader-about-me-label, .character-loader-public-chats-general, .character-loader-public-chat-container {
    background-color: #1e1e1e; /* Your desired solid background color */
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    animation: glistening 1.5s infinite;
}

@media screen and (max-width: 720px) {
    .character-loader-left-column {
        justify-content: center;
        align-items: center;
        padding-left: 40px;
        padding-right: 40px;
    }

    .character-card-loader-main {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }

    .character-loader-about-me-general,
    .character-loader-right-column {
        display: none;
    }
    .character-loader-bottom-row{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}
