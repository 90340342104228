.public-chats-mobile-container {
    padding: 20px 0;
}

.slider-chat-item {
    padding: 0 10px;
}

.chat-card {
    background: #1F1F1F;
    border-radius: 14px;
    padding: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    height: 100%;
    max-width: 400px;
}

.public-chats-slider {
    position: relative;
}

.public-chats-slider .slick-dots {
    bottom: -30px;
}

.public-chats-slider .slick-dots li button:before {
    font-size: 8px;
}

.public-chats-slider .slick-slide {
    transition: transform 0.3s ease;
}

.public-chats-slider .slick-current {
    transform: scale(1.02);
}