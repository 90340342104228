.app-modal-content-response-settings {
    position: absolute; /* or fixed, depending on your needs */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #131313;
    padding: 25px;
    border-radius: 10px;
    width: 65%;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 480px;
    max-width: 520px;
    z-index: 9999;
    user-select: none;
}

.owner-settings-wrapper {
    width: 60px;
    position: relative;
    display: flex;
    align-items: center;
    height: 45px;
    padding: 4px 10px;
    justify-content: center;
    border: 1px #c4c4c4 solid;
    border-radius: 8px;
    cursor: pointer;
}

.owner-settings-wrapper:hover {
    background-color: #1E1E1E;
}

.settings-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.settings-button svg {
    max-width: 24px;
    max-height: 24px;
    min-width: 24px;
    min-height: 24px;
}

.response-setting-menu {
    position: absolute;
    bottom: calc(100% + 8px);
    left: 0;
    width: 200px;
    background-color: #181818;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #c4c4c4;
    z-index: 1000;
}

.response-settings-menu-items {
    padding: 4px 0;
}

.response-setting-menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 100%;
    padding: 8px 16px;
    text-align: left;
    font-size: 0.90em;
    font-weight: 500;
    background: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
    color: #c4c4c4;
}

.response-setting-menu-item svg {
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
}

.response-setting-menu-item:hover {
    background-color: rgba(107, 114, 128, 0.5);
}

.response-setting-menu-item.delete {
    color: rgb(248, 113, 113);
}

.smaller-general-text {
    font-size: 1em;
    padding-top: 10px;
}

.line-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
}

.text-line-container {
    margin-right: 10px;
    color: #BABABA;
    font-weight: 600;
    font-size: 0.8em;
}

.footer-button-container {
    justify-content: space-between;
    display: flex;
    width: 100%;

}

.need-help-button {
    padding: 12px 24px;
    width: 35%;
    border-radius: 12px;
    border: none;
    font-weight: 600;
    transition: background-color 0.3s, opacity 0.3s;
    background-color: #6377BE;
    cursor: pointer;
    font-size: 1em;
    color: white;
    white-space: nowrap;
}

.need-help-button:hover {
    opacity: 0.90;
    background-color: #4F63A3;
}

.save-settings-button {
    padding: 12px 24px;
    width: 85%;
    height: 45px;
    border-radius: 12px;
    border: none;
    font-weight: 600;
    transition: background-color 0.3s, opacity 0.3s;
    background-color: #9072D9;
    cursor: pointer;
    font-size: 1em;
    color: white;
    white-space: nowrap;
}

.save-settings-button:hover {
    background-color: #7A5EC7;
    opacity: 0.90;
}

.save-settings-button:disabled {
    background-color: rgba(122, 94, 199, 0.5);
    cursor: not-allowed;
}

.main-elyza-text-response-settings {
    color: #C4C4C4;
    font-size: 0.9em;
    line-height: 15px;
}

.owner-custom-settings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    text-align: start;
    white-space: nowrap;
    margin-right: auto;
}

.big-red-button {
    color: #e65e5e;
    font-size: 0.85em;
    font-weight: 700;
    cursor: pointer;
    transition: text-decoration 0.3s ease-in-out;
}

.big-blue-button {
    color: #8991ec;
    font-size: 0.85em;
    font-weight: 700;
    cursor: pointer;
    transition: text-decoration 0.3s ease-in-out;
}

.big-blue-button:hover,
.big-red-button:hover {
    text-decoration: underline;
}

.big-blue-button:disabled,
.big-red-button:disabled {
    color: #aaa;
    cursor: not-allowed;
}

.owner-settings-header {
    width: 100%;
    margin-left: 15px;
    color: #BABABA;
    font-weight: 600;
    font-size: 1.0em;
}

.drop-down-menu {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: 10px;
    color: white;
    max-width: 100%;
    gap: 10px;
}

.textbox-name-general-response {
    resize: none;
    margin-top: 20px;
    color: white;
    display: flex;
    flex-direction: row;
    gap: 2px;
    width: 100%;

}

.textbox-name-general-response textarea {
    resize: none;
    width: 85%;
    margin-top: 5px;
    padding: 12px 10px 12px 15px;
    color: white;
    border-radius: 16px;
    font-size: .85em;
    border: 1px solid #696969;
    background: #171717;
}

.two-switches-menu {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    margin-left: auto;
    font-size: 1em;
    font-weight: 500;
    position: relative;
    font-style: normal;
    color: #C4C4C4;
    height: fit-content;
}

.sexy-dropdown {
    background: white;
    color: black;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    width: 55%;
    height: 45px;
    display: flex;
    position: relative;
    border-radius: 16px;
    margin-top: 15px;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.slider-standard {
    padding-top: 15px;
}

.slider-label {
    font-size: 1em; /* Adjust this to change the size */
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.slider-label svg {
    margin-left: 3px;
    max-width: 20px;
    max-height: 20px;
    min-height: 20px;
    min-width: 20px;
}

@media screen and (max-width: 680px) {
    .need-help-button,
    .save-settings-button {
        /*width: 30%;*/
        /*font-size: 0.85em;*/
        /*font-weight: 600;*/
        /*padding: 8px 12px;*/
    }

    .line-container,
    .a-straight-line-3 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .app-modal-content-response-settings {
        width: 100%;
        max-height: 720px;
        min-width: 360px;
        max-width: 440px;
        /*height: 100%;*/
        padding: 15px;
    }

    .main-elyza-text-response-settings {
        font-size: 0.75em;
        line-height: unset;
    }

    .sexy-dropdown {
        font-size: 1em;
        height: 35px;
        width: 45%;
    }

    .slider-standard {
        padding-top: 5px;

    }

    .slider-label {
        font-size: 1em;
    }

    .drop-down-menu {
        margin-top: 10px;
        margin-bottom: 5px;
    }

}