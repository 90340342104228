.menu-container {
    background-color: #151515;
    margin-top: 5px;
    width: 100%;
}

.button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    border-radius: 8px;
    padding: 5px 0;
}
.mode-button {
    flex: 1;
    padding: 16px 8px;
    border-radius: 6px;
    background-color: #262626;
    color: #D7D7D7;
    cursor: pointer;
    transition: all 0.3s;
    font-weight: 700;
    font-size: 1em;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mode-button:hover {
    background-color: #1f1f1f;
}

.mode-button.active {
    background-color: #a489ec;
    color: #e8e5e5;
}

.mode-button.active:hover {
    background-color: #8b6fd1;
}

.mode-button.locked {
    opacity: 0.5;
    cursor: not-allowed;
}

.mode-button:disabled {
    pointer-events: none;
}

@media screen and (max-width: 680px) {
    .menu-container {
        padding: 5px;
    }

    .button-group {
        gap: 5px;
    }

    .mode-button {
        padding: 10px 5px;
        font-size: 0.85em;
    }
}