.pricing-switch {
    display: flex;
    justify-content: center;
    gap: 32px;
    margin-bottom: 15px;
}

.updated-notification-label {
    font-size: 1.5em;
    font-weight: 700;
    width: 100%;
    white-space: normal;
    text-align: center;
    padding-bottom: 20px;
    align-items: center;
    background: linear-gradient(99.22deg, #FFFFFF -33.39%, #C0A7FF 46.95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.notification-buttons-container{
    display: flex;
    flex-direction: column;
}

.updated-modal-content-notification {
    display: flex;
    flex-direction: row;
    user-select: none;
    color: white;
    border-style: solid;
    border-radius: 16px;
    border-width: 1px;
    border-color: #242424;
    width: 90%;
    max-width: 800px;
    max-height: 500px;
    overflow: hidden;
    background: #131313 url("./icons/background.svg");
}

.switch-btn {
    font-size: 1.25em;
    color: #4c4c4c;
    background: none;
    border: none;
    cursor: pointer;
}

.switch-btn.active {
    color: white;
}

.features-grid {
    display: grid;
    position: relative;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    padding-top: 32px;
    padding-bottom: 32px;
}

.image-carousel-container-exit-intent {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}


.feature-item-notification {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
    white-space: pre-line;
}

.feature-item-notification svg {
    width: 32px;
    height: 32px;
}

.feature-item-notification span {
    font-size: 1em;
    color: #fff;
    font-weight: 700;
    white-space: pre-line;
    display: block;
    text-align: center;
    line-height: 1.2;
}


.updated-notification-left {
    flex: 1;
    border-radius: 16px;
    max-width: 55%;
    padding: 20px 16px;
}

.updated-modal-close-icon-notification{
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
}

.updated-notification-right {
    display: flex;
    position: relative;
    /*flex: 1;*/
    max-width: 50%;
}

.updated-notification-right img {
    width: 100%;
    height: 100%;
    max-width: 360px;
    object-fit: cover;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

.offer-timer {
    text-align: center;
    margin-top: 15px;
    padding: 12px;
    background: linear-gradient(102.84deg, #9072D9 41.88%, #8573B0 66.26%);
    border-radius: 12px;
}

.timer-label {
    color: white;
    font-size: 1.20em;
    font-weight: 600;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.countdown {
    font-size: 2em;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 10px rgba(166, 135, 255, 0.5);
    font-family: monospace;
}

.notification-button-main-updated {
    font-size: 1.25em;
    width: 100%;
    height: 50px;
    color: white;
    font-weight: 550;
    background: #916DE8;
    border-radius: 16px;
    margin-top: 30px;
    margin-bottom: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: opacity 0.3s ease, background 0.3s ease;
}

.notification-button-main-updated svg {
    width: 32px;
    height: 32px;
    min-height: 32px;
    min-width: 32px;
    margin-right: 10px;
    max-height: 32px;
    max-width: 32px;
}

.notification-button-main-updated:hover {
    opacity: 0.85;
    background: #7d5bd1;
}

.notification-button-secondary-updated{
    color: #c4c4c4;
    width: 100%;
    cursor: pointer;
    font-size: 0.9em;
    margin-top: 15px;
    margin-bottom: 5px;
}

@media screen and (min-width: 680px) and (max-width: 1024px) {

}

@media screen and (max-width: 680px) {
    .features-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .pricing-switch {
        display: none;
    }

    .notification-button-main-updated {
        width: 100%;
    }

    .notification-button-secondary-updated{
        width: unset;
    }

    .updated-modal-content-notification{
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .updated-notification-left {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .notification-buttons-container{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .updated-notification-right {
        display: none;
    }

    .updated-notification-label{
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;
    }

    .offer-timer {
        margin-right: 20px;
        margin-left: 20px;
    }

    .notification-button-secondary-updated{
        margin-top: 10px;
        color: #c4c4c4;
        cursor: pointer;
        border: 2px solid #c4c4c4;
        height: 55px;
        border-radius: 16px;
        font-size: 0.9em;
    }

    .features-grid {
        padding-left: 20px;
        padding-right: 20px;
    }

    .notification-buttons-container{
        padding-left: 20px;
        padding-right: 20px;
    }
}