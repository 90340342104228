.recent-chat-loader {
    z-index: 9;
    display: flex;
    width: 100%;
    align-items: center;
    max-width: 360px;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: fixed;
    gap: 40px;
    background-color: #131313;
    overflow: hidden;
    border-top: 2px solid #242424;
    border-right: 2px solid #242424;
}

.recent-chat-main-container{
width: 100%;

}
.loader-wrapper{
width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.top-loaders-container{
    align-items: center;
    margin-top: 27px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;

}


.smaller-loader-rectangular{
    width: 25%;
    border-radius: 4px;
    height: 30px;

    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    position: relative;
    animation: glistening 1.5s infinite;

}


.recent-loader-standard-1 {
    width: 100%;
    border-radius: 4px;
    margin-top: 5px;
    height: 35px;
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    position: relative;
    animation: glistening 1.5s infinite;
}


.recent-loader-standard-2 {
    width: 100%;
    border-radius: 16px;
    margin-top: 17px;
    height: 40px;
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    position: relative;
    animation: glistening 1.5s infinite;
}


.rectangle-array{}

.rectangle-item{
    display: flex;
    width: 100%;
    border-radius: 16px;
    margin-top: 20px;
    height: 60px;
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    position: relative;
    animation: glistening 1.5s infinite;
    align-items: center;
}


.circle-loader{
height: 48px;
    width: 48px;
    left: 15px;
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    position: absolute;
    animation: glistening 1.5s infinite;
    border-radius: 50%;
}

.right-container-loader{
    width: 100%;
    margin-left: 70px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.top-rectangle-loader{
    height: 15px;
    width: 50%;
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    position: relative;
    animation: glistening 1.5s infinite;
    border-radius: 16px;


}


.bottom-rectangle-loader{
    width: 100%;
    height: 10px;
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    position: relative;
    animation: glistening 1.5s infinite;
    border-radius: 16px;

}


@media screen and (max-width: 680px) {


    .recent-chat-loader {
        z-index: 9;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        align-items: center;
        max-width: 100%;
        margin-top: 0;
        background-color: #131313;
    }

}

