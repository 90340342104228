.MainNewPage {
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    background: #131313;

    /* Default scrollbar settings */
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;

    /* For Internet Explorer */
    -ms-overflow-style: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.MainNewPage::-webkit-scrollbar {
    width: 4px;
}

.MainNewPage::-webkit-scrollbar-track {
    background: transparent;
}

.MainNewPage::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
}

.MainNewPage::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Show scrollbar for IE and Edge */
@supports (-ms-overflow-style: none) {
    .MainNewPage {
        -ms-overflow-style: auto;
    }
}

/* Firefox-specific styles */
@-moz-document url-prefix() {
    .MainNewPage {
        scrollbar-width: thin;
        scrollbar-color: #888 transparent;
    }
}

.CharacterListH {
    width: 100vw;
}

.filter-toggle-icon {
    position: absolute;
    z-index: 10;
    left: 260px;
    top: 110px;
    height: 30px;
    width: 30px;
    fill: currentColor; /* Use the color property as the fill color */
    transition: transform 0.3s ease-in-out;

}

.icon-active-background {
    background-color: white;
    border-radius: 5px;
    padding: 10px 0px;
}

@media (max-width: 768px) {
    .filter-toggle-icon {
        /* Adjust the top value to move the icon down */
        top: 155px; /* You can change the value to fit your design */
        z-index: 10;
    }
}

