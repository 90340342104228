/* DeleteAccountModal.css */
.delete-account-dropdown {
    width: 100%;
    background: #151515;
    border-radius: 8px;
}

.delete-account-dropdown-control {
    border: 1px solid #C4C4C4;
    border-radius: 8px;
    padding: 12px 16px;
    font-size: 1em;
    background: #151515;
    color: #c4c4c4;
    cursor: pointer;
    transition: all 0.2s ease;
}

.delete-account-dropdown-control:hover {
    border-color: #cbd5e0;
}

.delete-account-dropdown-menu {
    border-radius: 0 0 8px 8px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background: #151515;
    border: 1px solid #C4C4C4;
}

.delete-account-dropdown-menu .Dropdown-option {
    padding: 12px 16px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.delete-account-dropdown-menu .Dropdown-option:hover {
    background-color: #2d3748;
    color: #c4c4c4;
}

.delete-account-dropdown-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    border-color: #ffffff transparent transparent;
}

.delete-account-dropdown.is-open .delete-account-dropdown-control {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #C4C4C4;
}