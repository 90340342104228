@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&amp;display=swap');

textarea {
    font-family: Inter, sans-serif;
    scrollbar-width: none;
}

@-moz-document url-prefix() {
    body {
        -moz-osx-font-smoothing: grayscale;
    }
}

body {
    font-family: 'Inter', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #131313;
    font-size: 16px;
    margin: 0;
}

button {
    background-color: transparent;
    border-width: 0;

}

html {
    font-size: 16px;
    font-family: Inter, sans-serif;
}

input {
    font-family: Inter, sans-serif;
}

p {
    margin-top: 5px;
}


label {
    padding: 0 !important;
}

li {
    color: white;
}

.swiper-wrapper {
    width: auto !important;
    position: unset !important;

}

.top-timer-container-main {
    position: fixed;
    margin-top: 85px;
    margin-bottom: -25px;
    overflow: hidden;
    z-index: 998;
    width: 100%;
}

.top-timer-container-main-chat {
    position: relative;
    margin-top: 85px;
    width: 100vw;
    z-index: 99;
}

.top-timer-container-main-pricing {
    position: fixed;
    margin-top: 85px;
    width: 100vw;
    z-index: 99;

}

/*FREE TO USE*/
h1 {
    font-size: 2.5em;
    font-weight: bold;
}

/*//GOOD HEADER*/
h2 {
    margin: 0;
    color: white;
    user-select: none;
    font-size: 1.7em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
}

/*//SMALLER SUB-HEADER*/
h3 {
    font-size: 1.15em;
    font-style: normal;
    font-weight: 800;
    letter-spacing: -0.01em;
    color: #C4C4C4;
    margin: 0;
}

h4 {
    font-weight: 500;
    font-size: 1em;
    color: #C4C4C4;
    margin: 0;
    font-style: normal;
    letter-spacing: -0.01em;
}

h5 {
    color: #C4C4C4;
    font-size: 1em;
    align-items: center;
    justify-content: center;
    margin: 0;
}


.main-upper-text-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    align-content: center;
    justify-content: space-between;

}

.main-elyza-text-label {
    font-size: 1.75em;
    font-weight: 700;
    color: white;
}

.main-elyza-text-sublabel {
    color: #C4C4C4;
    font-size: 0.95em;
    margin-top: 5px;
    line-height: 20px;
}

.main-elyza-text-sublabel-2 {
    color: #C4C4C4;
    font-size: 0.75em;
    margin-top: 2px;
    max-width: 350px;
}

.parent {
    width: 100%;
    display: flex;
}

.swiper {
    overflow: visible !important;
    align-items: center;
    justify-content: center;
    position: unset;
}

.swiper-slide {
    max-width: 75vw;
    margin: 10px;
}

.column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.close-modal-icon {
    color: white;
    display: flex;
    fill: white;
    cursor: pointer;
    position: relative;
    width: 16px;
}

.textbox-name, .textbox-email, .textbox-password, .textbox-repeat-password {
    margin-bottom: 10px;
    margin-top: 10px;
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.textbox-password-gap {
    gap: 15px;

}

.textbox-name input, .textbox-email input, .textbox-prompt-gallery-general textarea, .textbox-password input, .textbox-repeat-password input, .textbox-name-general-import input, .textbox-name-general input, .textbox-name-general-short textarea {
    width: 100%;
    padding: 12px 10px 12px 15px;
    color: white;
    border-radius: 16px;
    font-size: 1em;
    border: 1px solid #696969;
    background: #171717;
    resize: vertical;
}

.textbox-name-general-short textarea:disabled {
    cursor: not-allowed;
    opacity: 0.65;
    resize: none;
}

.textbox-prompt-gallery-general textarea,
.textbox-name-general input{
    margin-top: 5px;
}

.textbox-name input::placeholder, .textbox-email input::placeholder, .textbox-password input::placeholder, .textbox-repeat-password input::placeholder {
    color: #757575;
    font-weight: 500;
}

.textbox-password input::placeholder, .textbox-repeat-password input::placeholder {
    font-size: 0.75em;
}

.input[type="text"], input[type="search"] {
    padding: 10px 15px;
    background: #171717;
    border: 1px solid #696969;
    color: white; /* Ensure text color is white */
    -webkit-text-fill-color: #696969; /* Change this to white for WebKit browsers */
    border-radius: 16px;
    font-size: 1em;
    width: 100%;
}

.textbox-name-general-short textarea {
    min-height: 120px;
    max-height: 340px;
    margin-top: 5px;
}

/*TODO, figure this shit out*/
.search-bar-create-new-chat input[type="search"] {
    flex-grow: 1;
    outline: none;
    font-size: 13px;
    /*background-image: url('./src/components/ChatPageComponents/ChatIcons/search.svg');*/
    background-size: 16px 16px;
    background-position: 10px center;
    background-repeat: no-repeat;
    padding: 10px 30px 10px;

    align-items: center;
    border-radius: 16px;
    border: 1px solid #696969;
    color: white; /* Ensure text color is white */
    -webkit-text-fill-color: #696969; /* Change this to white for WebKit browsers */
}

code {
    font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    width: 65vw;
}

@media screen and (min-width: 1480px) {
    .textbox-name-general-short textarea {
        min-height: 145px;
        max-height: 340px;
        font-size: 1.1em;
    }
}

@media screen and (min-width: 0px) and (max-width: 680px) {
    h2 {
        font-size: 1.5em;
        letter-spacing: 0;
        font-weight: 600;
    }

    .swiper-wrapper {
        width: auto;
    }

    .swiper-button-prev, .swiper-button-next {
        margin-top: 50px !important;


    }
}


@media screen and (min-width: 0px) and (max-width: 680px) {
    .textbox-name input, .textbox-email input, .textbox-prompt-gallery-general textarea, .textbox-password input, .textbox-repeat-password input, .textbox-name-general-import input, .textbox-name-general input, .textbox-name-general-short textarea {
        font-size: 0.90em;
    }

    .slick-prev {
        margin-left: 10px;
    }

    .slick-next {
        margin-right: 10px;
    }

    .main-elyza-text-sublabel {
        font-size: 0.75em;
        line-height: unset;
    }

    .main-elyza-text-label {
        font-size: 1.25em;

    }

    .textbox-name input, .textbox-email input, .textbox-password input, .textbox-repeat-password input, .textbox-name-general-import input, .textbox-name-general input, .textbox-name-general-short textarea {

        padding: 10px 8px 10px 8px;

    }

    .textbox-name, .textbox-email, .textbox-password, .textbox-repeat-password {
        margin-bottom: 5px;
        margin-top: 5px;

    }

    .top-timer-container-main-chat {
        z-index: 1010;
        position: absolute;
    }

}