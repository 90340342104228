.shake-animation {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

.reset-password-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(10px);


}
.modal-header-2{
    display: flex;
    flex-direction: column;
}

.modal-content {
    background: #131313;
    color: white;
    display: flex;
    user-select: none;
    flex-direction: column;
    border-radius: 8px;
    gap: 5px;
    box-shadow: 0 2px 4px rgba(108, 108, 108, 0.85);
    min-width: 400px;
    max-width: 450px;
    position: relative;
    padding: 20px;
    width: 40vw;
    z-index: 9999;
}

.modal-header, .modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.main-elyza-text-label-reset{
    font-weight: 700;
    font-size: 1.5em;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.main-elyza-text-label-reset-2{
    font-weight: 700;
    font-size: 24px;
    margin-top: 10px;
    }

.reset-password-main-button{
    position: relative;
    background: #916DE8;
    border-radius: 12px;
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    margin-bottom: 5px;
    color: white;
    font-weight: 600;
    font-size: 1em;
    cursor: pointer;
}
.reset-password-button-loading {
    background-color: gray; /* Use !important to override other styles if necessary */
    color: white; /* Change the text color if needed */
    cursor: not-allowed; /* Optional: Change cursor to indicate the button is disabled */
}

.modal-header{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.modal-body {
    padding: 20px 0;
}

.modal-footer {

}

.close-modal-icon-reset svg{
    width: 16px;
    cursor: pointer;
}


.modal-backdrop {
    position: fixed;
    top: 0;
    z-index: 1050;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.change-step-button-reset {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
    padding: 12px 20px;
    transition: background-color 0.3s ease;
    position: relative;
    background: #b5a2e0 linear-gradient(102.84deg, #9072D9 41.88%, #8573B0 66.26%);
    border-radius: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #FFFFFF;
    cursor: pointer;
    align-items: center;
}

.change-step-button-reset:hover {
    opacity: 0.95;
}


@media screen and (max-width: 680px) {

    .modal-content {

        min-width: 350px;
        max-width: 420px;

    }

}