.menu-item-container {
    position: relative;
}

.language-dropdown-container {
    position: absolute;
    min-width: 140px;
    max-width: 140px;
    transform: translateX(50%);
}

.language-dropdown-menu-main {
    background-color: #131313;
    border: 1px solid #C4C4C4;
    border-radius: 8px;
    margin-top: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    font-size: 0.95em;
    font-weight: 550;
    color: #ffffff;
    overflow: hidden;
}

.language-option {
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    color: #fff;
    white-space: nowrap;
}

.language-option:first-child {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.language-option:last-child {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.language-option:hover {
    background-color: #0d0d0d;
}

.language-option.selected {
    background-color: #6335D2;
}