.content-setting-menu {
    background-color: #131313;
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: white;
    border-radius: 14px;
    max-width: 600px;
    user-select: none;
}

.textbox-generate-button-general{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.edit-main-selection-container {
    overflow-y: auto;
    height: 100%;
}

.accordion-component-main {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 5px;
}

.public-to-private-switch {
    display: flex;
    margin-left: -14px;
    margin-top: 5px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 10px;
    position: relative;
    font-style: normal;
    color: #C4C4C4;
}


.loading-spin-edit-char {
    display: flex;
    margin-top: 45px;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}


.content-setting-menu img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

.update-profile-image {
    width: 160px;
    height: 160px;
    display: flex;
    margin: 10px 0 10px;
    justify-content: center;
    align-items: center;
    border: 2px dashed #696969;
    cursor: pointer;
    text-align: center;
    border-radius: 8px;
}

.private-public-general {

}

@media screen and (min-width: 0px) and (max-width: 680px) {
    .modal-backdrop {
        overflow-y: scroll;
        /*background-color: #181818;*/
    }

    .update-profile-image {
        width: 120px;
        height: 120px;

    }

    .accordion-component-main {
        display: flex;
        flex-direction: column;
    }

    .content-setting-menu {
        height: 100%;
    }
}