.modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}



.profile-head-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile-name,
.profile-name-input {
    font-size: 1em;
    color: #C4C4C4;
    background: transparent;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
    margin-right: 5px;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.profile-name-input {
    border: none;
    outline: none;
    resize: none;
    padding: 0;
    margin: 0;
    height: auto;
    overflow: hidden;
    font-family: inherit;
}

.profile-name {
    cursor: pointer;
    margin: 0;
    padding: 0;
}

.user-profile-grid {
    display: grid;
    position: relative;
    grid-template-columns: repeat(3, 1fr);
    max-width: 100%;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    box-sizing: border-box;
}

.profile-box {
    border-radius: 8px;
    padding: 5px;
    color: #C4C4C4;
    display: flex;
    border: 1px solid #ccc;
    flex-direction: column;
    overflow: hidden;
    max-width: 100%;
    box-sizing: border-box;
    height: 85px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s, box-shadow 0.2s;
}
.profile-box:hover{
    background-color: #212121;
}

.profile-box:hover,
.profile-box.selected {
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.profile-box.selected {
    border-color: #C0A7FF;
    color: #C0A7FF;
}

.profile-box h2 {
    color: #C4C4C4;
    font-size: 1em;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.profile-box-bottom-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 3;
    font-size: 0.75em;
    font-weight: 500;
    /*line-height: 10px;*/
    color: #AEAEAE;
    height: 100%;
    flex-grow: 1;
}

/*//TODO custom dash mask*/
.new-profile-box {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: transparent;
    border-radius: 12px;
    border: 2px dashed #C4C4C4;
}

.new-profile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-size: 0.85em;
    text-align: center;
}

.new-profile-content p {
margin: 0;
}

.plus-icon svg {
    display: block;
    width: 24px;
    height: 24px;
    margin-bottom: 5px;
}

.profile-head-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
}

.open-menu-button {
    display: none;
}

.profile-box:hover .open-menu-button {
    display: block;
    position: relative;
    right: 0;
    width: 20px;
}

@media (max-width: 680px) {
    .profile-box-bottom-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
        font-size: 0.65em;
        font-weight: 500;
        /*line-height: 10px;*/
        color: #AEAEAE;
        height: 100%;
        flex-grow: 1;
    }

    .profile-box {
        height: 75px;
    }

    .profile-box h2 {
        color: #C4C4C4;
        font-size: 0.95em;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .new-profile-content p {
        font-size: 0.85em;
    }
}
