/* SendMessageComponent.css */

.send-message-container {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;

}


.ControlContainerFunction {
    display: flex;
    flex-direction: row;;
    position: absolute;
    bottom: 80px;
}

.call-function-button {
    background: #131313;
    border: 2px solid #242424;
    border-radius: 12px;
    padding: 6px 8px;
    cursor: pointer;
    display: flex;
    color: #A7A7A7;
    bottom: 5px;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 20px;
    transition: background 0.3s ease;
    gap: 2px;
}

.call-function-button:hover {
    background: #1f1f1f;
}

.FunctionButtonIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    max-height: 20px;
    max-width: 20px;
    min-width: 20px;
    min-height: 20px;
}

.FunctionButtonIcon svg {
    width: 20px;
    height: 20px;
    max-height: 20px;
    max-width: 20px;
    min-width: 20px;
    min-height: 20px;
}

.close-icon-button {
    position: relative;
    right: 20px;
    cursor: pointer;
    align-self: self-start;
    background-color: transparent;
    border: none;
    display: none;

}

.attached-image-preview:hover .close-icon-button {
    display: block;
}

.close-icon-button svg {
    background: #3b3b3b;
    border-radius: 50%;
    padding: 5px;
    border: 1px solid #8A8A8A;
    height: 24px;
    width: 24px;
}

.send-message-form {

    position: relative;
    display: flex;
    align-items: center;
    border-radius: 22px;
    background-color: #131313;
    border: 2px solid #242424;
    padding: 8px;

}

.form-image-attached {
    /*border-top: 1px solid #484848;*/
    box-shadow: none;
    border-top: 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0 0 24px 24px;
}


.attached-image-form-extension {
    background-color: #131313;
    border-radius: 24px;
    margin-bottom: 80px;

    border: 2px solid #242424;

}

.textarea-wrapper {
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    overflow: hidden;
}

.send-message-container.is-message-running .send-message-form {
    background-color: #1f1f1f; /* Darker background for running state */
    pointer-events: none; /* Optional: Prevent interaction with the form elements */
    cursor: not-allowed;
}

.attached-image-preview {
    display: flex;
    width: fit-content;
    margin: 5px 5px 0;
    cursor: pointer;
}

.attached-file-image {
    width: 50px;
    height: fit-content;
    max-height: 50px;
    min-height: 50px;
    margin-top: 10px;
    margin-left: 15px;
    position: relative;
    border-radius: 8px;
    align-self: flex-end;
    background-color: #8A8A8A;
}

/*.star-button-message{*/
/*background: #131313;*/
/*    border: 2px solid #242424;*/
/*    border-radius: 4px;*/
/*    width: 32px;*/
/*    height: 32px;*/
/*    cursor: pointer;*/
/*    display: flex;*/
/*    color: #A7A7A7;*/
/*    font-size: 1em;*/
/*    text-align: center;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    position: relative;*/
/*    transition: background 0.3s ease;*/
/*}*/

.message-input {
    display: flex;
    align-items: center; /* This centers the text vertically */
    border: none;
    outline: none;
    resize: none;
    font-size: .95em;
    overflow-y: auto; /* Changed from scroll to auto for better behavior */
    width: 100%;
    height: 100%;
    padding: 8px 6px; /* Increased horizontal padding for better text positioning */
    min-height: 36px;
    background-color: initial;
    user-select: text; /* Changed from none to text to allow text selection */
    border-radius: 18px;
    box-sizing: border-box;
    color: #fff;
    font-weight: 500;
    line-height: 1.2; /* Added to improve text readability and vertical centering */
}

.message-input[contenteditable="true"] {
    display: flex;
    align-items: center;
}

.message-input::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
}

.message-input::-webkit-scrollbar-track {
    background: transparent;
}

.icon-button {
    height: 24px;
    min-height: 24px;
    max-height: 24px;
    min-width: 24px;
    width: 24px;
    max-width: 24px;
    background: none;
    border: none;
    cursor: pointer;
    color: #FFFFFF;
    margin: 0 10px;
    flex-shrink: 0;
}

@media (max-width: 1080px) {
    .call-function-button {
        border-radius: 12px;
        padding: 4px 6px;
        margin-right: 10px;
        font-size: 0.8em;
    }

    .call-function-button svg {
        width: 18px;
        height: 18px;
        min-width: 18px;
        max-width: 18px;
        min-height: 18px;
        max-height: 18px;
    }
}