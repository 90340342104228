/* FrequentQuestions Container */
.faq-container {
    width: 100%;
    display: flex;
    padding: 40px 60px 0;
    flex-direction: column;
    margin: 0 auto;
    align-items: flex-start;
    justify-content: center;
    z-index: 9;
    position: relative;
}

/* FrequentQuestions Item */
.faq-item {
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    width: 65%;
    transition: background-color 0.3s ease;
    position: relative;
    padding: 15px 15px 15px 0;
}


/* Question */
.faq-question {
    font-weight: bold;
    color: white;
    padding: 10px 10px 10px 0;
}

.faq-desktop {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 50px;
}

/* Answer (Initially Hidden) */
.faq-answer {
    padding: 10px 10px 10px 0;
    max-height: 0;
    overflow: hidden;
    display: block;
    color: white;
    z-index: 9;
    transition: max-height 0.5s ease;
}

/* Active Answer */
.faq-item.active .faq-answer {
    max-height: 10000px;
    z-index: 9;
    transition: max-height 0.5s ease;
}

/* Icon for Toggle */
.faq-toggle-icon {
    position: absolute;
    top: 15px;
    right: 10px;
    transform: rotate(0deg);
    fill: white;
    transition: transform 0.3s ease;
}

/* Icon Rotation for Active Item */
.faq-item.active .faq-toggle-icon {
    transform: rotate(180deg);
}


@media (max-width: 680px) {
    .faq-container {
        padding: 40px 25px 0;
        padding-bottom: 100px;
    }

    .faq-title {
        font-size: 1.5em;
        line-height: 34px;
    }

    .faq-question {
        font-size: 0.8750em;
        padding: 6px;
    }

    .faq-answer {
        padding: 6px;
    }

    .faq-item {
        padding: 8px;
        width: 75%;
    }
}
