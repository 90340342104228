
.user-attached-image {
    position: relative;
    display: flex;

}
.user-attached-image-actual-image{
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 14px;
    box-shadow: 0 2px 5px 0 #E8E8E840;
    max-height: 300px;
}

.user-text {
    position: relative;
}

.user-chat-container-image {
    display: flex;
    position: relative;
}
