@media (max-width: 680px) {
    .mobile-menu-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.15);
        z-index: 9999;
        backdrop-filter: blur(4px);
        margin-top: 87px;

    }

    .menu-icon-mobile{
        display: flex;
        align-items: center;
        justify-content: center;
        stroke: #c4c4c4;
    }

    .menu-icon-mobile svg {
        max-width: 24px;
        max-height: 24px;
        min-width: 24px;
        min-height: 24px;
        transition: stroke 0.3s ease;
    }

    .mobile-menu-container {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 60%;
        background-color: #131313;
        transform: translateX(-100%);
        animation: slideIn 0.3s forwards;
        z-index: 9;
        display: flex;
        flex-direction: column;
        box-shadow: 2px 0 8px rgba(0, 0, 0, 0.3);
    }

    .mobile-menu-container.open {
        transform: translateX(0);
    }

    @keyframes slideIn {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0);
        }
    }

    .mobile-menu-header {
        padding: 1rem;
        border-bottom: 1px solid #242424;
        display: flex;
        justify-content: flex-end;
    }

    .mobile-menu-items {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        overflow-y: scroll;
    }

    @keyframes strokeGlow {
        0% {
            filter: brightness(1);
            opacity: 0.8;
        }
        50% {
            filter: brightness(1.5);
            opacity: 1;
        }
        100% {
            filter: brightness(1);
            opacity: 0.8;
        }
    }

    .menu-icon-mobile-premium svg {
        stroke: #9072D9;
        min-width: 24px;
        max-width: 24px;
        max-height: 24px;
        min-height: 24px;
        animation: strokeGlow 5s ease-in-out infinite;
    }

    .menu-label-premium {
        color: #9072D9;
        animation: strokeGlow 5s ease-in-out infinite;
    }

    .mobile-menu-item {
        display: flex;
        align-items: center;
        padding: 1rem 1.5rem;
        font-weight: 700;
        color: #c4c4c4;
        text-decoration: none;
        transition: background-color 0.2s;
        gap: 1rem;
        cursor: pointer;
    }

    .mobile-menu-item:hover {
        background-color: #242424;
    }

    .mobile-menu-item.active {
        border-left: 2px solid #9072D9;
    }

    .menu-label {
        font-size: 1rem;
        font-weight: 500;
    }

    /*.join-discord-button{*/
    /*    display: flex;*/
    /*    position: relative;*/
    /*    width: 24px;*/
    /*    height: 24px;*/
    /*    margin-left: 20px;*/
    /*    margin-right: 20px;*/
    /*    justify-content: center;*/
    /*    color: #c4c4c4;*/
    /*    font-weight: 700;*/
    /*    cursor: pointer;*/
    /*    transition: background-color 0.2s;*/
    /*    top: 50px;*/
    /*}*/
}