.image-generation-page {
    width: 100%;
    height: fit-content;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
    align-items: center;
    padding: 165px 40px 50px;
    gap: 30px;
    user-select: none;
}

.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050; /* Higher than other content */
}

.image-modal-content {
    position: relative;
    max-height: 80%;
    z-index: 1051;
}


.modal-image {
    max-height: 75vh;
    max-width: 100%;
    height: 75vh;
    width: 100%;
    display: block;
    margin: 0 auto;
    border-radius: 16px;
}

.modal-close-button {
    position: absolute;
    top: -50px;
    right: -50px;
    cursor: pointer;
    z-index: 9999;
}

.mobile-back-char {
    width: 100%;
}

.main-prompt-container {
    width: 100%;
}

.back-character {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    border-radius: 12px;
    background-color: #131313;
    color: white;
    font-size: 1em;
    border: 1px solid #393939;
    cursor: pointer;
    gap: 5px;
    font-weight: 700;
    height: 45px;
}

.back-character svg {
    width: 32px;
    height: 32px;
}

.gallery-container-collection {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Creates four columns */
    gap: 20px; /* Space between the items */
}

.back-character:hover {
    background-color: #0e0e0e;
}

.create-image-button-width {
    width: 100%;
}

.prompt-generate-box-general {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 420px;
    max-height: 600px;
    background-color: rgba(21, 21, 21, 0.96);
    border-radius: 14px;
}

.loading-image-container-gallery {
    flex-direction: column;
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: center;
    background-color: #131313;
    border-radius: 16px;
    border: 2px solid #242424;
}

.prompt-generate-box {
    position: relative;
    background-size: cover;
    background-position: center;;
    top: 0;
    left: 0;
    min-height: 580px;
    min-width: 400px;
    max-height: 580px;
    max-width: 400px;
    width: 100%;
    height: 100%;
    background-color: rgba(21, 21, 21, 0.96);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #AAAAAA;
    font-weight: 600;
    align-self: center;
    font-size: 1.25em;
    gap: 5px;
    border: 1px solid #393939;
}

.gallery-content-create {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 600;
    font-size: .85em;
    gap: 10px;
}

.prompt-generate-box svg {
    width: 48px;
    height: 48px;;
}

.image-generation-container-left {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 60px;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.prompt-generate-loader-icon {
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 80px;
    min-width: 80px;
    max-height: 80px;
    max-width: 80px;
}

.prompt-generate-loader-icon svg {
    min-height: 80px;
    min-width: 80px;
    max-height: 80px;
    max-width: 80px;
    margin-bottom: -10px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.normalizer-of-size-gallery {
    display: flex;
    width: fit-content;
    gap: 25px;
    flex-direction: column;
}

.image-generation-container-right {
    width: 100%;
    max-width: 520px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}

.image-gen-right-background {
    background-color: #151515;
    width: 100%;
    max-width: 680px;
    padding: 20px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border: 1px solid #393939;
    border-radius: 16px;
}

.click-to-generate-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    cursor: pointer;
    color: white;
    border-radius: 6px;
    width: 100%;
    background: linear-gradient(102.84deg, #9072D9 41.88%, #8573B0 66.26%);
    height: 45px;
    font-size: 1.2em;
    font-weight: 700;
    transition: all 0.3s ease;
    gap: 10px;
}

.click-to-generate-button:hover:not(:disabled) {
    background-color: #5e5e5e; /* Darker shade for hover effect */
}

.click-to-generate-button.disabled {
    pointer-events: none;
}

.click-to-generate-button svg {
    width: 24px;
    height: 24px;
}

.click-to-generate-button:hover {
    opacity: 0.95;
    background: linear-gradient(102.84deg, #7e58dc 41.88%, #6a4eb6 66.26%);
}

.character-pose-text {
    font-size: 1em;
    color: #C4C4C4;
}


.pose-selection-general-header {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.char-name-header-gallery {
    color: white;
    font-size: 1.5em;
    font-weight: 700;
    white-space: normal;
}

.header-text-gallery-container {
    border-top: 2px solid #232323;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
}

.header-text-gallery {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-size: 1.5em;
    font-weight: 700;
    padding-top: 35px;
    padding-left: 40px;
}

.bottom-of-page-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    gap: 20px;
    padding: 10px 40px 40px;
}

.few-images {
    justify-content: flex-start;
}

.many-images {
    justify-content: space-between;
}

.image-liked-gallery-container {
    position: relative;
    width: 100%;
    height: auto;
    border-radius: 16px;
    cursor: pointer;
}

.image-liked-gallery-container:hover::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 14px;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s ease;
}

.image-liked-gallery {
    width: 100%;
    height: auto;
    border-radius: 16px;
}

.loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border: 6px solid rgba(255, 255, 255, 0.3);
    border-top-color: #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.loader-text {
    position: absolute;
    top: 100px; /* Adjust based on your loader size */
    left: 50%;
    transform: translateX(-50%);
    color: #ffffff;
    font-size: 16px;
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.no-images-message {
    color: #ffffff;
    font-size: 1.5em;
    font-weight: 700;
    width: 100%;
    text-align: center;
}

.like-dislike-buttons-gallery {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 9;
}

.like-icon-gallery, .dislike-icon-gallery {
    cursor: pointer;
    stroke: white;
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.5);
    fill: white;
    border-radius: 6px;
    margin-right: 8px;

}

.like-icon-gallery svg, .dislike-icon-gallery svg {
    width: 20px;
}

.like-icon-gallery:hover, .dislike-icon-gallery:hover {
    opacity: 0.65;
}

.icon-gallery-like-selected {
    cursor: pointer;
    stroke: white;
    padding: 4px 8px;
    background-color: rgba(255, 255, 255, 0.8);
    fill: #2f2f2f;
    border-radius: 6px;
    margin-right: 8px;
}

@media screen and (min-width: 1280px) {
    .normalizer-of-size-gallery {
        display: flex;
        width: fit-content;
        gap: 25px;
        flex-direction: column;
        align-items: flex-end;
    }
}

.textbox-prompt-gallery-general {
    resize: vertical;
    margin-top: 20px;
    margin-bottom: 10px;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
}

.textbox-prompt-gallery-general textarea {
    resize: vertical;
    border-radius: 12px;
    overflow: auto;
    max-height: 200px;
    min-height: 80px;
}

@media screen and (min-width: 720px) and (max-width: 980px) {
    .gallery-container-collection {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Creates four columns */
        gap: 20px;
    }

    .prompt-generate-box {
        min-height: 500px;
        min-width: 360px;
        max-height: 500px;
        max-width: 360px;
    }

    .prompt-generate-box-general {
        max-width: 360px;
        max-height: 500px;
    }

    .image-generation-container-right {
        max-width: 400px;
    }

    .image-generation-container-left {
        gap: 25px;
    }
}

@media screen and (min-width: 0px) and (max-width: 720px) {
    .gallery-container-collection {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Creates four columns */
        gap: 20px; /* Space between the items */
    }
    .char-name-header-gallery {
        white-space:  normal;
    }
    .image-generation-page {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .image-generation-container-left {
        width: 100%;
        flex-direction: column-reverse;
        align-items: center;
    }

    .image-gen-right-background {
        width: 100%;

    }

    .bottom-of-page-gallery {
        gap: 20px;
    }
}

@media screen and (min-width: 1680px) {
    .gallery-container-collection {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 20px;
    }
}

@media screen and (min-width: 1980px) {
    .gallery-container-collection {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 20px;
    }
}
