.character-card-background-user {
    height: 100%;
    max-height: 285px;
    width: 100%;
    background: url('./icons/backgroundShapes.svg'),
    linear-gradient(97.28deg, rgba(99, 87, 115, 0.3) 33.62%, rgba(57, 42, 64, 0.3) 75.64%), #131313;
    background-size: cover;
    border-radius: 24px 24px 0 0;
    z-index: -1;
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
}

.user-page-image {
    min-width: 300px;
    min-height: 300px;
    max-width: 300px;
    max-height: 300px;
    border-radius: 12px;
    margin-bottom: 5px;
    object-fit: cover;
    display: block;
    background-size: cover;
}

.user-profile-placeholder {
    background: url("../../components/UserSettings/ProfileIcons/new-default-profile.svg");
    background-size: cover;
    min-width: 300px;
    min-height: 300px;
    max-width: 300px;
    max-height: 300px;
    border-radius: 12px;
    border: 1px solid #C4C4C4;
}

.user-stats-text{
    font-weight: 500;
    letter-spacing: -0.01em;
    color: #B9B9B9;
    margin-top: -10px;
}

.follow-user-button{
    display: flex;
    width: 100%;
    min-width: 200px;
    max-width: 300px;
    height: 50px;
    background-color: #6F46D1;
    border-radius: 12px;
    color: white;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    gap: 5px;
    font-size: 1.25em;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    cursor: pointer;
}

.follow-user-button:hover {
    background-color: #7D5BEB;
    opacity: 0.90;
}

.follow-user-button svg {
    width: 22px;
    height: 22px;
}

.follow-user-button.loading {
    opacity: 0.7;
    cursor: not-allowed;
}