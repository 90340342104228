.banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-size: cover;
    background-position: center;
    height: 17vh;
    margin-top: 10px;
    border-radius: 20px;
    padding: 20px;
    color: white;
    user-select: none;
}

.banner-container-hero {
    display: flex;
    justify-content: center;
    width: 100%;
    background-size: cover;
    background-position: center;
    height: 20vh;
    margin-top: 10px;
    border-radius: 20px;
    padding: 20px 0 0 20px;
    color: white;
    user-select: none;


}

.banner-content {
    color: #fff; /* Adjust text color based on your background */
    justify-content: space-between;
    width: 90%;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.banner-content-hero {
    color: #fff; /* Adjust text color based on your background */
    justify-content: space-between;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.banner-left {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
}

.banner-left-hero {
    display: flex;
    flex-direction: column;
    width: 65%;
    align-items: flex-start;
    justify-content: center;
    margin-left: 3.5%;
}

.banner-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

}

.banner-right-hero {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    min-height: 320px;
    max-height: 420px;
    align-self: flex-end;
    justify-content: flex-end;
    background-image: url('./BannerIcons/real1.png'), url('./BannerIcons/anime1.png');
    background-position: right 120px bottom, right bottom;
    background-size: auto 100%, auto 100%;
    opacity: 0.20;
    right: 0;
    border-bottom-right-radius: 14px;
    background-repeat: no-repeat;
}

.colorful-text-nsfw {
    background: linear-gradient(94.33deg, #FF9FEA 30.43%, #FFFFFF 86.78%), #FFFFFF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-style: italic;
    width: 100%;
    padding: 0 5px 0 0;
}

.main-title-screen {
    display: flex;
    flex-direction: column;
}

.banner-title {
    margin: 0;
    font-size: 1.5em;
    font-weight: 600;
    white-space: nowrap;
}


.banner-subtitle {
    margin: 0;
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    font-size: 2.5em;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-weight: 800;
}

.banner-title-hero {
    margin: 0;
    font-size: 2.4em;
    font-weight: 600;
    white-space: nowrap;
}

.banner-subtitle-hero {
    font-size: 0.95em;
    font-weight: 500;
    color: #DCDCDC;
    width: 100%;
    margin-top: 20px;
}


.banner-icon {
    display: flex;
    justify-content: center;

}

.banner-icon svg {
    width: 48px;
    height: 48px;
}

.banner-state-1 {
    background: linear-gradient(275.36deg, rgba(0, 71, 255, 0.2) -5%, rgba(0, 0, 0, 0) 59.08%), linear-gradient(74.11deg, rgba(15, 111, 255, 0.106) 18.4%, rgba(0, 0, 0, 0) 48.51%), #1A1A1A;
    height: 200px;
}

.banner-state-2 {
    background: linear-gradient(275.36deg, rgba(255, 0, 230, 0.2) -5%, rgba(0, 0, 0, 0) 59.08%), linear-gradient(55.75deg, rgba(203, 0, 183, 0.2) 10.75%, rgba(0, 0, 0, 0) 60.65%), #1A1A1A;
    height: 200px;
}

.banner-state-3 {
    background: linear-gradient(275.76deg, rgba(73, 0, 255, 0.2) 2.12%, rgba(0, 0, 0, 0) 55.27%), linear-gradient(55.75deg, rgba(73, 0, 255, 0.2) 10.75%, rgba(0, 0, 0, 0) 60.65%), #1A1A1A;
    height: 200px;
}

.banner-state-4 {

    background: linear-gradient(275.36deg, rgba(142, 24, 235, 0.212) -5%, rgba(0, 0, 0, 0) 59.08%),
    linear-gradient(55.75deg, rgba(146, 23, 242, 0.212) 10.75%, rgba(0, 0, 0, 0) 60.65%),
    linear-gradient(180deg, #0F0F16 0%, rgba(46, 47, 72, 0) 100%),
    url("./BannerIcons/background.svg") #1A1A1A;


    height: 420px;
    background-size: cover;
    border: 1px solid #4C4C4C;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}


.button-state-1 {
    background-color: #3450B1;
}

.button-state-2 {
    background-color: #A73A9C;
}

.button-state-3 {
    background-color: #7F53EC;
}

.button-state-4 {
    background-color: #E47CCD;
    margin-top: 20px;
    z-index: 2;
    max-width: 380px;
}


.join-community-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
}

.join-community-row svg {
    fill: #A3A3A3;
    height: 24px;
    width: 24px;

}

.hero-banner-icon {
    cursor: pointer;
    fill: #A3A3A3;
    height: 24px;
    width: 24px;
    z-index: 9;
}

.center-component-banner {
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sub-button-text {
    color: #C5C5C5;
    font-size: 0.75em;
    cursor: pointer;
}

.banner-button {
    cursor: pointer;
    border: none;
    color: white;
    padding: 10px;
    height: 55px;
    gap: 10px;
    min-width: 200px;
    display: flex;
    font-size: 1.25em;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    white-space: nowrap;
    border-radius: 12px;
    transition: background-color 0.3s, opacity 0.3s;

}

.banner-button:hover {
    opacity: 0.8;
}

.banner-button-hero {
    cursor: pointer;
    border: none;
    color: white;
    padding: 10px;
    height: 45px;
    gap: 10px;
    width: 65%;
    min-width: 200px;
    /*max-width: 300px;*/
    display: flex;
    font-size: 1.25em;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    white-space: nowrap;
    border-radius: 12px;
    transition: background-color 0.3s, opacity 0.3s;
    margin-bottom: 10px;

}

.banner-button-hero:hover {
    opacity: 0.8;
}

.star-icon-banner {
    width: 20px;
    height: 20px;
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
}

.star-icon-banner svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
}



@media screen and (min-width: 0px) and (max-width: 420px) {

    .banner-right-hero {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        min-height: 420px;
        max-height: 520px;
        bottom: 0;
        align-self: flex-end;
        justify-content: flex-end;
        background-image: url('./BannerIcons/real1.png'), url('./BannerIcons/anime1.png');
        background-position: left -60px bottom, right bottom;
        background-size: auto 80%, auto 80%;
        opacity: 0.20;
        right: 0;
        border-bottom-right-radius: 14px;
        background-repeat: no-repeat;
    }
}
@media screen and (min-width: 420px) and (max-width: 680px) {

    .banner-right-hero {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        min-height: 520px;
        max-height: 620px;
        bottom: 0;
        align-self: flex-end;
        justify-content: flex-end;
        background-image: url('./BannerIcons/real1.png'), url('./BannerIcons/anime1.png');
        background-position: left -70px bottom, right bottom;
        background-size: auto 85%, auto 85%;
        opacity: 0.20;
        right: 0;
        border-bottom-right-radius: 14px;
        background-repeat: no-repeat;
    }

}


@media screen and (max-width: 680px) {
    .banner-state-4 {
        height: 100vh;
        border: none;
    }

    .banner-container-hero {
        margin-top: 0;
        border-radius: 0;
    }

    .banner-left-hero {
        width: 100%;


    }


    .banner-button-hero {
        font-size: 1em;
    }

    .banner-title-hero {
        font-size: 1.9em;
    }

    .banner-subtitle-hero {
        font-size: 0.95em;
    }

    .banner-left-hero-mobile {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        justify-content: center;
    }


    .banner-container-hero-mobile {
        position: absolute;
        padding: 30px;
        user-select: none;
        z-index: 1110;
    }

    .row-title-screen-mobile {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .banner-container {
        height: 30vh;
        max-height: 200px;
    }

    .banner-content {
        flex-direction: column;
        gap: 10px;
    }

    .banner-content-hero {
        flex-direction: column;
        gap: 10px;
    }

    .banner-title {
        font-size: 1em;
    }

    .banner-subtitle {
        font-size: 1.5em;
    }

    .banner-button {
        width: 50%;
        height: 45px;
        font-size: 1em;
    }

    .center-component-banner {
        gap: 5px;
    }

    .banner-left {
        align-items: center;
    }

    .banner-right {
        align-items: flex-end;
    }

    .sub-button-text {
        font-size: 0.65em;
    }

    .star-icon-banner {
        width: 20px;
        height: 20px;
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
    }

    .star-icon-banner svg {
        width: 20px;
        height: 20px;
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
    }
}


@media screen and (min-width: 680px) and (max-width: 1280px) {
    .banner-content {
        width: 95%;
    }

    .banner-content-hero {
        width: 100%;
    }

    .banner-button-hero {
        font-size: 1.1em;
    }
}


@media screen and (min-width: 1280px) and (max-width: 1680px) {
    .banner-content {
        width: 95%;
    }

    .banner-content-hero {
        width: 100%;
    }

    .banner-subtitle-hero {
        width: 85%;
    }

}

@media screen and (min-width: 1680px) and (max-width: 1980px) {
    .banner-content {
        width: 75%;
    }

    .banner-content-hero {
        width: 100%;
    }

    .banner-subtitle-hero {
        width: 85%;
    }

}

@media screen and (min-width: 1980px) {
    .banner-content {
        width: 60%;
    }

    .banner-content-hero {
        width: 100%;
    }
}






