.menu-item-content-icon-premium svg {
    stroke: #9072D9!important;
    min-width: 24px;
    max-width: 24px;
    max-height: 24px;
    min-height: 24px;
    animation: strokeGlow 5s ease-in-out infinite;
}

.menu-item-content-icon-premium-but-smaller{
    display: flex;
    align-items: center;
    font-size: 0.95em;
    animation: strokeGlow 5s ease-in-out infinite;
    font-weight: 600;
    color: #9072D9;
}

@media (max-width: 680px) {
    .menu-item-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 64px; /* Fixed width for all items */
        height: 100%;
        padding: 0;
        margin: 0;
        gap: 5px;
    }

    @keyframes strokeGlow {
        0% {
            filter: brightness(1.25);
            opacity: 0.9;
        }
        50% {
            filter: brightness(1.5);
            opacity: 1;
        }
        100% {
            filter: brightness(1.25);
            opacity: 0.9;
        }
    }

    .menu-item-content-icon {
        stroke: #BEBEBE;
        min-width: 24px;
        max-width: 24px;
        max-height: 24px;
        min-height: 24px;
    }

    .menu-item-content-icon-premium svg {
        stroke: #9072D9!important;
        min-width: 24px;
        max-width: 24px;
        max-height: 24px;
        min-height: 24px;
        animation: strokeGlow 5s ease-in-out infinite;
    }

    .menu-item-content-icon-premium-but-smaller{
        display: flex;
        align-items: center;
        font-size: 0.95em;
        animation: strokeGlow 5s ease-in-out infinite;
        font-weight: 600;
        color: #9072D9;
    }

    .menu-item-content-icon-premium-but-smaller svg {
        stroke: #9072D9!important;
        min-width: 20px;
        max-width: 20px;
        max-height: 20px;
        min-height: 20px;
        /*animation: strokeGlow 5s ease-in-out infinite;*/
    }


    .menu-item-content-label{
        color: #c4c4c4;
        font-size: 0.85em;
        font-weight: 600;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .menu-item-content-label-premium {
        color: #9072D9;
        animation: strokeGlow 5s ease-in-out infinite;
        font-size: 0.85em;
        font-weight: 600;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .header-container-mobile-nav{
        width: 100vw;
    }

    .main-mobile-navigation {
        display: flex;
        z-index: 1004;
        flex-direction: row;
        position: fixed;
        width: 100%;
        height: 85px;
        bottom: 0;
    }

    .header-container-chat-mobile-nav{
        width: 100vw;
    }

    .menu-item-label-navigation {
        color: #c4c4c4;
        font-size: 0.85em;
        font-weight: 600;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .mobile-navigation-main {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #131313;
        border-top: 2px solid #242424;
        user-select: none;
    }

    /* Set fixed size for icons */
    .menu-item-content svg {
        width: 24px;
        height: 24px;
        min-width: 24px; /* Prevent shrinking */
        min-height: 24px; /* Prevent shrinking */
    }

    /* Make menu items equal width */
    .menu-item-container-bottom {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 20%; /* Since there are 5 items, each takes 20% */
    }

    .header-menu-item {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    .header-menu-item.selected .menu-item-label-navigation {
        color: white;
    }
}