.LegalApp .LegalHeader {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    color: white;
}

.LegalApp .DocumentNav {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LegalApp .DocButton {
    cursor: pointer;
    width: 100%;
    background-color: #916DE8;
    color: white;
    border: none;
    padding: 20px;
    margin: 5px;
    border-radius: 5px;
    font-size: 1.5em;
    transition: background-color 0.3s, transform 0.2s;
}

.LegalApp .DocButton:hover {
    background-color: rgba(145, 109, 232, 0.8);
    transform: scale(1.05);
}

/* Terms of Service container */
.tos-container {
    max-width: 680px;
    padding: 20px;
    margin: 40px auto 0;
}

.GoBackButton {
    color: white;
    position: fixed;
    align-items: center;
    background: #595959;
    justify-content: center;
    padding: 6px;
    width: auto;
    height: auto;
    cursor: pointer;
    margin: 5px;
    top: 0;
    border-radius: 4px;
    transition: background-color 0.3s;

}

.GoBackButton:hover {
    background-color: #4d4d4d;

}

/* Heading style */
.tos-heading {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
    color: white;
    -webkit-background-clip: text;
    background-clip: text;

}

/* Section heading style */
.tos-section {
    font-size: 1.125em;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    color: white; /* Slightly lighter gray text color */
}

.tos-content h1, .tos-content h2 {
    color: #ffffff;
}

.tos-content p, .tos-content ul {
    color: white;
}

/* Guidelines container */
.guidelines {
    margin-top: 40px;
}

/* Guidelines heading style */
.guidelines-heading {
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333; /* Dark gray text color */
}

