/* Search Component Styles */
.search-container {
    border: 1px solid #4F4F4F; /* Main color */
    padding: 6px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    width: 35vw;
    max-width: 450px;
    height: auto;
    z-index: 999;
    margin-left: 25px;

    color: #BEBEBE; /* Main color */
    background: #1A1A1A;
}

.search-input {
    background: transparent;
    border: none;
    width: 100%;
    outline: none;
    color: #FFFFFF; /* Text color */
    font-size: 1.125em;
    font-weight: 500;
    margin-left: 10px;
}

.search-input::placeholder { /* Edge 12 -18 */
    color: #BEBEBE;
}

.search-icon svg {
    color: #BEBEBE; /* Main color */
}

.search-icon {
    margin-left: 10px;

}

@media screen and (max-width: 680px) {
    .search-container {
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
        padding: 8px;
        max-width: 100%;

    }
}
