.main-header-container {
    display: -webkit-box; /* Old iOS */
    display: -moz-box; /* Old Firefox */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Old Chrome/Safari */
    display: flex;
    -webkit-flex-direction: column; /* Chrome 21+, Safari 7+ */
    -moz-flex-direction: column; /* Firefox */
    -ms-flex-direction: column; /* IE 10 */
    flex-direction: column;
    width: 100%; /* More consistent and avoids issues with scrollbars */
    z-index: 1003;
    position: fixed; /* Consider alternatives or additional properties for older IE versions */
    -webkit-align-items: center; /* Chrome 21+, Safari 7+ */
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    border-bottom: 2px solid #242424;
    background-color: #131313;

    /*//TODO DANGER */
    /*overflow: hidden;*/
    /*//TODO DANGER */

    height: 87px; /* Ensure this fixed height does not cause content issues on smaller screens */
    -webkit-transition: height 0.2s ease-in-out; /* Chrome/Safari */
    -moz-transition: height 0.2s ease-in-out; /* Firefox */
    -o-transition: height 0.2s ease-in-out; /* Opera */
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

}

.header-container-chat {
    width: 100vw;
    height: 85px;
    margin: auto;
    background: #131313;
    border-radius: 0;
    display: flex;
    align-items: center;
    position: absolute;
    flex-direction: row;
    padding: 10px 20px;
    display: -webkit-box; /* Old iOS */
    display: -moz-box; /* Old Firefox */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Old Chrome/Safari */
    display: flex;
    -webkit-flex-direction: column; /* Chrome 21+, Safari 7+ */
    -moz-flex-direction: column; /* Firefox */
    -ms-flex-direction: column; /* IE 10 */
    z-index: 9;
    -webkit-align-items: center; /* Chrome 21+, Safari 7+ */
    -moz-align-items: center; /* Firefox */
    -ms-align-items: center; /* IE 10 */
    overflow: hidden;
    -webkit-transition: height 0.2s ease-in-out; /* Chrome/Safari */
    -moz-transition: height 0.2s ease-in-out; /* Firefox */
    -o-transition: height 0.2s ease-in-out; /* Opera */
    transition: height 0.2s ease-in-out;
}

.main-header-container-chat {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 87px;
    z-index: 1030;
    position: fixed;
    align-items: center;
    background-color: #131313;
    border-bottom: 2px solid #242424;
}

.main-header-container.expanded {
    height: 150px; /* Expanded height */
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.main-header-container.expanded .header-searchbar-visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.header-container {
    width: 97vw;
    z-index: 4;
    height: 85px;
    background: #131313;
    border-radius: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.mobile-menu-icon svg {
    height: 30px;
    width: 30px;
}


.header-searchbar {
    display: flex;
    justify-content: space-around;

}

.tooltip-for-header {
    z-index: 999;

}

.header-icon {
    justify-items: center;
    position: relative;
    align-items: center;
}


.menu-item-container {
    cursor: pointer;
}

.temp-language-dropdown {
    min-width: 135px;
    margin-left: 10px;
    z-index: 9999;
    font-size: 0.85em;
}

.header-menu {
    margin-right: 0;
    gap: 30px;
    display: flex;
    position: relative;
    align-items: flex-end;
    background: transparent;
    width: 100%;
    font-size: 1.25em;
    white-space: nowrap;
    transition: color 0.1ms, background 0.1ms, border-bottom 0.1ms;
    border-bottom: 2px solid transparent;
    display: -webkit-box; /* Old iOS */
    display: -moz-box; /* Old Firefox */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Old Chrome/Safari */
    display: flex;
    -webkit-align-items: flex-end; /* Safari 7.0+ */
    -webkit-justify-content: flex-end; /* Safari 7.0+ Chrome */
    -moz-justify-content: flex-end; /* Firefox */
    -ms-justify-content: flex-end; /* IE 10+ */
    justify-content: flex-end; /* Standard syntax */
    -webkit-transition: color 0.1ms, background 0.1ms, border-bottom 0.1ms; /* Chrome/Safari */
    -moz-transition: color 0.1ms, background 0.1ms, border-bottom 0.1ms; /* Firefox */
    -o-transition: color 0.1ms, background 0.1ms, border-bottom 0.1ms; /* Opera */
    transition: color 0.1ms, background 0.1ms, border-bottom 0.1ms;
    border-bottom: 2px solid transparent;
}

.header-menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
    stroke: #BEBEBE;
}

.header-menu-item svg {
    max-width: 24px;
    max-height: 24px;
    min-width: 24px;
    min-height: 24px;
    transition: stroke 0.3s ease;
}

.header-menu-item:focus svg,
.header-menu-item:hover svg,
.header-menu-item.selected svg {
    stroke: #ffffff; /* Change stroke to white */
}


.header-menu-item::after {
    content: '';
    display: block;
    margin-top: 5px;
    height: 2px;
    background: #ffffff;
    transition: width 0.1ms ease;
}

.header-menu .header-menu-item:hover {
    color: #f3f3f3;
    opacity: 1.05;
}

.header-menu-item.selected {
    opacity: 1.05;
}

.desktop-menu {
    display: flex;
    max-height: 100px;
    align-items: center;
    font-size: 100%;
    gap: 10px;
    width: 100%;
    justify-content: flex-start;
}

.header-menu-item {
    display: flex;
    align-items: flex-end;
    padding: 5px 5px;
    position: relative;
    text-decoration: none;
    transition: all 100ms ease;
    color: #FFFFFF;
}

.header-searchbar-visible {
    display: none;
}

.dropdown-profile {
    display: flex;
    position: fixed;
    z-index: 9999;
    background-color: #242424;
    border-radius: 10px;
}

.header-char-creator-switch {
    display: flex;
    position: relative;
    height: 44px;
    padding: 2px;
    gap: 4px;
    margin-left: 45px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(144, 114, 217, 0.25);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.header-char-creator-switch:hover {
    box-shadow: 0 4px 12px rgba(144, 114, 217, 0.35);
}

.header-switch-option {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    min-width: 120px;
    height: 40px;
    padding: 0 16px;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 600;
    font-size: 0.9em;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    user-select: none;
}

.header-switch-option.selected {
    background: linear-gradient(102.84deg, #9072D9 41.88%, #8573B0 66.26%);
}

/* Icon styling */
.header-switch-option svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    transition: all 0.3s ease;
}


@media screen and (max-width: 680px) {

    .header-char-creator-switch {
        display: none;
    }
    .sign-up-button {
        background-color: #A281F2;
        color: white;
        padding: 8px 16px;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        transition: background-color 0.2s;
    }

    .sign-up-button:hover {
        background-color: #9072D9;
    }

    .header-searchbar {
        display: none;

    }

    .header-menu {
        gap: 10px;
        align-items: center;
    }

    .header-searchbar-visible {
        display: flex;
        width: 100%;
        position: absolute;
        top: 85px;
    }

    .header-menu-chatpage {
        gap: 10px;
    }

    .header-container-chat {
        z-index: 3;
        height: 85px;
        background: #131313;
        border-radius: 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        position: relative;
        padding: 10px;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }
}
