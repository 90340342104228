@keyframes glow {
    0% {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    }
    100% {
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
    }
}

.character-list-menu {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
}

.general-row-container {
    flex-direction: column;
    display: flex;
    position: relative;
    align-items: flex-start;
    color: white;
    margin: auto;
}

.GeneralRowsChars {
    width: 97vw;
    padding-left: 5px;
    padding-right: 5px;
    z-index: 9;
}

.character-row.placeholder {
    height: 150px; /* Adjust based on your design */
    background-color: #f3f1f5; /* Light variant of your secondary color */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #131313; /* Primary color for any text */
    margin: 10px 0;
}

.character-row.placeholder:before {
    content: "Loading more characters...";
    font-style: italic;
    color: #131313; /* Primary color */
}


.ErrorScreenOverlay {
    position: absolute; /* or absolute */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's above other content */
}

.character-row {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    position: relative;
    align-items: center;
    scrollbar-width: none; /* for Firefox */
    width: 100%;

}

.general-row-container h2 {
    padding-bottom: 5px;
    padding-top: 20px;
    padding-left: 4px;

}

.character-container-grid-new {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding: 10px 5px 10px 5px;
    flex-direction: row;
    scrollbar-width: none;
    -ms-overflow-style: none;

}

.character-container-grid-new::-webkit-scrollbar {
    display: none;
}

.scroll-buttons-container {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 50px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 16px; /* Increase the gap as needed for more space between buttons */
}

.scroll-button-character {
    background: transparent;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    transition: opacity 0.2s;
    position: absolute; /* Add absolute positioning for individual control */
    top: 50%; /* Center vertically */
    transform: translateY(-50%);
}

.right-scroll {
    left: 0; /* Position to the far left */

}

.left-scroll {

    right: 0; /* Position to the far right */

}

.scroll-button:hover {
    opacity: 1; /* Full opacity on hover */
}


.character-container-grid-new::before,
.character-container-grid-new::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100px; /* Adjust the width of the fade effect as needed */
    height: 100%;
    pointer-events: none; /* So that clicks pass through */
}

.character-container-grid-new::before {
    z-index: 9;
    left: 0;
    background: linear-gradient(to right, #131313, rgba(19, 19, 19, 0));
    transition: background 0.3s ease; /* Add this line */
}

.character-container-grid-new::after {
    z-index: 9;
    right: 0;
    background: linear-gradient(to left, #131313, rgba(19, 19, 19, 0));
    transition: background 0.3s ease; /* Add this line */
}


.character-container-grid-new::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Webkit browsers */
}

.another-event-container {
    width: 97vw;
    padding: 5px 5px 0 5px;
}

.event-mainmenu-container {
    height: fit-content;
    margin-top: 10px;
    border-radius: 20px;




    /*background: linear-gradient(275deg, rgba(142, 24, 235, 0.11) -5%, rgba(0, 0, 0, 0.00) 59.08%), linear-gradient(56deg, rgba(142, 24, 235, 0.11) 10.75%, rgba(0, 0, 0, 0.00) 60.65%), #1A1A1A;*/
    /*box-shadow: 0px 2px 4px 0px rgba(150, 150, 150, 0.25);*/
    position: relative;

}


.loading-spin {
    display: flex;
    margin-top: 45px;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.no-blur-left::before {
    background: none;
    transition: background 0.3s ease; /* Add this line */
}

.no-blur-right::after {
    background: none;
    transition: background 0.3s ease; /* Add this line */
}

.placeholder-row {
    display: flex;
    width: 95vw;
    gap: 10px;
    padding: 15px 5px 5px;
    position: relative;
    overflow: hidden;
}

.placeholder-row::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Adjust if necessary to ensure it overlays correctly */
    background: linear-gradient(to right, rgba(19, 19, 19, 0), #131313); /* Adjusted gradient direction */
    transition: background 0.3s ease;
}

.pseudo-card {
    border-radius: 24px;
    position: relative;
    height: 380px;
    min-width: 200px;
    background: linear-gradient(
            to right,
            transparent 0%,
            rgb(66, 66, 66) 50%,
            transparent 100%
    );
    animation: glistening 1.5s infinite;
    background-size: 200% 100%;
    box-shadow: 0 2px 4px 0 rgba(150, 150, 150, 0.25);
}

@keyframes glistening {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

.loading-indicator {
    width: 25vw;
    height: 40px;
    margin-top: 30px;
    background: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    margin-left: 5px; /* Align to the left */
    border-radius: 24px; /* Border radius */
    background-size: 200% 100%;
    box-shadow: 0 2px 4px 0 rgba(150, 150, 150, 0.25); /* Subtle shadow for depth */
    animation: glistening 1.5s infinite;
}


@media (max-width: 680px) {
    .event-mainmenu-container {

        margin-top: 0;
    }
    .scroll-buttons-container {
      display: none;
    }
    .pseudo-card {
        height: 298px;
        min-width: 200px;
    }
    .loading-indicator{
        width: 20vw;
        height: 35px;
    }
    .placeholder-row {
        padding-top: 10px;
    }

}


@media (min-width: 680px) and (max-width: 1280px) {
    .pseudo-card {
        height: 340px;
        min-width: 200px;
    }
    .loading-indicator{
        width: 20vw;
        height: 35px;
    }


}