.pose-options-scroll {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding: 10px 0;
}

.pose-options-scroll button {
    background-color: #2a2a2a;
    border: 1px solid #4F4F4F;
    border-radius: 20px;
    color: #ffffff;
    padding: 8px 16px;
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.3s;
}

.image-generation-options {
    margin-top: 10px;
}

.pose-options-scroll button:hover,
.pose-options-scroll button.selected {
    background-color: #4a4a4a;
}


.aspect-ratio-selector {
    display: flex;
    width: 100%;
    gap: 15px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.label-image-gen {
    color: #C4C4C4;
    font-size: 0.95em;
    font-weight: 450;
}

.aspect-ratio-button {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: white;
}

.aspect-ratio-button:hover {
    background-color: #242424;
}

.aspect-ratio-button.selected {
    background-color: #242424;
    border-color: #916DE8;
}

.aspect-ratio-visual {
    width: 100%;
    max-width: 40px;
    max-height: 55px;
    margin-bottom: 10px;
}

.aspect-ratio-inner {
    width: 100%;
    height: 100%;
    background-color: #1A1A1A;
    border-radius: 4px;
    border: 1px #4F4F4F solid;
}

.aspect-ratio-button span {
    font-size: 14px;
    font-weight: bold;
}

.pose-suggestions {
    margin-bottom: 10px;
}

.pose-item {
    color: white;
    font-weight: 600;
    font-size: 0.90em;
    background-color: #242424;
    border-radius: 6px;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 5px;
    margin-right: 5px;
}

.pose-item:hover {
    background-color: #282828;
}

.pose-item:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.locked-poses {
    display: flex;
    flex-wrap: wrap;
    border-radius: 12px;
    margin-top: 5px;
    gap: 5px;
}

.locked-pose {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 600;
    font-size: 0.90em;
    background-color: #242424;
    border-radius: 6px;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    gap: 5px;
}

.locked-pose:hover {
    background-color: #202020;
}

.locked-pose-cross {
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.locked-pose-cross svg {
    max-height: 14px;
    max-width: 14px;
    min-height: 14px;
    min-width: 14px;
}