.audio-preview-container {
    display: flex;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 15px;
}

.custom-player {
    display: flex;
    align-items: center;
    background-color: #1C1C1C;
    border: 1px solid #3b3b3b;
    padding: 6px 8px;
    border-radius: 8px;
    width: 100%;
    position: relative;
    height: 50px;
}

.player-button svg{
    min-width: 24px;
    min-height: 24px;
    max-height: 24px;
    max-width: 24px;
    stroke: #c4c4c4;
}

.player-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: none;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.player-button:hover {
    background-color: #4b4b4b;
}

.time-controls {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
}

.time-display {
    color: white;
    font-size: 14px;
    width: 48px;
    text-align: center;
}

.volume-controls {
    position: relative;
}

.volume-popup {
    position: absolute;
    bottom: 40px;
    right: -8px;
    background-color: #282828;
    padding: 12px 8px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    transform: translateY(10px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
}


.volume-popup.visible {
    opacity: 1;
    visibility: visible;
}

.progress-slider,
.volume-slider {
    -webkit-appearance: none;
    background: #4b4b4b;
    cursor: pointer;
    border-radius: 2px;
    position: relative;
}

.progress-slider {
    flex: 1;
    height: 4px;
}

.volume-slider {
    width: 4px;
    height: 100px;
    /*transform: rotate(180deg);*/
}

.progress-slider::-webkit-slider-thumb,
.volume-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border: none;
    transition: transform 0.1s ease;
    margin-top: -4px; /* Centers the thumb vertically */
}

.progress-slider::-moz-range-thumb,
.volume-slider::-moz-range-thumb {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border: none;
    transition: transform 0.1s ease;
    margin-top: -4px; /* Centers the thumb vertically */
}

/* Track styling */
.progress-slider::-webkit-slider-runnable-track,
.volume-slider::-webkit-slider-runnable-track {
    height: 4px;
    border-radius: 2px;
    background: transparent;
}

.progress-slider::-moz-range-track,
.volume-slider::-moz-range-track {
    height: 4px;
    border-radius: 2px;
    background: transparent;
}
/* Hover effects */
.progress-slider::-webkit-slider-thumb:hover,
.volume-slider::-webkit-slider-thumb:hover {
    transform: scale(1.2);
}

.progress-slider::-moz-range-thumb:hover,
.volume-slider::-moz-range-thumb:hover {
    transform: scale(1.2);
}

/* Vertical volume slider thumb centering */
.volume-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border: none;
    transition: transform 0.1s ease;
    margin-left: -4px; /* Centers the thumb horizontally */
}

.volume-slider::-moz-range-thumb {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border: none;
    transition: transform 0.1s ease;
    margin-left: -4px; /* Centers the thumb horizontally */
}

.volume-slider::-webkit-slider-thumb {
    margin-left: -4px; /* Centers the thumb horizontally */
}

.volume-slider::-moz-range-thumb {
    margin-left: -4px; /* Centers the thumb horizontally */
}

/* Updated background gradients to fix color positions */
.progress-slider {
    background-image: linear-gradient(to right, #4b4b4b 0%, #4b4b4b 100%);
}

.volume-slider {
    background-image: linear-gradient(to top, #4b4b4b 0%, #4b4b4b 100%);
}