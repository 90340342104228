
.token-counter-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.token-counter-reward {
    display: flex;
    color: #fff;
    gap: 3px;
    font-weight: 600;
    font-size: 1em;
}

.reward-item-reward-button svg,
.special-reward-button svg,
.reward-item-reward svg,
.token-counter-reward svg {
    min-width: 18px;
    min-height: 18px;
    max-height: 18px;
    max-width: 18px;
}

.reward-screen-loading {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
    margin-top: 15px;
}

.reward-loader-label {
    width: 100%;
    height: 20px;
    border-radius: 4px;
}

.reward-loading-item {
    width: 100%;
    height: 50px;
    border-radius: 8px;
}

.token-counter-loader {
    width: 100%;
    height: 45px;
    border-radius: 4px;
}

.token-counter-loader,
.reward-loader-label,
.reward-loading-item {
    background-color: #1e1e1e; /* Your desired solid background color */
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    animation: glistening 1.5s infinite;
}

.get-more-tokens-button {
    color: #c4c4c4;
    font-size: 1em;
    cursor: pointer;
}

.reward-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.reward-item-container {
    display: flex;
    flex-direction: column;
    background: #202020;
    height: auto;
    padding: 8px;
    border-radius: 8px;
    width: 100%;
    justify-content: center;
    gap: 5px;
}

.crossed-out-reward {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: line-through;
    opacity: 1;
    gap: 3px;
    color: #c4c4c4;
}

.reward-item-reward {
    display: flex;
    align-items: center;
}

.reward-item-container.special-reward-item {
    gap: 5px;
}

.reward-item-container-sub {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    height: 30px;
    align-items: center;
}

.reward-item-redeemed {
    color: #9072D9;
}

.reward-title-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.reward-item-title {
    font-size: 0.9em;
    font-weight: 550;
    white-space: nowrap;
}

.reward-item-subtitle {
    font-size: 0.80em;
}

.redeem-reward-button {
    display: flex;
    width: 135px;
    height: 100%;
    color: white;
    align-items: center;
    justify-content: center;
    font-weight: 550;
    white-space: nowrap;
    font-size: 1em;
    background: #9072D9;
    border-radius: 8px;
    gap: 5px;
    cursor: pointer;
    margin-left: auto;
}

.reward-item-reward {
    display: flex;
    flex-direction: row;
    gap: 3px;
    height: 100%;
    margin-left: auto;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    /*color: white;*/
    font-weight: 600;
    font-size: 0.8em;
}

.reward-item-redeemed {
    color: #9072D9;
}

.reward-item-reward-button {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: white;
}

.reward-item-progress {
    display: flex;
    align-items: center;
    gap: 10px;
}

.progress-bar-container-reward {
    width: 100%;
    height: 3px;
    background-color: #000000;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 3px;
}

.progress-bar-reward {
    height: 100%;
    background-color: #9072D9;
    transition: width 0.3s ease-in-out;
}

.special-reward-item-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.special-reward-item-content {
    width: 100%;
}

.special-reward-item-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    height: 100%;
    width: 100%;
}

.special-reward-button {
    display: flex;
    width: 105px;
    height: 30px;
    color: white;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    white-space: nowrap;
    font-size: 0.9em;
    background: linear-gradient(180deg, #9072D9 0%, #8573B0 100%);
    border-radius: 8px;
    gap: 5px;
    cursor: pointer;
}

@media screen and (max-width: 680px) {
    .reward-item-title {
        font-size: 0.9em;
        font-weight: 550;
        white-space: break-spaces;
    }
}

