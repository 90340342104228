.advanced-step-one h2 {
    margin-top: 30px;
    margin-bottom: 15px;
}

.advanced-step-one {
    margin-top: 25px;
}

.options-container {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;
    margin-bottom: 15px;
}

.option-box {
    background-color: #151515;
    color: #757575;
    padding: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 12px;
    border: 2px solid #757575;
    height: 100px;
}

.option-box:hover {
    background-color: #333;
}

.option-box.selected {
    border: 2px solid #9980D7;
    color: white;
}

.option-text {
    font-size: 1em;
    font-weight: 700;
}

.option-description {
    font-size: 0.85em;
    font-weight: 500;
}

.upload-char-creator-adv {
    color: white;
    font-size: 1em;
    font-weight: 700;
}

.image-upload-prompt {
    display: flex;
    flex-direction: column;
    color: white;
}

.generated-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-upload-container {
    display: flex;
    border: 2px dashed #696969;
    padding: 20px;
    text-align: center;
    border-radius: 12px;
    margin-top: 15px;
    min-height: 200px;
    align-items: center;
    justify-content: center;
}

.final-option-image-upload {
    position: relative;
    display: inline-block;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 12px;
}

.final-option-image-upload:hover .image-overlay {
    opacity: 1;
}

.char-final-image {
    border-radius: 12px;
    max-width: 600px;
    max-height: 280px;
}

.clear-image-button {
    display: none;
}

.custom-switch-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #C4C4C4;
}

.custom-switch {
    width: 60px;
    height: 30px;
    background-color: #242424;
    border-radius: 15px;
    padding: 2px;
    transition: background-color 0.3s;
    position: relative;
}

.custom-switch.on {
    background: linear-gradient(102.84deg, #9072D9 41.88%, #8573B0 66.26%);
}

.switch-handle {
    width: 26px;
    height: 26px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
}

.custom-switch.on .switch-handle {
    transform: translateX(30px);
}

.switch-text {
    margin-left: 10px;
    font-size: 1em;
}

.nav-button.next-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}


@media screen and (min-width: 1920px) {
    .option-text {
        font-size: 1.4em;
    }
    .option-description{
        font-size: 1.05em;
    }
    .option-box {
        height: 135px;
    }
    .nav-button{
        font-size: 1.2em;
    }
    .step-title{
        font-size: 1.75em;
    }
    .step-subtitle{
        font-size: 1.25em;
    }
}

@media screen and (min-width: 1680px) and (max-width: 1920px) {
    .option-text {
        font-size: 1.35em;
    }
    .option-description{
        font-size: 1.0em;
    }
    .option-box {
        height: 125px;
    }
    .nav-button{
        font-size: 1.15em;
    }
    .step-title{
        font-size: 1.65em;
    }
    .step-subtitle{
        font-size: 1.2em;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1680px) {
    .option-text {
        font-size: 1.2em;
    }
    .option-description{
        font-size: 0.95em;
    }
    .option-box {
        height: 120px;
    }
    .nav-button{
        font-size: 1.15em;
    }
    .step-title{
        font-size: 1.5em;
    }
    .step-subtitle{
        font-size: 1.15em;
    }
}

@media screen and (min-width: 680px) and (max-width: 1280px) {
    .option-text {
        font-size: 1.15em;
    }
    .option-description{
        font-size: 0.9em;
    }
    .option-box {
        height: 115px;
    }
    .nav-button{
        font-size: 1em;
    }
    .step-title{
        font-size: 1.4em;
    }
    .step-subtitle{
        font-size: 1.1em;
    }
}


@media screen and (max-width: 680px) {
    .clear-image-button {
        margin-top: 10px;
        padding: 5px 10px;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    .options-container {
        gap: 10px;
        margin-bottom: 10px;
    }

    .option-box {
        padding: 6px;
    }

    .option-description {
        font-size: 0.75em;
    }

    .char-final-image {
        border-radius: 12px;
        max-width: 200px;
        max-height: 200px;
    }
}

