.sales-navbar {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    background: transparent;
    justify-items: center;
    justify-content: flex-start;
    align-self: flex-start;
    width: 100%;
    overflow-x: hidden;
}


.join-button:hover {
    opacity: 1.2;
    transition: opacity 0.3s ease-in-out;
}
.sales-nav-button.active, .sales-nav-button2.active {
    font-weight: bold;
    border-bottom: 3px solid #FFF;
}

.join-button:hover {
    opacity: 1.2;
}



.join-button {
    text-decoration: none;
    margin-top: 40px;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    font-weight: 700;
    margin-left: 0;
    margin-right: 0;
    white-space: nowrap;
    line-height: 36px;
    color: white;
    background: #E47CCD;
    opacity: 1;
    width: 100%;
    height: 55px;
    border-radius: 13px;
    cursor: pointer;
}

/* Hover */
.join-button:hover {

    background: rgba(228, 124, 205, 1);
    opacity: 0.8;
}


.activeSales {
    font-weight: bold;
    border-bottom: 3px solid #FFF;
}



.ElyzaSalesPageLogo{
    margin-left: 20px;
    margin-top: 30px;

}





.login-button-sales-page {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.25em;
    font-weight: 700;
    transition: color 0.3s ease; /* Smooth transition for hover effect */
}
.login-button-sales-page:hover {
    color: #e0e0e0; /* Lighter shade of white on hover */
}
.signup-button-sales-page {
    background-color: #E47CCD;
    color: white;
    border: none;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 36px;
    padding: 6px 20px;
    border-radius: 13px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.signup-button-sales-page:hover {
    background-color: #d36cb8; /* Slightly darker shade on hover */
}

.sales-navbar-buttons {
    display: flex;
    margin-top: 30px;
    margin-right: 10px;
    align-items: center;
    gap: 10px; /* Adjust the gap as needed */
    margin-left: auto; /* Pushes the buttons to the right */
}
























@media (max-width: 600px) {
    .sales-navbar {
        padding: 10px;
        margin-bottom: 20px;
        height: auto;
        overflow-x: hidden;
    }

    .sales-title-text {
        font-size: 1.625em;
        line-height: 52px;
    }


    .ElyzaSalesPageLogo{
        margin-left: 0;


    }
}





@media (max-width: 1280px) {
    .sales-navbar-buttons {

        font-size: 0.8750em;
        line-height: 36px;

    }
    .sales-nav-button,
    .sales-nav-button2 {
        margin-left: -15px;
    }

}
@media (max-width: 1980px) {
    .join-button {

        width: 80%;

    }
}