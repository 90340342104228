.App {
    text-align: center;
    overflow-x: hidden;
    overflow: unset;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}
.MuiTooltip-tooltip {
    color: #e7e7e7 !important;
    background-color: #2c2929 !important;
    font-size: 0.75em !important;
}
.MuiTooltip-arrow {
    color: #2c2929 !important; /* Same as tooltip background for consistent look */
}
.App-header {
    background-color: transparent;
    /*#b5a2e0*secondary/
    /*#2e2f48*primary*/


    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* Styles for Webkit browsers like Chrome, Safari */
*::-webkit-scrollbar {
display: block;

    cursor: pointer;
    width: 4px;
}

*::-webkit-scrollbar-track {
    background: rgba(52, 52, 52, 0.85); /* Track color */
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(145, 109, 232, 0.8); /* Thumb color */
    border-radius: 4px; /* Roundness of the thumb */

}

*::-webkit-scrollbar-thumb:hover {
    background-color: #6e34ea;

}
*,
*::before,
*::after {
    box-sizing: border-box;
}