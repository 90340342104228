.ChatPage {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
}

.card-container {
    display: flex;
    position: relative;
    width: 100%;
    height: fit-content;
    margin-top: 5px;
}

.front-card {
    top: 0;
    z-index: 9;
    left: 50%;
    transform: translateX(-50%);
}

.front-card:hover {
    transform: translateX(-50%) scale(1.05) translateY(-5px);
    box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.7);
}

.back-card {
    top: 10px;
    width: 110px;
    height: 150px;
}

.back-card.left {
    left: 35%;
    transform: translateX(-50%) rotate(-10deg);
}

.back-card.left:hover {
    z-index: 99;
    transform: translateX(-50%) rotate(-10deg) scale(1.05);
    box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.7);
}

.back-card.right {
    left: 65%;
    transform: translateX(-50%) rotate(10deg);
}

.back-card.right:hover {
    z-index: 99;
    transform: translateX(-50%) rotate(10deg) scale(1.05);
    box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.7);
}

.scroll-bottom-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Keeps the button in place relative to the viewport */
    bottom: 140px;
    left: 50%;
    transform: translateX(-50%);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: white;
    color: black;
    border: none;
    cursor: pointer;
    text-align: center;
    padding: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
}

.main-elyza-text-sublabel-gc {
    font-weight: 700;
    margin-left: 20px;
    font-size: 16px;
    color: #C4C4C4;
    white-space: nowrap;
    margin-right: 50%;
}

.recent-chat-list-h-container {
    position: fixed;
    max-width: 360px;
    display: flex;
    justify-items: center;
    align-content: center;
    align-items: center;
}

.gallery-container-h {
    display: flex;
    flex-direction: column;
    min-width: 360px;
    max-width: 360px;
    min-height: calc(100% - 85px);
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #131313;
    z-index: 99;
    border-left: 2px solid #242424;
    right: 0;
    position: fixed;
    color: white;
    user-select: none;
    opacity: 1;
    transition: transform 0.5s, opacity 0.5s; /* Adjust duration as needed */
}

.section-wrapper > .gallery-text-banner:not(:last-child)::after {
    content: '';
    display: block;
    height: 2px; /* Adjust line thickness */
    background-color: #242424; /* Adjust line color */
    margin: 12px 0; /* Adjust spacing around the line */
}

.card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    background-position: top; /* Align the image to the top of the container */
}

.banner-header {
    font-size: 1em;
    padding: 0 16px 0;
    color: #C4C4C4;
    font-weight: 700;
}

.char-name-banner {
    font-size: 1.35em;
    font-weight: 700;
    padding: 6px 16px 0;
}

.image-banner-right-gallery {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.image-gallery-banner {
    width: 360px;
    height: 380px;
    object-fit: cover;
    object-position: top;
    border-bottom: 2px solid #242424;
}

.section-wrapper > .gallery-text-banner:not(:last-child)::after {
    content: '';
    display: block;
    height: 2px; /* Adjust line thickness */
    background-color: #242424; /* Adjust line color */
    margin: 15px 0; /* Adjust spacing around the line */
}

.banner-main-text-small {
    font-size: 0.80em;
    padding: 4px 16px;
    color: #C4C4C4;
    font-weight: 500;
}

.galleries-hide-them {
    width: 100%;
    height: fit-content;
}

.chat-container {
    scrollbar-width: none;
    display: flex;
    z-index: 1;
    padding: 60px 0 125px 0;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
}

/*.chat-container::before {*/
/*    content: "";*/
/*    position: fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    background-image: url('../../icons/background-shapes.svg');*/
/*    background-size: cover;*/
/*    background-position: center;*/
/*    background-attachment: fixed;*/
/*    filter: brightness(0.5); !* Adjust this value to control darkness *!*/
/*    z-index: -1;*/
/*    height: 200%; !* Make the background much taller than the viewport *!*/
/*    pointer-events: none; !* Allows scrolling through the pseudo-element *!*/
/*}*/

.banner-chat-container {
    scrollbar-width: none;
    display: flex;
    z-index: 1;
    padding: 85px 0 125px 0;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    animation: containerSlide 0.3s ease forwards;
    margin-left: 360px;
    min-height: 100%;
    background-size: cover;
    background-position: center;
}

/*.banner-chat-container::before {*/
/*    content: "";*/
/*    position: fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    background-image: url('../../icons/background-shapes.svg');*/
/*    background-size: cover;*/
/*    background-position: center;*/
/*    background-attachment: fixed;*/
/*    filter: brightness(0.5); !* Adjust this value to control darkness *!*/
/*    z-index: -1;*/
/*    height: 200%; !* Make the background much taller than the viewport *!*/
/*    pointer-events: none; !* Allows scrolling through the pseudo-element *!*/
/*}*/

.message-margin-top {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.messages-scroll-container {
    position: relative;
    height: 100%;
    overflow-y: auto;
}

.loading-spin {
    margin-left: 0;
    z-index: 2;
}

.loading-spin-chat {
    display: flex;
    margin-top: 85px;
    margin-left: 360px;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: calc(100vw - 360px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
}

.chat-container::-webkit-scrollbar {
    display: none;
}


.chatbox-spacer {
    width: calc(100vw - 360px);
    display: flex;
    position: fixed;
    align-content: center;
    margin-left: 360px;
    bottom: 5px;
    height: 80px;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    padding: 0 20px;
    box-sizing: border-box;
}

.user-chat-container-main {
    position: relative;
    max-width: 40%;
    flex-direction: column;
    display: flex;
    min-width: 60px;
    align-self: flex-end;
    border-radius: 16px 16px 0 16px;
    background-color: transparent;
    color: white;
    padding-right: 16px;
}

.user-chat-container,
.bot-chat-container {
    align-self: flex-start;
    border-radius: 8px;
    padding: 16px;
    max-width: 70%;
    word-wrap: break-word;
    display: flex;
    width: fit-content;
    font-weight: 400;
    flex-direction: column;
    margin-bottom: 20px;
    transition: width 0.3s ease, height 0.3s ease, left 0.3s ease, border-radius 0.3s ease, margin 0.3s ease, opacity 0.3s ease;
}

.bot-chat-container {
    position: relative;
    min-width: 120px;
    align-self: flex-start;
    color: white;
}

.user-chat-container {
    display: flex;
    position: relative;
    justify-content: flex-end;
    max-width: 100%;
    margin-bottom: 10px;
    min-width: 60px;
    margin-top: 10px;
    padding-right: 20px;
    align-self: flex-end;
    border-radius: 16px 16px 0 16px;
    background-color: #9c4ac1;
    color: white;
}

.chat-container-top-container {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    height: 80px;
    flex-direction: row;
    align-items: center;
    position: fixed;
    z-index: 102;
    min-width: calc(100vw - 360px);
    max-width: calc(100vw - 360px);
    border-bottom: 2px solid #242424;
    border-top: 2px solid #242424;
    background-color: #0d0d0d;
    overflow: hidden; /* Prevents content overflow if necessary */
    box-sizing: border-box; /* Ensures padding and border are included in the width */
}

.settings-icon {
    cursor: pointer; /* Change cursor to pointer for interactive icons */
    display: flex; /* Ensure the icon centers vertically */
    align-items: center; /* Vertically center the icon */
}

.settings-icon-gallery,
.settings-icon {
    z-index: 9;
    color: white;
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;

}

.settings-icon svg {
    height: 26px;
    min-height: 26px;
    max-height: 26px;
    max-width: 26px;
    min-width: 26px;
    width: 26px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.settings-icon-gallery svg {
    height: 28px;
    min-height: 28px;
    max-height: 28px;
    max-width: 28px;
    min-width: 28px;
    width: 28px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.char-name-container {
    display: flex;
    position: relative;
    margin-right: auto;
    align-items: center;
    gap: 10px;
    color: white;
    font-weight: 700;
    font-size: 1.35em;
}

.icons-container {
    display: flex;
    position: relative;
    margin-left: auto;
    align-items: center;
    gap: 10px; /* Adjust the gap between icons */
}

.new-feature-block svg {
    min-width: 20px;
    max-width: 20px;
}

.new-feature-block {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    gap: 5px;
    padding: 10px;
    border-radius: 12px;
    border: 2px solid #D7D7D7;
    cursor: pointer;
    color: #E7E7E7;
    font-weight: 600;
    font-size: 0.90em;
    width: 110px;
    height: 35px;
    white-space: nowrap;
    background-color: #9072D9;

    /* Glistening effect */
    background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.2) 25%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0) 100%
    );
    background-size: 200px 100%;
    background-repeat: no-repeat;
    animation: glistening 2s linear infinite;
    transition: all 0.3s ease;
}

.new-feature-block:hover {
    background-color: #7b5ed0;
}

.chat-back-button {
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
}

.chat-back-button svg {
    min-height: 24px;
    max-height: 24px;
    min-width: 24px;
    max-width: 24px;
    fill: white;
}

.chat-container-visible {
    display: none;
}

.card {
    position: absolute;
    width: 160px;
    height: 200px;
    border-radius: 12px;
    border: 2px solid #242424;
    background-size: cover;
    background: #101010;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}

.character-svg-altcard-gallery svg {
    width: 26px;
    height: 26px;
    min-height: 26px;
    max-height: 26px;
    min-width: 26px;
    max-width: 26px;
}
.token-counter {
    display: flex;
    align-items: center;
    border: 1px solid #c4c4c4;
    border-radius: 16px;
}

.token-display {
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 4px 8px;
    font-size: 0.85em;
    color: #fff;
    font-weight: 650;
}

.token-display svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

@media (max-height: 980px) {
    .gallery-container-h {
        max-width: 360px;
        min-width: 360px;
    }

}

@media (max-width: 680px) {
    .settings-icon svg {
        height: 28px;
        min-height: 28px;
        max-height: 28px;
        max-width: 28px;
        min-width: 28px;
        width: 28px;
        align-items: center;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .bot-chat-container {
        max-width: 100%;
    }

    .user-chat-container-main {
        max-width: 100%;
        padding-left: 15px;
    }

    .backdrop-epic-darken-mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9;
    }

    .chat-container-top-container {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
        min-width: 100%;
        border-top: 0;
        height: 85px;
    }

    .loading-spin-chat {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;

    }

    .scroll-bottom-button {
        bottom: 120px;
        margin-left: 0;
        right: 0;
        left: 85%;
    }

    .recent-chat-list-h-container-visible {
        width: 100%;
        /*height: 100vh;*/
        z-index: 998;
        justify-items: center;
        align-content: center;
        align-items: center;
        /*overflow: hidden;*/
        position: fixed;
        display: flex;
    }

    .chat-container-visible {
        scrollbar-width: none;
        display: flex;
        z-index: 1;
        padding: 0 0 125px 0;
        position: relative;
        overflow-y: auto;
        flex-direction: column;
        animation: containerSlide 0.3s ease forwards;
        height: 100%;
        background-size: cover;
        background-position: center;
    }

    /*.chat-container-visible::before {*/
    /*    content: "";*/
    /*    position: fixed;*/
    /*    top: 0;*/
    /*    left: 0;*/
    /*    right: 0;*/
    /*    bottom: 0;*/
    /*    background-image: url('../../icons/background-shapes.svg');*/
    /*    background-size: cover;*/
    /*    background-position: center;*/
    /*    background-attachment: fixed;*/
    /*    filter: brightness(0.5); !* Adjust this value to control darkness *!*/
    /*    z-index: -1;*/
    /*    height: 200%; !* Make the background much taller than the viewport *!*/
    /*    pointer-events: none; !* Allows scrolling through the pseudo-element *!*/
    /*}*/

    .chatbox-spacer {
        width: 100vw;
        margin-left: 0;
    }

    .user-chat-container{
        margin-top: 20px;
        margin-bottom: 20px;
    }
}


