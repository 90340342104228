.share-dropdown {
    width: 100%;
    height: 65px;
    position: relative;
}

.share-dropdown-control {
    display: flex;
    background-color: transparent;
    border-radius: 12px;
    border: 1px solid #C4C4C4;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 15px;
    width: 100%;
    height: 65px;
    align-items: center;
    transition: all 0.3s ease;
}

.share-dropdown.is-open .share-dropdown-control {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #C4C4C4;
}

.share-dropdown-control:hover {
    background-color: #212121;
}

.share-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #131313;
    border-radius: 0 0 8px 8px;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
}

.share-dropdown.is-open .share-dropdown-menu {
    border: 1px solid #C4C4C4;
    border-top: none;
}

.Dropdown-option {
    color: #ffffff;
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.Dropdown-option:hover {
    background-color: #212121;
}

.share-dropdown-arrow {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #ffffff;
    transition: transform 0.3s ease;
}

.share-dropdown.is-open .share-dropdown-arrow {
    transform: translateY(-50%) rotate(180deg);
}

/*.Dropdown-option.is-selected {*/
    /*background-color: #252525;*/
    /*color: #ffffff;*/
/*}*/

.option-icon {
    width: 28px;
    height: 28px;
    margin-right: 10px;
}

.option-text {
    display: flex;
    flex-direction: column;
}

.option-label {
    font-weight: bold;
    color: white;
}

.option-sublabel {
    font-size: 0.8em;
    color: #C4C4C4;
}