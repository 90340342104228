.funnel-container {
    display: flex;
    flex-direction: column;
    background-color: #151515;
    border: 1px solid #393939;
    border-radius: 16px;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 20px 40px;
    max-width: 900px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.random-name-button {
    background: #151515;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    padding: 0 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s;
}

.random-name-button:hover {
    background: #1a1a1a;
}

.random-name-button svg {
    width: 24px;
    height: 24px;
}

.funnel-creation-label {
    font-size: 1.25rem;
    color: white;
    font-weight: 700;
    text-align: left;
    width: 100%;
}

.progress-bar-container-char-funnel {
    position: relative;
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

/* Header Styles */
.step-header {
    text-align: left;
}

@media screen and (min-width: 0px) and (max-width: 680px) {
    .progress-bar-container-char-funnel,
    .funnel-container {
        min-width: 100%;
        max-width: 100%;
    }
}


@media screen and (min-width: 680px) and (max-width: 1280px) {
    .progress-bar-container-char-funnel,
    .funnel-container {
        min-width: 650px;
        max-width: 650px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1480px) {
    .progress-bar-container-char-funnel,
    .funnel-container {
        min-width: 700px;
        max-width: 700px;
    }
}

@media screen and (min-width: 1480px) and (max-width: 1920px) {
    .progress-bar-container-char-funnel,
    .funnel-container {
        min-width: 800px;
        max-width: 800px;
    }
}