
.recent-chat-list-title {
    align-self: flex-start;
    font-size: 1.5em;
    color: white;
    font-weight: 700;

}


.sidebar-menu-item {
    background-color: #bba9e1;
    transition: background-color 0.3s ease;
    min-height: 62px;
}

.sidebar-menu-item:hover, .sidebar-menu-item.selected {
    background-color: #2c2c4c;
    display: block;
}

.chat-image-container{
    max-width: 64px;
}

.chat-navigation-control-menu {
    flex-direction: column;
    display: flex;
    gap: 5px;
    width: 85%;
    padding-top: 25px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
}

.button-bottom-no-chars-section {

    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;
}


.button-no-chars {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 12px 20px;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    position: relative;
    background: #b5a2e0 linear-gradient(102.84deg, #9072D9 41.88%, #8573B0 66.26%);
    border-radius: 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 1.3em;
    color: #FFFFFF;
    cursor: pointer;
    align-items: center;
}

.button-no-chars:hover {
    background: #b5a2e0 linear-gradient(102.84deg, #9072D9 41.88%, #8573B0 66.26%);
    opacity: 0.9;
}

.settings-icons {
    display: flex;
    gap: 10px;
}

.settings-icons svg {
    height: 20px;
    width: 20px;
    max-height: 20px;
    max-width: 20px;
    cursor: pointer;
}

.top-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

.group-chat-icon {
    width: 50px;
    height: 50px;
}

.search-container-chatpage {
    display: flex;
    align-items: center;
    border: 1px solid #4F4F4F;
    border-radius: 24px;
    color: white;
    height: 40px;
    width: 100%;
    margin-top: 5px;
    padding: 5px 5px 5px 15px;
    margin-bottom: 0;
    background-color: #1a1a1a;
}

.chat-search-input {
    flex-grow: 1;
    min-height: 20px;
    padding: 8px;
    outline: none;
    border: none;
    background: transparent;
    overflow: hidden;
    color: white;
    white-space: nowrap;
    font-size: 1em;
    font-weight: 600;
}

.chat-search-input::placeholder {
    color: #BEBEBE;
}

.smaller-text {
    font-size: 0.9em;
    width: 100%;
    color: #C4C4C4;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.3px;
    margin: 2px 0 0;
}

.chat-delete-button {
    color: #FFF;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    text-align: left;
    width: 100%;
    display: block;
    transition: background-color 0.2s ease;
}

.chat-delete-button:hover {
    background-color: #bba9e1;
}

.recent-chat-list-container-h {
    display: flex;
    min-width: 360px;
    max-width: 360px;
    width: 100%;
    height: 100vh;
    transform: translateX(0);
    transition: transform 0.1s ease-in-out;
}

.recent-chat-list-container-h.closed {
    transform: translateX(-360px);
}

.settings-icon-open {
    height: 18px;
    min-height: 18px;
    max-height: 18px;
    max-width: 18px;
    min-width: 18px;
    width: 18px;
}

.settings-icon-open svg {
    height: 18px;
    min-height: 18px;
    max-height: 18px;
    max-width: 18px;
    min-width: 18px;
    width: 18px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}


.recent-chat-list-container {
    position: relative;
    background-color: #131313;
    display: flex;
    user-select: none;
    border-top: 2px solid #242424;
    flex-direction: column;
    overflow-y: hidden;
    /*justify-content: center;*/
    align-items: center;
    height: 100vh;
    border-right: 2px solid #242424;
}

.recent-chat-title {
    position: inherit;
    font-size: 1em;
    line-height: 20px;
    color: white;
}

.recent-chat-sidebar-menu {
    position: relative;
    width: 95%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 15px;
}


.recent-chat-sidebar-menu .sidebar-menu-item {
    padding: 4px 2px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: auto;
    color: white;
    background: transparent;
    border-radius: 16px;
    cursor: pointer;
    white-space: nowrap;
    font-weight: bold;
    position: relative;
}

.sidebar-menu-item:hover {
    background: rgba(91, 91, 91, 0.20);
    border-radius: 16px;
}

.recent-chat-sidebar-menu .sidebar-menu-item.selected {
    position: relative;
    font-weight: bold;
    background: rgba(91, 91, 91, 0.40);

}


.gc-modal-join-chat-btn {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #2c2c4c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
}

.gc-modal-join-chat-btn:hover {
    background-color: rgba(44, 44, 76, 0.8);
}


.sidebar-menu-item .recent-chat-info {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-grow: 1;
}


.sidebar-menu-item .recent-chat-image {
    max-width: 52px;
    max-height: 52px;
    min-height: 52px;
    min-width: 52px;
    border-radius: 50%;
    margin-left: 5px;
    margin-top: 2px;
    object-position: top;
}


.recent-chat-image {
    width: 100%;
    height: 100%;
    max-width: 52px;
    max-height: 52px;
    min-height: 52px;
    min-width: 52px;
    object-fit: cover;
    object-position: center;
}

.sidebar-menu-item .icons-container {

    display: none;
    gap: 12px;
}

.sidebar-menu-item:hover .icons-container {
    display: flex;
}


.sidebar-menu-item button {
    background: none;
    border: none;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
}


.recent-chat-label {
    position: relative;
    text-align: left;
    font-size: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    margin-bottom: 5px;
}

.sidebar-menu-item:hover .recent-chat-time {
    display: none;
}

.recent-chat-time-mobile {
    display: block;
    color: #BDBDBD;
    font-size: 0.6em;
    position: relative;
    align-self: flex-start;

}


.sidebar-menu-item:hover .icons-container {
    display: block;
}

.recent-chat-delete-icon {
    position: relative;
    align-self: flex-end;
    width: 40px;
    height: 40px;
    display: block;
    cursor: pointer;
}


.recent-chat-delete-icon:hover {
    border-radius: 50%;
    background: #242431;

}


.recent-chat-delete-icon:disabled {
    opacity: 0.4;
}


.main-chat-container.selected {
    animation: chatMenuOpen 0.3s ease forwards;
}


.main-chat-container.selected::before {
    content: "";
    position: inherit;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    pointer-events: none;
}

.recent-chat-last-message {
    font-size: 0.66em;
    color: #BDBDBD;
    max-width: 150px;
    font-weight: 500;
}

.recent-chat-time {
    color: #BDBDBD;
    font-size: 0.66em;
    position: relative;
    align-self: flex-start;
    font-weight: 500;
    margin-top: 4px;
    margin-right: 6px;
}

@keyframes chatMenuOpen {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}


/* Hover effect for sidebar menu items */


/*.create-group-chat-button{*/
/*    position: relative;*/
/*    height: 36px;*/
/*    display: inherit;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    font-size: 0.8750em;*/
/*    line-height: 22px;*/
/*    color: #ffffff;*/
/*    background-color: #5c5c82;*/
/*    opacity: 1;*/
/*    border: none;*/
/*    border-radius: 4px;*/
/*    gap: 6px;*/
/*    cursor: pointer;*/
/*}*/

/*.create-group-chat-button:hover {*/
/*    background-color: rgba(92, 92, 130, 0.8);*/
/*}*/


/* Custom Scrollbar Styles */
.recent-chat-sidebar-menu::-webkit-scrollbar {
    display: none;
}

@media (min-width: 720px) {
    .sidebar-menu-item:hover {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
        cursor: pointer; /* Change cursor to indicate clickable */
    }

    .sidebar-menu-item:hover .recent-chat-delete-icon {
        visibility: visible; /* Make delete icon visible only on hover for desktop */
    }
}

@media screen and (max-width: 680px) {


    .sidebar-menu-item:hover {
        background: transparent;
        border-radius: inherit;
    }

    .modal-no-chars {

        width: 85%;

    }

    .search-container-chatpage,
    .smaller-text,
    .top-section {
        width: 90vw;

    }

    .chat-navigation-control-menu {
        width: 100vw;
        margin-bottom: 5px;
        padding-top: 0;

    }

    .recent-chat-list-container-h {
        max-width: 100%;
        overflow: hidden;
        padding-top: 5px;
        border-top: 0;
        border-right: 0;
    }

    .recent-chat-list-container {
        max-width: 100%;
        overflow: hidden;
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: 0;
        border-right: 0;
    }

    .recent-chat-sidebar-menu {
        margin-bottom: 85px;
        border-radius: 16px 16px 0 0;
        user-select: none;
        width: 90%;
    }


    .recent-chat-sidebar-menu .sidebar-menu-item.selected {
        position: relative;
        font-weight: bold;
        background: rgba(28, 28, 28, 0.99);


    }

    .icons-container-mobile {
        position: absolute;
        width: 100%;
        align-items: center;
        justify-content: center;
        right: 0;
        left: 0;
    }

    .extension-component {
        position: absolute;
        height: 70px;
        width: 100%;
        color: #FF7474;
        display: flex;
        border-radius: 0 0 16px 16px;
        margin-top: 25px;
        background: rgba(28, 28, 28, 0.99);
        z-index: 9999;
        padding: 4px 6px;
        align-self: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        user-select: none;
    }

    .recent-chat-delete-icon-mobile {
        cursor: pointer;
    }

    .last-extension-component {
        bottom: 30px;
        border-radius: 16px 16px 0 0;

    }

    .sidebar-menu-item.last-chat-item {
        border-radius: 0 0 16px 16px;
    }

    .recent-chat-sidebar-menu .sidebar-menu-item {
        padding: 4px 6px;
        width: 100%;

    }

    .recent-chat-time-mobile {
        padding-right: 5px;
    }
}


@media screen and (min-width: 980px) and (max-width: 1280px) {

    .recent-chat-title {
        font-size: 1.125em;
    }

}

.empty-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 10;
    background: #131313;
    color: white;
}

.empty-modal-content {
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.a-straight-line {
    width: 100%;
    height: 1px;
    background: #ccc;
    margin-top: 20px;
}

.button-bottom-no-chars-section {
    margin-top: 20px;
}





.chat-item-container {
    margin-bottom: 2px;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    width: 100%;
    min-height: 62px;
}

.chat-item-container.last-item {
    margin-bottom: 0;
}

.delete-confirmation-overlay {
    /*background: rgb(255, 59, 48);*/
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100px;
}

.confirm-delete-btn, .cancel-delete-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
}

.confirm-delete-btn {
    background-color: rgb(255, 59, 48);
    color: #fff;
    cursor: pointer;
}

.cancel-delete-btn {
    background-color: transparent;
    color: white;
    border: 1px solid white;
}

/* Prevent text selection during swipe */
.sidebar-menu-item {
    user-select: none;
    -webkit-user-select: none;
    touch-action: pan-y pinch-zoom;
}

/* Add some visual feedback for the swipe */
.sidebar-menu-item.swiping {
    background-color: rgba(255, 59, 48, 0.1);
}