.loading-screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-screen-content {
    position: relative;
    width: 80%;
    max-width: 600px;
    max-height: 400px;
    aspect-ratio: 4 / 3;
    overflow: hidden;
    border-radius: 10px;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.spinner {
    width: 60px;
    height: 60px;
    border: 4px solid #ffffff;
    border-top: 4px solid #916DE8;
    border-radius: 50%;
}

.loading-text {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #ffffff;
    text-align: center;
}

.progress-bar-container {
    width: 80%;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    margin-top: 20px;
}

.progress-bar {
    height: 100%;
    background-color: #916DE8;
    border-radius: 2px;
    transition: width 0.15s ease-in-out;
}