.select-container-microtrans {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 25px;
    column-gap: 20px;
    /*width: 100%;*/
    margin: auto;
    align-items: center;
    justify-content: center;
}

@keyframes glow {
    0% {
        box-shadow: 0 0 5px rgba(151, 109, 253, 0.3);
    }
    100% {
        box-shadow: 0 0 10px rgba(151, 109, 253, 0.7);
    }
}

/*.microtrans-option-special {*/
/*    border: 2px solid #393939;*/
/*    padding: 20px;*/
/*    color: white;*/
/*    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
/*    text-align: center;*/
/*    background: linear-gradient(96.19deg, rgba(144, 114, 217, 0.5) 4.89%, rgba(70, 57, 102, 0.1) 93.75%), #131313;*/
/*    width: 200px;*/
/*    aspect-ratio: 1/1;*/
/*    cursor: pointer;*/
/*    border-radius: 12px;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    display: flex;*/
/*    position: relative;*/
/*    transition: background-color 0.3s, border-color 0.3s;*/
/*    animation: glow 2s infinite alternate;*/
/*}*/

/*.microtrans-option-special.selected {*/
/*    border: 2px solid #976DFD;*/
/*}*/


.microtrans-option {
    border: 2px solid #393939;
    padding: 20px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    background-color: #131313;
    width: 185px;
    aspect-ratio: 1/1;
    cursor: pointer;
    border-radius: 18px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    transition: background-color 0.3s, border-color 0.3s;
}

.microtrans-option.selected {
    background: linear-gradient(96.19deg, rgba(91, 69, 141, 0.4) 4.89%, rgba(70, 57, 102, 0.1) 93.75%), #131313;
    border: 2px solid #976DFD;
        transition: background-color 0.3s, border-color 0.3s;
        animation: glow 2s infinite alternate;
}

.plan-heading {
    color: #333;
    margin-bottom: 15px;
}

.plan-images, .plan-audio, .plan-video, .plan-bonus, .plan-price {
    color: #666;
    line-height: 1.5;
    margin: 5px 0;
}

.new-pricing-subtitle-microtrans {
    max-width: 75%;
    color: #C4C4C4;


}

.microtrans-option-bonus {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
    height: 25px;
    background-color: #976DFD;
    border-radius: 0 0 12px 12px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.bonus-oval h4 {
    margin: 0;
    font-weight: 700;
    font-size: 0.95em; /* Adjust font size as needed */
    color: #ffffff; /* Text color */
}

.button-buy-addon-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
    gap: 15px;
}


.buy-right-now-button-microtrans-crypto,
.buy-right-now-button-microtrans {
    width: 50%;
    height: 55px;
    gap: 5px;
    display: flex;
    background: linear-gradient(102.84deg, #A281F2 41.88%, #998CB9 66.26%);
    border-radius: 16px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    color: #FFFFFF;
    min-width: 120px;
    max-width: 320px;
    transition: opacity 0.3s ease;
}

.buy-right-now-button-microtrans-crypto{
    background: linear-gradient(102.84deg, #F7931A 41.88%, #E3A036 66.26%);
}

.buy-right-now-button-microtrans-crypto svg,
.buy-right-now-button-microtrans svg {
    height: 24px;
    min-height: 24px;
    min-width: 24px;
    width: 24px;
}

.buy-right-now-button-microtrans-crypto:hover,
.buy-right-now-button-microtrans:hover {
    opacity: 0.90;
}

.no-select {
    background: linear-gradient(68.84deg, #545454 41.88%, #656565 66.26%);
    cursor: not-allowed;

}

.no-select:hover {
    background: linear-gradient(68.84deg, #545454 41.88%, #656565 66.26%);
    cursor: not-allowed;
}


.microtrans-option-header {

    display: flex;
    flex-direction: column;

}

.plan-heading-microtrans {
    display: flex;
    gap: 5px;
    margin-bottom: 15px;
    font-size: 1.75em;
    font-weight: 700;
    align-items: center;
    white-space: nowrap;
}


.plan-heading-microtrans svg {
    width: 32px;
    height: 32px;
}

.plan-subheading-microtrans {
    font-size: 1em;
    font-weight: 600;
    white-space: nowrap;
    color: #c4c4c4;
}


.pricing-container-top-column-microtrans {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.pricing-swiper-component-microtrans {
    overflow: visible !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.swiper-slide {
    width: fit-content !important;
}

.buy-right-now-button-mobile{
    display: none;
}

@media (max-width: 680px) {
    .microtrans-option {
        border: 2px solid #393939;
        padding: 20px;
        color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;

        width: 165px;
        cursor: pointer;
        border-radius: 18px;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        position: relative;
        transition: background-color 0.3s, border-color 0.3s;

    }

    .plan-heading-microtrans {
        font-size: 1.75em;
        margin-bottom: 5px;
    }

    .plan-subheading-microtrans {
        font-size: 1.25em;
    }

    .slide-option-mobile-micro {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        user-select: none;
    }

    .microtrans-option-bonus {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 25px;
        background-color: #976DFD;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bonus-oval h4 {
        margin: 0;
        font-size: 0.95em;
        color: #ffffff;
    }

    .plan-subheading-microtrans {
        font-size: 1em;
        font-weight: 600;
        white-space: nowrap;
        margin-top: 10px;
    }

    .new-pricing-subtitle-microtrans {
        max-width: 100%;
    }
    .buy-right-now-button-microtrans-crypto,
    .buy-right-now-button-microtrans{
        width: 100%;
    }

    .buy-right-now-button-mobile{
        display: flex;
        z-index: 9999;
        width: 100%;
        gap: 10px;
        background: #9072D9;
        color: white;
        height: 65px;
        bottom: 85px;
        position: fixed;
        font-weight: 700;
        font-size: 1.15em;
        align-items: center;
        justify-content: center;
        border-radius: 12px 12px 0 0;
    }

    .buy-right-now-button-mobile svg{
       width: 24px;
        height: 24px;
    }
}