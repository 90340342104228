.user-loader-profile {
    min-width: 300px;
    min-height: 300px;
    border-radius: 12px;
    animation: shine 1.5s infinite ease-in-out;
}

.user-loader-profile {
    background-color: #1e1e1e; /* Your desired solid background color */
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    animation: glistening 1.5s infinite;
}

.user-loader-profile {
    background-color: #1e1e1e; /* Your desired solid background color */
    background-image: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    background-size: 200% 100%;
    animation: glistening 1.5s infinite;
}