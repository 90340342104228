.enforced-display-char-creator {
    position: absolute;
    top: 20px;
    left: 30px;
    z-index: 10;
    font-weight: bold;
}

.enforced-display-char-creator-small {
    position: absolute;
    top: 15px;
    left: 20px;
    z-index: 10;
    font-weight: bold;
}

.furry-wrapper {
    grid-column: 1 / -1;
    grid-row: 2;
}

.custom-voice-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.general-options-container {
    margin-top: 30px;
    display: grid;
    position: relative;
    border-radius: 16px;
    justify-content: space-around;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    margin-bottom: 15px;
    gap: 35px;
}

.general-option-creator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 14px;
    position: relative;
    font-size: 1.625em;
    z-index: 1;
    height: fit-content;
    border: 2px solid white;
    color: white;
    transition: all 0.3s ease-in-out;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.general-option-creator::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(178.83deg, #1C1C1C 1%, rgba(28, 28, 28, 0) 70.77%);
    right: 0;
    bottom: 0;
    z-index: 9;
    pointer-events: none;
    border-radius: 12px;
}

.general-option-creator-multiple-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 14px;
    position: relative;
    font-size: 1.625em;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    color: white;
    border: 2px solid white;
}


.custom-voice-container {
    border-radius: 8px;
}

.voice-upload-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 10px;
    border-radius: 6px;
    height: 135px;
    border: 1px dashed #c4c4c4;
}

.voice-sample-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    height: 100%;
}

.upload-button-voice {
    background: none;
    color: white;
    font-weight: 700;
    font-size: 1em;
}

.record-button-voice {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 1px solid #5F5F5F;
    border-radius: 8px;
    color: #D74646;
    font-weight: 650;
    font-size: 1em;
    padding: 8px 16px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.record-button-voice:hover {
    background-color: #D74646;
    color: white;
}







.audio-upload-label{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px 12px;
    height: 45px;
    border: 1px solid #5e5e5e;
    border-radius: 8px;
    gap: 5px;
    cursor: pointer;
    color: #c4c4c4;
    font-size: 1em;
    font-weight: 700;
    transition: all 0.3s ease;
}

.audio-upload-label:hover{
    background-color: #1f1f1f;
}

.audio-container-cloning{
    margin-top: 15px;
}


.voice-recording-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.internal-voice-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.recording-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 15px;
    width: 100%;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid #5e5e5e;
    background: none;
    transition: all 0.3s ease;
    cursor: pointer;
    color: #ef4444;
    font-weight: 700;
    font-size: 1em;
}

.recording-button svg {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    stroke: #ef4444;
}

.recording-button:hover {
    background-color: #ef4444;
    color: white;
}

.recording-button:hover.recording-button svg {
    stroke: white;
}

.recording-button.recording {
    background-color: #ef4444;
    animation: pulse 2s infinite;
    color: white;
}

.recording-button.recording.recording-button svg {
    fill: white;
    stroke: white;
}

.recording-button.recording.hover.recording-button svg {
    fill: white;
    stroke: white;
}

.recording-button.recording:hover {
    background-color: #dc2626;
}

.delete-voice-clone-audio-button{
    aspect-ratio: 1/1;
    height: 50px;
    border: 1px solid #5e5e5e;
    border-radius: 6px;
    padding: 10px;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}


.delete-voice-clone-audio-button:hover{
    background-color: #1f1f1f;
}


/* This removes the white border around the player */
audio {
    background: transparent;
}

.custom-audio-player {
    background: transparent !important;
    width: 100%;
    height: 50px;
}



@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}

.audio-preview {
    margin-top: 10px;
    padding: 10px;
    border-radius: 6px;
    background: #c4c4c4;
}

.audio-preview audio {
    width: 100%;
}











.general-option-voice-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 12px;
    position: relative;
    font-size: 1em;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    width: 100%;
    max-height: 40px;
    min-height: 40px;
    color: #c4c4c4;
    border: 1px solid #c4c4c4;
}

.voice-example-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.voice-example-container svg {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
    stroke: #C4C4C4
}

.general-option-creator-selected .voice-example-container svg {
    stroke: #C0A7FF
}

.general-option-creator-multiple-items::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(178.83deg, #1C1C1C 1%, rgba(28, 28, 28, 0) 50.77%);
    right: 0;
    bottom: 0;
    z-index: 9;
    pointer-events: none;
    border-radius: 12px;
}

.general-image-creator {
    display: block;
    position: relative;
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 12px;
    aspect-ratio: 1/1;
    object-position: top;
    width: 100%;
}

.general-option-creator-selected, .non-binary-option-selected, .personality-box.selected, .general-option-creator-multiple-items.selected {
    border-color: #C0A7FF;
    color: #C0A7FF;
}

.non-binary-option {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease;
}

.personality-selection-container {
    width: 100%;
    margin-top: 20px;
}

.personality-grid {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
}

.personality-box {
    border: 1px solid #ccc;
    border-radius: 8px;
    height: 125px;
    padding: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.personality-box:hover,
.personality-box.selected {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
}

.personality-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
}

.selected-personality {
    margin-top: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
}

.personality-name-creator {
    color: white;
    font-weight: 700;
    font-size: 1.25em;
}

.personality-description-creator {
    color: #C4C4C4;
    font-weight: 500;
    font-size: 1em;
}

.general-multiple-options-container {
    margin-top: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    gap: 15px;
}

.top-row, .bottom-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
}

.top-row-color, .bottom-row-color {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    width: 100%;
}

/*.top-row {*/
/*    width: 100%;*/
/*}*/


.general-option-wrapper {
    position: relative;
    width: 100%;
}

.bottom-row-uneven {
    display: flex;
    flex-direction: row;
    width: 66.67%;
    gap: 10px;
}

.general-option-wrapper-eye-color {
    position: relative;
}


.input-container-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 25px;
}

.model-dropdown-creator {
    width: 100%;
    height: 45px;
}

.model-dropdown-control-creator {
    display: flex;
    background-color: #171717;
    border: 1px solid #696969;
    border-radius: 12px;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 15px;
    width: 100%;
    height: 45px;
    align-items: center;
    transition: all 0.3s ease;
}

.model-dropdown-creator.is-open .model-dropdown-control-creator {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #C4C4C4;
}

.model-dropdown-control-creator:hover {
    background-color: #1C1C1C;
}

.model-dropdown-menu-creator-voice {
    background-color: #171717;
    border-radius: 0 0 8px 8px;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1003;
    max-height: 320px;
}

.model-dropdown-menu-creator {
    background-color: #171717;
    border-radius: 0 0 8px 8px;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1003;
}

.model-dropdown-creator.is-open .model-dropdown-menu-creator, .model-dropdown-menu-creator-voice {
    border: 1px solid #C4C4C4;
}

.model-dropdown-menu-creator-voice .Dropdown-option {
    color: #ffffff;
    padding: 10px 15px;
}

.model-dropdown-menu-creator .Dropdown-option {
    color: #ffffff;
    padding: 10px 15px;
}

.model-dropdown-menu-creator-voice .Dropdown-option:hover {
    background-color: #1C1C1C;
}

.model-dropdown-menu-creator .Dropdown-option:hover {
    background-color: #1C1C1C;
}

.model-dropdown-arrow-creator {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    border-color: #ffffff transparent transparent;
}

.final-step-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    margin-top: 25px;
}

.hair-color-selector {
    position: relative;
}

.color-box {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
}

.quick-insert-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-height: 150px; /* Adjust this value as needed */
    overflow-y: auto;
    margin-top: -15px;
}

.quick-insert-button {
    padding: 6px 10px;
    border-radius: 16px;
    background-color: #262626;
    color: white;
    font-weight: 600;
    font-size: 0.95em;
    cursor: pointer;
    transition: background-color 0.3s;
    white-space: nowrap;
}

.quick-insert-button:hover {
    background-color: #1C1C1C;
}

.unblur-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 12px;
    cursor: pointer;
    z-index: 1;
    transition: background-color 0.3s ease;
}

.unblur-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}


.voice-grid {
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
}

.dropdown-container-bottom {
    margin-bottom: 15px;
}

@media screen and (min-height: 680px) and (max-height: 1280px) {
    .general-option-creator {
        min-width: 100%;
        min-height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}


@media screen and (min-width: 680px) and (max-width: 1280px) {
    .general-option-creator {
        min-width: 100%;
        min-height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}

@media screen and (min-width: 0px) and (max-width: 680px) {
    .general-option-creator {
        min-width: 100%;
        min-height: 100%;
        max-width: 100%;
        max-height: 100%;
    }

    .dropdown-container-bottom {
        margin-bottom: 5px;
    }

    .model-dropdown-control-creator {
        font-size: 1em;
    }

    .final-step-container {
        gap: 15px;
        margin-top: 10px;
    }

    .quick-insert-container {
        gap: 5px;
        margin-top: 5px;
        font-size: 0.95em;
    }

    .general-options-container {
        gap: 10px;
    }

    .personality-grid {
        gap: 10px;
        grid-template-columns: repeat(2, 1fr);
    }

    .personality-box {
        height: 95px;
        padding: 8px;
    }

    .personality-name-creator {
        font-size: 0.90em;
        white-space: nowrap;
    }

    .personality-description-creator {
        font-size: 0.80em;
    }

    .general-option-voice-items {
        width: 100%;
        max-height: 35px;
        min-height: 35px;
    }

    .voice-grid {
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }

    .general-multiple-options-container {
        gap: 10px;
        max-width: 100%;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .top-row, .bottom-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 5px;
        /*width: 100%;*/
    }

    .top-row-color, .bottom-row-color {
        gap: 5px;
    }

    .enforced-display-char-creator {
        top: 10px;
        left: 15px;
        font-size: 0.9em;
    }

    .enforced-display-char-creator-small {
        top: 5px;
        left: 10px;
        font-size: 0.65em;
    }

    .color-box {
        border-radius: 12px;
    }

}