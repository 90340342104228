/* General Footer Styles */
.footer {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 20px 40px;
    background-color: #0F0F0F;
    color: #FFF;
    overflow-x: hidden;
    justify-content: center;
    gap: 20px;
}
.footer-information{
    position: relative;
    margin-bottom: 20px;
    padding: 40px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.footer-agreement{
    position: relative;
    bottom: 0;
    left: 0;
}
.footer-subtitle {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.footer-logo span {

    font-size: 1.5em;
    font-weight: bold;
}

.footer-logo svg {
    width: 120px;
    height: 120px;
}

.DiscordIconFooter {
    cursor: pointer; /* Change cursor to pointer to indicate clickability */
    display: flex; /* To align the SVG icon properly */
    align-items: center; /* Align items vertically */
    justify-content: center; /* Center content horizontally */
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
}

.DiscordIconFooter svg {
    width: 100%; /* Scale SVG to fit its container */
    height: auto;
    fill: #7289DA; /* Discord's brand color, adjust if needed */
}

@media screen and (max-width: 680px) {
    .footer {
        flex-direction: column;
        padding: 20px;
        gap: 0;
    }

    .footer-logo{

        margin-bottom: 0;
    }

    .footer-information {
        margin-left: 0;
        padding: 0;
        align-items: center;
        text-align: center;
    }

}
