
.expandable-description {
    position: relative;
}

.description-content {
    overflow: hidden;
}

.read-more-button {
    background: none;
    border: none;
    position: absolute;
    color: #bba9e1;
    margin-left: -5px;
    cursor: pointer;
    font-size: 0.9em;
    transition: color 0.3s ease;
}

.read-more-button:hover {
    color: #d1c7f1;
    opacity: 0.95;
}

.description-wrapper {
    position: relative;
}

.description-container {
    max-height: 96px;
    overflow: hidden;
}

.popup-description {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #131313;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 101;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-content {
    position: relative;
}


.close-button {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 8px;
    background: #f3f4f6;
    border-radius: 50%;
    cursor: pointer;
}

.description-content {
    margin: 0;
    line-height: 1.5;
}

@media (max-width: 720px) {
    .read-more-button {
        position: relative;
    }
}