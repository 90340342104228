.select-messages-general {
    display: flex;
    flex-direction: row;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    bottom: 0;
    background-color: #0d0d0d;
    height: 65px;
    width: calc(100% - 360px);
    border-top: 2px solid #242424;
}

.menu-item {
    display: flex;
    align-items: center;
}

.icon {
    min-width: 20px;
    min-height: 20px;
    max-height: 20px;
    max-width: 20px;
    cursor: pointer;
}

.message-selected-amount {
    color: #c4c4c4;
    font-size: 0.95em;
    font-weight: 700;
}

.select-all {
    display: flex;
    align-items: center;
    color: #c4c4c4;
    font-size: 0.95em;
    font-weight: 700;
}

.select-all span {
    margin-right: 8px;
}

@media screen and (max-width: 680px) {
    .select-messages-general {
        width: 100%;
        height: 75px;
    }
}
