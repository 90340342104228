.main-page-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #131313;
    overflow: hidden;
    width: 100%;
    margin-top: 105px;
    height: 100%;

}


.no-scroll {
    overflow: hidden !important;
}

@keyframes glistening {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}


.character-general-rows-loading{
    position: relative;

}


.loading-row-header,
.loading-tag,
#loading-header,
#loading-tags,
#loading-event-container,
.loading-card {
    background: linear-gradient(
            to right,
            transparent 0%,
            rgb(66, 66, 66) 50%,
            transparent 100%
    );
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgba(150, 150, 150, 0.25);

}

#loading-header {
    height: 85px;
    width: 100%;
    border-radius: 4px;
}

#loading-tags {
    width: 95vw;
    height: 30px;
    margin: 10px 0;

    border-radius: 8px;
}

#loading-event-container {
    width: 95vw;
    min-height: 300px;
    height: 25vh;
    margin: 5px 5px 20px;
    border-radius: 24px;
}
.loading-row-header{
    width: 35vw;
    height: 50px;
    border-radius: 24px;
    margin: 20px 2px 15px;


}
.loading-character-cards {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 20px 20px 20px 5px;
    width: 95vw;


}

.loading-card {
    width: 190px;
    height: 300px;
    max-height: 300px;
    max-width: 190px;
    border-radius: 16px;
    flex-grow: 1;

}

.loading-tags {
    display: flex;
    justify-content: flex-start;
    gap: 10px; /* Space between tags */

    width: 95vw;
    margin: 5px 10px 20px;

}

.loading-tag {
    height: 40px; /* Adjust the height as needed */
    width: 100px; /* Adjust the width as needed */
    border-radius: 24px;

}

.loading-tag::after,
#loading-header::after,
#loading-tags::after,
#loading-event-container::after,
.loading-card::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
            to right,
            transparent 0%,
            rgb(26, 26, 26) 50%,
            transparent 100%
    );
    animation: glistening 1.5s infinite;
    background-size: 200% 100%;
}
.placeholder-unify{

    display: flex;
flex-direction: column;


}
@media screen and (max-width: 680px) {
    .loading-card {
        max-width: 190px;
        height: 300px;
    }
    #loading-event-container {

        min-height: 200px;

    }
    .main-page-loader{
        margin-top: 0px;

    }
}