.popup-settings-close-popup {
    position: absolute; /* or fixed, depending on your needs */
    border-radius: 10px;
    width: fit-content;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: left;
    z-index: 9999;
    user-select: none;
}

.smaller-modal-content{
    position: absolute;
    width: fit-content;
    margin-left: 38px;
    /*margin-top: 45px;*/
    margin-bottom: 5px;
}

.edit-delete-container {
    color: #C4C4C4;
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    background-color: #1C1C1C;
    border-radius: 8px;
    /*padding: 6px 8px;*/
    box-shadow: 0 4px 4px rgba(255, 255, 255, 0.15);

}

.custom-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: #d7d7d7;
    padding: 8px 12px;
    cursor: pointer;
    width: 100%;
    font-weight: 700;
    justify-content: flex-start;
}

.custom-button:hover {
    background-color: #333;
}
.custom-button.edit-icon-bt:hover {
    border-radius: 8px 8px 0 0;
}

.custom-button.middle-icon-bt:hover {
    border-radius: 0;
}

.custom-button.delete-icon-bt:hover {
    border-radius: 0 0 8px 8px;
}

.button-separator {
    height: 1px;
    background-color: #C4C4C4;
}

.delete-confirmation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2c2c2c;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.confirmation-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.cancel-button {
    margin-right: 10px;
    padding: 8px 16px;
    background: none;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    color: #d7d7d7;
    cursor: pointer;
}

.delete-button {
    padding: 8px 16px;
    background-color: #dc3545;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

.custom-button-icon{
    width: 16px;
    height: 16px;
    margin-right: 6px;
}

.custom-button-icon svg{
    min-width: 18px;
    min-height: 18px;
    max-height: 18px;
    max-width: 18px;
}