.faq-main-component{
    width: 100%;
}

.still-not-sure{
    font-size: 0.85em;
    color: #9b9b9b;
    font-weight: 500;
    padding: 0 25px 135px;
}

.faq-question-pricing {
    font-weight: bold;
    color: #c4c4c4;
    padding: 10px 10px 10px 0;
}

.faq-answer-pricing {
    padding: 10px 10px 10px 0;
    max-height: 0;
    overflow: hidden;
    display: block;
    color: #c4c4c4;
    z-index: 9;
    transition: max-height 0.5s ease;
}

.faq-item.active .faq-answer-pricing {
    max-height: 10000px;
    z-index: 9;
    transition: max-height 0.5s ease;
}