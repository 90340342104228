.error-frf-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 120px;
    user-select: none;

}
.bigger-error-text {
    font-size: 50px;
    font-weight: 700;
    color: #ccc;
    margin-bottom: 20px;
    margin-top: 10%;
}

.smaller-error-text{
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: #ccc;
    margin-bottom: 20px;
}

@media screen and (max-width: 720px) {
    .error-frf-container{
        padding: 120px 20px;
    }
}