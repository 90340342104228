.tag-list-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 97vw;
    padding: 5px;
    user-select: none;
    position: relative;
}

.tag-list-container-character-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*padding: 5px;*/
    user-select: none;
    position: relative;
}

@media screen and (max-width: 680px) {
    .tag-list-container {
        margin-top: 105px;
        padding-bottom: 5px;
    }
    .tag-list-container-character-card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /*padding: 5px;*/
        user-select: none;
        position: relative;
    }
}

.tag-list {
    padding: 5px 0;
    display: flex;
    gap: 10px;
    position: relative;
    align-items: center;
    width: 100%;
    overflow: hidden;
    font-weight: bold;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.scroll-button-character-h {
    z-index: 99;
    background-color: white; /* White circle background */
    color: black; /* Black arrow icon */
    border-radius: 50%; /* Make it a circle */
    width: 24px; /* Circle size */
    height: 24px; /* Circle size */
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    position: static;
}


.tag-list-h {
    width: 95vw;
    align-items: center;
    margin-top: 90px;

}

.tag-list-gradient {
    display: flex;
    scroll-behavior: unset;
    overflow-x: auto;
    flex-wrap: nowrap;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    transition: box-shadow 0.2s ease;
}

.tag-list-gradient::before,
.tag-list-gradient::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100px; /* Adjust the width of the fade effect as needed */
    height: 100%;
    z-index: 9;
    pointer-events: none; /* So that clicks pass through */
}

.tag-list-gradient::before {
    left: 0;
    background: linear-gradient(to right, #131313, rgba(19, 19, 19, 0));
}

.tag-list-gradient::after {
    right: 0;
    background: linear-gradient(to left, #131313, rgba(19, 19, 19, 0));
}

.scroll-buttons-container-h {
    position: relative;
    gap: 16px;
    display: flex;
}

.tag-list-gradient::-webkit-scrollbar {
    display: none;
}

.tag-list-gradient-character-card{
    display: flex;
    scroll-behavior: unset;
    overflow-x: auto;
    flex-wrap: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
    transition: box-shadow 0.2s ease;
}

.tag-list-gradient-character-card::before,
.tag-list-gradient-character-card::after {
    content: '';
    position: absolute;
    top: 0;
    width: 50px;
    height: 100%;
    z-index: 9;
    pointer-events: none;
}

.tag-list-gradient-character-card::before {
    left: 0;
    background: linear-gradient(to right, #131313, rgba(19, 19, 19, 0));
}

.tag-list-gradient-character-card::after {
    right: 0;
    background: linear-gradient(to left, #131313, rgba(19, 19, 19, 0));
}

.tag-list-gradient-character-card::-webkit-scrollbar {
    display: none;
}

.tag-item {
    background-color: #262626;
    color: #d7d7d7; /* Text color */
    user-select: none;
    transition: background-color 0.3s, color 0.3s;
}
.tag-item:hover {
    background-color: #1C1C1C;
}

.tag-item.selected {
    color: #1C1C1C; /* This color will show when the item is clicked but not selected */
    background-color: #F2F2F2;
}


.tag-item {
    white-space: nowrap; /* Prevent text wrapping */
    padding: 8px 16px;
    margin-right: 8px;
    border-radius: 20px;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}


/* Optional: animation for adding/removing tags */
@keyframes tagFadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.tag-item {
    animation: tagFadeIn 0.3s forwards;
}

.no-blur-left::before {
    background: none;
    transition: background 0.3s ease;
}

.no-blur-right::after {
    background: none;
    transition: background 0.3s ease;
}