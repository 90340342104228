.main-space-finder-modal {
    z-index: 1031;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    user-select: none;
    /*backdrop-filter: blur(10px);*/
}
.failed-purchase-message {
    color: white;
    z-index: 9998;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 35vw;
    padding: 40px;
    height: 10vh;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    user-select: none;
    background-color: #131313;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto;
}


.modal-header-delete-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    z-index: 9999;
    white-space: nowrap;
}

.modal-close-icon{
    height: 16px;
    position: absolute;
    right: 15px;
    cursor: pointer;
    top: 25px;
}

.app-modal-content {
    background-color: #131313;
    padding: 25px;
    border-radius: 10px;
    width: 55%;
    color: white;
    display: block;
    /*flex-direction: column;*/
    /*justify-content: space-between;*/
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 450px;
    max-width: 550px;
    z-index: 9999;
}

.modal-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.app-delete-btn,
.app-confirm-btn,
.app-cancel-btn {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    font-weight: 500;
    transition: background-color 0.2s;
}

.app-delete-btn {
    background-color: #b5a2e0;
}

.app-confirm-btn {
    background-color: #e63946;
    color: white;
    font-weight: 700;
    font-size: 1em;
}

.app-cancel-btn {
    background-color: #a8a8a8;
}

.app-delete-btn:hover,
.app-confirm-btn:hover,
.app-cancel-btn:hover {
    filter: brightness(90%); /* Slight darkening on hover for feedback */
}

@media screen and (max-width: 680px) {
    .main-space-finder-modal{
        overflow-y: scroll;
    }
}